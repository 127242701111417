import { Grid, makeStyles, Tooltip, IconButton, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { ChangingRoomContext } from '../contexts/ChangingRoomContext';
import { ShoppingCartContext } from '../contexts/ShoppingCartContext';
import ShoppingCartService from '../services/shopping-cart.service';
import Utils from '../services/utils.service';
import RemoveIcon from '@material-ui/icons/CancelRounded';
import MarkedIcon from '@material-ui/icons/ErrorRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: 'white',
    height: '100%',
    display: 'block',
    padding: 10,
    scrollbarWidth: 'none',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
  },
  row: {
    zIndex: 1,
    position: 'relative',
    cursor: 'pointer',
    backgroundColor: 'white',
    borderRadius: 5,
    marginBottom: theme.spacing(1.5),
    '& .productImage': {
      width: 60,
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#fff',
      padding: 5,
      borderRadius: 2,
    },
    '& img': {
      maxWidth: 50,
      maxHeight: 50,
      objectFit: 'contain',
    },
    '& p': {
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(0.5),
    },
    '& .title': {
      fontWeight: 500,
    },
    '& .color': {
      fontSize: 14,
      marginBottom: theme.spacing(0.5),
    },
    '&.selected': {
      backgroundColor: '#EE1F3B',
      color: '#fff',
      '& $removeIconButton': {
        color: '#fff'
      },
      '& $markedIcon': {
        background: '#ee1f3b',
        color: '#fff'
      }
    },
  },
  groupSection: {
    marginBottom: theme.spacing(1),
  },
  groupName: {
    color: 'white',
    fontWeight: 500,
    paddingBottom: theme.spacing(1),
    textTransform: 'uppercase',
  },
  removeIconButton: {
    position: 'absolute',
    top: -5,
    right: -5,
    padding: 0,
    color: '#dc3545'
  },
  removeBtn: {
    zIndex: 2,
    cursor: 'pointer',
    textDecoration: 'underline',
    marginBottom: '0 !important'
  },
  markedIcon: {
    backgroundColor: '#fff',
    borderRadius: '50% ',
    padding: theme.spacing(0),
    border: 'none',
    color: '#ee1f3b',
  }
}));

const ChangingRoomItems = (props) => {
  let classes = useStyles();
  const [shoppingCartContext] = useContext(ShoppingCartContext);
  const [changingRoomContext, setChangingRoomContext] = useContext(ChangingRoomContext);
  const [groups, setGroups] = useState([]);
  const [items, setItems] = useState([
    {
      productSku: 1,
      productGroup: 'head',
      name: 'Cap',
      color: 'red',
      imageUrl: 'images/ChangingRoom/cap.png',
      selected: false,
    },
    {
      productSku: 2,
      productGroup: 'eyes',
      name: 'Sun glasses',
      color: 'gold',
      imageUrl: 'images/ChangingRoom/sun-glasses-gold.png',
      selected: false,
    },
    {
      productSku: 3,
      productGroup: 'eyes',
      name: 'Sun glasses',
      color: 'black',
      imageUrl: 'images/ChangingRoom/sun-glasses.png',
      selected: false,
    },
    {
      productSku: 4,
      productGroup: 'tops',
      name: 'T Shirt',
      color: 'black',
      imageUrl: 'images/ChangingRoom/t-shirt-black.png',
      selected: false,
      size: 'M'
    },
    {
      productSku: 5,
      productGroup: 'tops',
      name: 'T Shirt',
      color: 'green',
      imageUrl: 'images/ChangingRoom/t-shirt-green.png',
      selected: false,
      size: 'L'
    },
    {
      productSku: 6,
      productGroup: 'legs',
      name: 'Pants',
      color: 'green',
      imageUrl: 'images/ChangingRoom/pant-green.png',
      selected: false,
    },
    {
      productSku: 7,
      productGroup: 'legs',
      name: 'Pants',
      color: 'orange',
      imageUrl: 'images/ChangingRoom/pant-orange.png',
      selected: false,
    },
    {
      productSku: 8,
      productGroup: 'legs',
      name: 'Jeans',
      color: 'blue',
      imageUrl: 'images/ChangingRoom/jean.png',
      selected: false,
    },
    // {
    //   productSku: 9,
    //   productGroup: 'legs',
    //   name: 'Jeans',
    //   color: 'blue',
    //   imageUrl: 'images/ChangingRoom/jean.png',
    //   selected: false,
    //   size: 'XL'
    // },
    // {
    //   productSku: 10,
    //   productGroup: 'legs',
    //   name: 'Jeans',
    //   color: 'blue',
    //   imageUrl: 'images/ChangingRoom/jean.png',
    //   selected: false,
    //   size: 'S'
    // },
  ]);

  useEffect(() => {
    console.log("first useEffect");

    let shoppingCartJsonString = localStorage.getItem('sc');
    let shoppingCart = JSON.parse(shoppingCartJsonString);
    if (shoppingCart) {
      console.log("shoppingCart", shoppingCart);
      setItems([...items, ...shoppingCart])
    }

    if (groups && items && changingRoomContext && changingRoomContext.dressUp) {
      console.log("in if");
      let changingRoomProductGroups = Object.keys(changingRoomContext.dressUp);
      if (shoppingCart) {
        let tmpItems = [...items, ...shoppingCart];
        changingRoomProductGroups.filter((key) => {
          let dressedUpProduct = changingRoomContext.dressUp[key];
          if (dressedUpProduct) {
            console.log("dressedUpProduct", dressedUpProduct);
            let item = tmpItems.find((x) => x.productSku == dressedUpProduct.productSku);
            if (item) {
              console.log("item", item);
              item.selected = true;
            } else {
              let newDressUp = changingRoomContext.dressUp;
              delete newDressUp[key];
              console.log("No!!", newDressUp);
            }
          }
        });
        setItems(tmpItems);
      } else {
        let tmpItems = [...items];
        changingRoomProductGroups.forEach((key) => {
          let dressedUpProduct = changingRoomContext.dressUp[key];
          if (dressedUpProduct) {
            let item = tmpItems.find((x) => x.productSku == dressedUpProduct.productSku);
            if (item) {
              item.selected = true;
            }
          }
        });
        setItems(tmpItems);
      }
    }
  }, []);

  useEffect(() => {
    console.log("tmpItems:", items);
    let productGroups = items.map((x) => x.productGroup).filter(Utils.distinct);
    setGroups(productGroups);
  }, [items])

  useEffect(() => {
    console.log("changingRoomContext change");
  }, [changingRoomContext])

  const handleItemClick = (productSku) => {
    let tmpItems = [...items];
    let product = tmpItems.find((x) => x.productSku == productSku);
    if (product) {
      let tmpDressUp = { ...changingRoomContext.dressUp };
      if (!product.selected) {
        tmpItems.filter((x) => x.productGroup == product.productGroup).forEach((inGroupedProduct) => (inGroupedProduct.selected = false));
        product.selected = true;

        tmpDressUp[product.productGroup] = product;
      } else {
        product.selected = false;
        tmpDressUp[product.productGroup] = {};
      }
      setItems(tmpItems);
      setChangingRoomContext({ ...changingRoomContext, dressUp: tmpDressUp });
    }
  };

  const handleClickRemove = (productSku, productGroup) => {
    let newDressUp = changingRoomContext.dressUp;
    delete newDressUp[productGroup];
    setChangingRoomContext({ ...changingRoomContext, dressUp: newDressUp });
    let tmpItems = items.filter(x => x.productSku !== productSku);
    setItems(tmpItems)
    ShoppingCartService.removeShoppingCartItem(productSku);
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="column">
        {groups &&
          groups.map((group, groupIndex) => {
            let groupedItems = items.filter((x) => x.productGroup == group);
            return (
              <Grid key={`group${groupIndex}`} item container spacing={2} direction="column" className={classes.groupSection}>
                <Grid className={classes.groupName}>{group}</Grid>
                {groupedItems &&
                  groupedItems.map((item, index) => {
                    return (
                      <Grid
                        item
                        key={`changingItem${groupIndex}${index}`}
                        container
                        spacing={1}
                        className={clsx(classes.row, item.selected && 'selected')}
                        onClick={() => handleItemClick(item.productSku)}
                      >
                        <Grid item className="productImage">
                          <img src={item.imageUrl} />
                        </Grid>
                        <Grid item xs>
                          <p className="title">{item.name}</p>
                          <p className="color"><strong>Color: </strong>{item.color}</p>
                          {item.size && <p className="color"><strong>Size: </strong>{item.size}</p>}
                        </Grid>
                        {/* <Grid item>
                          <Typography
                            variant="body2"
                            classes={{ root: classes.removeBtn }}
                            onClick={(e) => {
                              e.stopPropagation()
                              handleClickRemove(item.productSku, item.productGroup)
                            }}>
                            Remove
                          </Typography>
                        </Grid> */}
                        <Grid item>
                          {item.size && changingRoomContext.body && item.size !== changingRoomContext.body.bodySize ? <Tooltip title={`Size mismatch!`} placement="top" arrow>
                            <MarkedIcon classes={{ root: classes.markedIcon }} />
                          </Tooltip> : <></>}
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default ChangingRoomItems;
