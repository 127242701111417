import React, { useState, useContext, useEffect } from "react";
import { makeStyles, Slide } from '@material-ui/core';
import { CouponContext } from '../contexts/CouponContext';
import CouponService from '../services/coupon.service';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        zIndex: 100,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    spinningCoin: {
        marginTop: -250,
    }
}))

const SpinningCoin = (props) => {
    const classes = useStyles();
    const { showSpiningCoin } = props;

    const startSound = () => {
        const sound = new Audio('/sounds/get-coupon.wav');
        sound.play();
    };

    useEffect(() => {
        if (showSpiningCoin) {
            startSound();
        }
    }, [showSpiningCoin])

    return (<>
        <Slide direction="up" in={showSpiningCoin} mountOnEnter unmountOnExit>
            <div className={classes.backdrop}>
                <img src='/images/coin-flip.gif' width={150} className={classes.spinningCoin} />
            </div>
        </Slide>
    </>);
}

export default SpinningCoin;