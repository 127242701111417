import { Box, Breadcrumbs, Grid, IconButton, Link, makeStyles, Slide } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStreetView } from '@fortawesome/free-solid-svg-icons/faStreetView';
import CategoriesMenuContent from './CetegoriesMenuContent';
import { PanoramaContext } from '../contexts/PanoramaContext';

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: 20,
    right: 10,
    zIndex: 100,
    position: 'absolute',
  },
  menusContainer: {
    alignItems: 'flex-end',
  },
  breadcrumbs: {
    fontSize: '0.75rem',
    backgroundColor: '#fff',
    borderRadius: 20,
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
    padding: '5px 10px',
    marginRight: 10,
  },
  streetViewButtonContainer: {
    margin: -10,
    borderRadius: '50%',
    padding: 10,
    background: 'radial-gradient(circle, rgba(0,0,0,0.25) 0%, rgba(255,255,255,0) 65%)',
  },
  streetViewButton: {
    padding: 0,
  },
  streetViewIcon: {
    color: '#fff',
    fontSize: '1.75em',
    cursor: 'pointer',
  },
}));

const mockCategories = [
  {
    title: 'New',
    menus: [
      { panoId: 'men-t-shirts', title: 'Men', url: '?id=men-t-shirts' },
      { panoId: 'women', title: 'Women', url: '?id=women' },
      { panoId: 'women-accessories', title: 'Accessories', url: '?id=women-accessories' },
      { panoId: 'kid', title: 'Toddler', url: '?id=kid' },
    ],
  },
  {
    title: 'Women',
    menus: [
      // { panoId: '3IBg3VUJjoFTOOQqzlFPDg', title: 'Activewear', url: '?id=3IBg3VUJjoFTOOQqzlFPDg' },
      // { panoId: 'Q1sYbAR0T4bWZ6ho9agFKg', title: 'Dresses & Jumpsuits', url: '?id=Q1sYbAR0T4bWZ6ho9agFKg' },
      // { panoId: 'Kbbd57_2Hbl7mI0mfg4xHQ', title: 'Fleece & Sweats', url: '?id=Kbbd57_2Hbl7mI0mfg4xHQ' },
      { panoId: 'women', title: 'Dresses & Jumpsuits', url: '?id=women' },
      { panoId: 'women-accessories', title: 'Accessories', url: '?id=women-accessories' },
      { panoId: 'women-swim', title: 'Swimwear', url: '?id=women-swim' },
      { panoId: 'women-bag', title: 'Bags & Shoes', url: '?id=women-bag' },
      { panoId: 'women-lingerie', title: 'Lingerie', url: '?id=women-lingerie' },
      // { panoId: '', title: 'Jackets & Coats', url: '' },
    ],
  },
  {
    title: 'Men',
    menus: [
      // { panoId: '4OpMweqgjWVyV7chzqc9nQ', title: 'Activewear', url: '?id=4OpMweqgjWVyV7chzqc9nQ' },
      { panoId: 'men-shirt', title: 'Shirts', url: '?id=men-shirt' },
      { panoId: 'men-t-shirts', title: 'T-Shirts & Singlets', url: '?id=men-t-shirts' },
      { panoId: 'men-4', title: 'Socks & Underwear', url: '?id=men-4' },
      // { panoId: '', title: 'Jackets & Coats', url: '' },
      // { panoId: 'men', title: 'Jeans', url: '?id=men' },
      // { panoId: '', title: 'Knitwear & Cardigans', url: '' },
    ],
  },
  {
    title: 'Teen',
    menus: [
      { panoId: 'kid', title: 'Activewear', url: '?id=kid' },
      { panoId: 'kid-dresses', title: 'Dresses & Jumpsuits', url: '?id=kid-dresses' },
      // { panoId: '', title: 'Fleece & Sweats', url: '' },
      // { panoId: '', title: 'Jackets & Coats', url: '' },
      // { panoId: '', title: 'Jeans', url: '' },
      // { panoId: '', title: 'Knitwear & Cardigans', url: '' },
    ],
  },
  {
    title: 'Baby & Toddler',
    menus: [
      { panoId: 'kid', title: 'Activewear', url: '?id=kid' },
      { panoId: 'kid-dresses', title: 'Dresses & Jumpsuits', url: '?id=kid-dresses' },
      // { panoId: '', title: 'Fleece & Sweats', url: '' },
      // { panoId: '', title: 'Jackets & Coats', url: '' },
      // { panoId: '', title: 'Jeans', url: '' },
      // { panoId: '', title: 'Knitwear & Cardigans', url: '' },
    ],
  },
];

const CategoriesMenu = (props) => {
  const [panoramaContext] = useContext(PanoramaContext);
  const classes = useStyles();
  const [showMenuContent, setShowMenuContent] = useState(true);
  const [breadcrumb, setBreadcrumb] = useState({
    level1: 'Street Front',
    level2: 'Women',
    level3: 'Activewear',
  });

  useEffect(() => {
    if (panoramaContext && panoramaContext.panoId) {
      let panoId = panoramaContext.panoId;
      mockCategories.forEach((category) => {
        let currentMenu = category.menus.find((x) => x.panoId === panoId);
        if (currentMenu) {
          setBreadcrumb({
            level1: 'Street Front',
            level2: category.title,
            level3: currentMenu.title,
          });
          return;
        }
      });
    }
  }, [panoramaContext]);

  const handleBreadcumbClick = () => { };

  return (
    <Box className={classes.root}>
      <Grid container direction="row" className={classes.menusContainer}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb" className={clsx(classes.breadcrumbs)}>
            <Link color="inherit" href="/" onClick={handleBreadcumbClick}>
              {breadcrumb.level1}
            </Link>
            <Link color="inherit" href="/" onClick={handleBreadcumbClick}>
              {breadcrumb.level2}
            </Link>
            <Link color="textPrimary" href="/" onClick={handleBreadcumbClick} aria-current="page">
              {breadcrumb.level3}
            </Link>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div className={classes.streetViewButtonContainer}>
            <IconButton
              color="secondary"
              className={classes.streetViewButton}
              onClick={() => {
                setShowMenuContent(!showMenuContent);
              }}
            >
              <FontAwesomeIcon className={classes.streetViewIcon} icon={faStreetView} />
            </IconButton>
          </div>
        </Grid>
      </Grid>
      <Slide direction="up" in={showMenuContent} mountOnEnter unmountOnExit>
        <CategoriesMenuContent categories={mockCategories} />
      </Slide>
    </Box>
  );
};

export default CategoriesMenu;
