import React, { forwardRef } from 'react';
import { Button, makeStyles, Paper, Tooltip, withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonBooth } from '@fortawesome/free-solid-svg-icons';
import ChangingRoom from './ChangingRoom';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    borderRadius: '50%',
  },
  icon: {
    color: '#fff',
    fontSize: '2em',
  },
  iconImg: {
    width: '100%',
    borderRadius: 8
  }

}));

const ChangingRoomButton = withStyles((theme) => ({
  root: {
    marginTop: '-50%',
    marginLeft: '-50%',
    padding: 10,
    border: '2px solid #FFF',
    color: '#FFF',
    background: 'radial-gradient(circle, rgba(0,0,0,0.5) 0%, rgba(250,250,250,1) 100%);',
    // backgroundColor: '#fff',
    // width: 72,
    // height: 72,
    // overflow: 'hidden',
    // alignItems: 'baseline',
    // boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
    minWidth: 0,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      // backgroundColor: '#f8f9fa',
    },
  },
  label: {
    padding: 0,
    '& svg': {
      width: '1em',
      height: '1em',
    },
  },
}))(Button);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 13,
    fontWeight: 'normal',
    maxWidth: 310,
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

const PoiChangingRoom = forwardRef((props, ref) => {
  const classes = useStyles();
  const { index, poi, handleOpenModal } = props;

  const body = <ChangingRoom poi={poi} />;

  const handleClick = () => {
    if (handleOpenModal) {
      handleOpenModal(body);
    }
  };

  return (
    <div ref={ref} className="poiPoint" data-poi-index={index}>
      <LightTooltip
        title={poi.title ? poi.title : `We have some new exciting offers for you upstairs. Go through this door and I will show you!`}
        aria-label="add"
        placement="top"
        arrow
      >
        <ChangingRoomButton color="primary" onClick={handleClick} className={classes.button} size="small">
          <FontAwesomeIcon className={classes.icon} icon={faPersonBooth} />
          {/* <img src={poi.botAvatar} className={classes.iconImg} /> */}
        </ChangingRoomButton>
      </LightTooltip>
    </div>
  );
});

export default PoiChangingRoom;
