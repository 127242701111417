import { Box, Button, Grid, makeStyles, Slider, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Rating, ToggleButton } from '@material-ui/lab';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { ButtonBack, ButtonNext, CarouselProvider, Slider as CorouselSlider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ProductsMockData from '../mockdatas/products';
import CustomerRateSlider from '../styled-components/CustomerRateSlider';
import FavoriteToggleButton from '../styled-components/FavoriteToggleButton';
import AddToBagButton from '../styled-components/AddToBagButton';
import { faThermometerEmpty } from '@fortawesome/free-solid-svg-icons';
import ShoppingCartService from '../services/shopping-cart.service';
import { ShoppingCartContext } from '../contexts/ShoppingCartContext';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon } from 'react-share';
import SuggestionTab from './SuggestionTab';
import SizeGuide from './SizeGuide';

const useProductImagesStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  imageSeletor: {},
  imageSelectorItem: {
    padding: theme.spacing(0.5),
    cursor: 'pointer',
    '& img': {
      width: 80,
      border: '3px solid #FFF',
    },
  },
  imageSelectorItemSelected: {
    '& img': {
      border: '3px solid #333',
    },
  },
  previewImage: {
    padding: theme.spacing(0.5),
    '& img': {
      border: '3px solid #FFF',
      width: 370,
    },
  },
}));
const ProductImages = (props) => {
  const { images } = props;
  const classes = useProductImagesStyles();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [previewImageUrl, setPreviewImageUrl] = useState('');

  useEffect(() => {
    setSelectedIndex(0);
    if (images) {
      setPreviewImageUrl(images[0]);
    }
  }, [images]);

  useEffect(() => {
    if (images) {
      setPreviewImageUrl(images[selectedIndex]);
    }
  }, [selectedIndex]);

  return (
    <div className={classes.root}>
      <div className={classes.imageSeletor}>
        {images &&
          images.map((image, index) => {
            return (
              <div
                key={`imageItem${index}`}
                className={clsx(classes.imageSelectorItem, index == selectedIndex && classes.imageSelectorItemSelected)}
                onClick={() => {
                  setSelectedIndex(index);
                }}
              >
                <img src={image} />
              </div>
            );
          })}
      </div>
      <div className={classes.previewImage}>
        <img src={previewImageUrl} />
      </div>
    </div>
  );
};

const useSizeSelectorStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  item: {
    cursor: 'pointer',
    marginRight: theme.spacing(0.5),
    padding: 0,
    border: '1px solid #979797',
    borderRadius: 2,
    width: 36,
    height: 42,
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#333',
    fontSize: 12,
    '&.selected': {
      border: '1px solid #333',
      backgroundColor: '#333',
      color: '#fff',
    },
    '&.disabled': {
      border: '1px solid #CACACA',
      backgroundColor: '#CACACA',
      color: '#666666',
      textDecoration: 'line-through',
      cursor: 'default',
    },
  },
}));
const SizeSelector = (props) => {
  const { sizes, handleSelectSize } = props;
  const classes = useSizeSelectorStyles();

  const [selectedSizeName, setSelectedSizeName] = useState('');

  useEffect(() => {
    // setSelectedSizeName('');
    if (sizes) {
      let size = sizes.find(x => x.available)
      console.log('sizes', size);
      setSelectedSizeName(size.name);
    }
  }, [sizes]);

  useEffect(() => {
    console.log("selectedSizeName", selectedSizeName);
    handleSelectSize(selectedSizeName)
  }, [selectedSizeName])

  return (
    <div className={classes.root}>
      {sizes &&
        sizes.map((size, index) => {
          return (
            <div
              key={`sizeItem${index}`}
              className={clsx(classes.item, size.name == selectedSizeName && 'selected', !size.available && 'disabled')}
              onClick={() => {
                if (size.available) {
                  setSelectedSizeName(size.name);
                }
              }}
            >
              <span>{size.name}</span>
            </div>
          );
        })}
    </div>
  );
};

const useProductHomeStyles = makeStyles((theme) => ({
  productName: {
    fontSize: 18,
    fontWeight: 700,
  },
  price: {
    fontSize: 28,
    fontWeight: 700,
    fontFamily: '"Roboto Condensed", sans-serif',
  },
  priceWithSale: {
    color: '#979797',
    textDecoration: 'line-through',
    paddingRight: theme.spacing(0.5),
  },
  salePrice: {
    color: '#ee1f3b',
    paddingRight: theme.spacing(0.5),
  },
  salePercent: {
    color: '#333',
    fontSize: 16,
  },
  weeklyInstallment: {
    color: '#333',
    fontSize: 14,
  },
  specialOfferBox: {
    backgroundColor: '#333',
    textAlign: 'center',
    position: 'relative',
    fontSize: 16,
    fontWeight: 700,
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    padding: 6,
  },
  specialOfferBoxIcon: {
    position: 'absolute',
    right: 10,
    color: '#fff',
  },
  ratingContainer: {
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    alignItems: 'center',
  },
  rating: {
    color: '#ee1f3b',
  },
  ratingInfo: {
    fontSize: 14,
    color: '#979797',
    paddingLeft: theme.spacing(0.5),
  },
  colorSelectorContainer: {},
  selectedColor: {
    padding: 0,
    marginBottom: 0,
    fontSize: 14,
    '& .title': {
      fontWeight: 700,
      marginRight: theme.spacing(0.5),
    },
  },
  colorSelector: {
    width: '345px',
    position: 'relative',
    display: 'flex',
  },
  colorSelectorItem: {
    padding: 0,
    cursor: 'pointer',
    '& img': {
      width: 45,
      border: '2px solid #FFF',
    },
  },
  colorSelectorItemSelected: {
    '& img': {
      border: '2px solid #333',
    },
  },
  colorSelectorButton: {
    position: 'absolute',
    zIndex: 100,
    top: 25,
    width: 25,
    height: 25,
    borderRadius: '50%',
    border: 0,
    backgroundColor: '#333',
    opacity: '0.3',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.back': {
      left: -5,
    },
    '&.next': {
      right: -5,
    },
  },
  sizeGuide: {
    fontSize: 14,
    marginBottom: 0,
    '& .title': {
      textDecoration: 'underline',
      fontWeight: 700,
      marginRight: theme.spacing(0.5),
    },
  },
  fitRateContainer: {
    fontSize: 14,
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  fitSliderContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  fitLabel: {
    fontSize: 12,
    color: '#979797',
    display: 'flex',
    justifyContent: 'space-between',
  },
  stockReadyText: {
    color: '#26a65b',
    fontSize: 14,
    marginBottom: theme.spacing(0.5),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  addToBagButton: {
    width: 280,
  },
  shareText: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: theme.spacing(0.5),
  },
  shareButtonsContainer: {
    display: 'flex',
    '& button': {
      marginRight: theme.spacing(1),
      borderRadius: '50%',
      boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
    },
  },
}));
const ProductHome = (props) => {
  const { productId } = props;
  const classes = useProductHomeStyles();
  const [selectedColorId, setSelectedColorId] = useState(1);
  const [selectedColor, setSelectedColor] = useState();
  const [selectedSize, setSelectedSize] = useState('')
  const [favorited, setFavorited] = useState(false);
  const [productData, setProductData] = useState({});
  const [shoppingCartContext, setShoppingCartContext] = useContext(ShoppingCartContext);

  const handleSelectSize = (selectedSize) => {
    console.log("selectedSize!!!", selectedSize);
    setSelectedSize(selectedSize)
  }

  useEffect(() => {
    if (productId) {
      let productData = ProductsMockData.find((x) => x.id === productId);
      setProductData(productData);
      setSelectedColorId(1);
    }
  }, [productId]);

  useEffect(() => {
    if (selectedColorId && productData.colors) {
      let selectedColor = productData.colors.find((x) => x.id == selectedColorId);

      setSelectedColor(selectedColor);
    }
  }, [selectedColorId, productData]);

  const handleAddToBag = () => {
    let product = {
      productSku: `${productId}${selectedColorId}${selectedSize.toLowerCase()}`,
      name: productData.name,
      color: selectedColor.name,
      size: selectedSize,
      imageUrl: selectedColor.optionImage,
      price: productData.price,
      salePercent: productData.salePercent,
      salePrice: productData.salePrice,
      productGroup: 'tops',
    }
    console.log("data", product);
    ShoppingCartService.addProduct(product, 1);
    setShoppingCartContext({ ...shoppingCartContext, lastUpdated: new Date() });
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <ProductImages images={selectedColor && selectedColor.images} />
        </Grid>
        <Grid item xs={5}>
          <p className={classes.productName}>{productData.name}</p>
          <p className={classes.price}>
            <span className={classes.priceWithSale}>{`$${productData.price}`}</span>
            <span className={classes.salePrice}>{`$${productData.salePrice}`}</span>
            <span className={classes.salePercent}>{`${productData.salePercent} off`}</span>
          </p>
          <p className={classes.weeklyInstallment}>
            {`Or buy now from AU$${productData.weeklyInstallment && productData.weeklyInstallment.toLocaleString(undefined, { minimumFractionDigits: 2 })
              } per week`}{' '}
            <br />
            <img src="/images/pay-later.png" height="30" />
          </p>

          <Box className={classes.specialOfferBox}>
            {`${productData.salePercent} off`}
            <InfoOutlinedIcon className={classes.specialOfferBoxIcon} />
          </Box>
          <Box className={classes.ratingContainer}>
            <Rating
              readOnly
              name="customized-empty"
              //defaultValue={productData.star}
              precision={0.5}
              className={classes.rating}
              value={productData.star || 0}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
            />
            <span className={classes.ratingInfo}>{`${productData.star} (${productData.starTotal})`}</span>
          </Box>

          <Box className={classes.colorSelectorContainer}>
            <p className={classes.selectedColor}>
              <span className="title">Colour</span>
              {selectedColor && selectedColor.name}
            </p>
            <div className={classes.colorSelector}>
              <CarouselProvider
                visibleSlides={7}
                naturalSlideWidth={50}
                naturalSlideHeight={75}
                totalSlides={productData.colors ? productData.colors.length : 7}
                isIntrinsicHeight={true}
                className={clsx(classes.colorSelector)}
              >
                <ButtonBack className={clsx(classes.colorSelectorButton, 'back')}>
                  <NavigateBeforeIcon />
                </ButtonBack>
                <CorouselSlider className="flex-grow-1">
                  {productData &&
                    productData.colors &&
                    productData.colors.map((color, index) => {
                      return (
                        <Slide
                          index={index}
                          key={`colorImage${index}`}
                          className={clsx(classes.colorSelectorItem, color.id == selectedColorId && classes.colorSelectorItemSelected)}
                          onClick={() => {
                            setSelectedColorId(color.id);
                          }}
                        >
                          <img src={color.optionImage} />
                        </Slide>
                      );
                    })}
                </CorouselSlider>
                <ButtonNext className={clsx(classes.colorSelectorButton, 'next')}>
                  <NavigateNextIcon />
                </ButtonNext>
              </CarouselProvider>
            </div>
          </Box>

          <SizeSelector sizes={selectedColor && selectedColor.sizes} handleSelectSize={handleSelectSize} />

          {/* <div className="astrafit-wdgt"
            data-id="138314"
            data-brand="Demo Brand"
            data-brand-code="Demo SKU"
            data-kind="Men's t-shirts"
            data-gender="2"
            data-sizes-list="L,M,S,XL"
            data-canonical="https://yoursite.com/items/123456"
            data-img=""
          ></div> */}
          <SizeGuide productName={productData.name} productSizeGuide={productData.sizeGuide} productId={productId} />
          <p className={classes.sizeGuide}>
            <span className="title">Size Guide</span>
            <span>In AU sizes unless otherwise stated</span>
          </p>
          <div className={classes.fitRateContainer}>
            <span>Customer are saying</span>
            <div className={classes.fitSliderContainer}>
              <CustomerRateSlider value={productData.fitRate || 0} aria-labelledby="discrete-slider" valueLabelDisplay="off" step={1} marks min={1} max={5} />
              <div className={classes.fitLabel}>
                <span>Runs Small</span>
                <span>Run Large</span>
              </div>
            </div>
          </div>
          <p className={classes.stockReadyText}>In stock and ready to ship</p>
          <div className={classes.buttonContainer}>
            <AddToBagButton className={classes.addToBagButton} onClick={() => handleAddToBag()} />
            <FavoriteToggleButton
              selected={favorited}
              onChange={() => {
                setFavorited(!favorited);
              }}
            />
          </div>
          <p className={classes.shareText}>Share</p>
          <div className={classes.shareButtonsContainer}>
            <FacebookShareButton url={window.location.href} quote="Test share via facebook">
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href} title="Test share via twitter">
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <EmailShareButton url={window.location.href} subject="Test share via email" body="This is a test share, ">
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
        </Grid>
      </Grid>
      <SuggestionTab />
    </div>
  );
};

export default ProductHome;
