import React, { forwardRef } from 'react';
import { Button, makeStyles, Typography, Grid, Zoom, IconButton } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
}

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 400,
    width: '100%',
    backgroundColor: '#e1e1e1',
    borderRadius: 8,
    position: 'relative',
  },
  content: {
    color: '#212529',
    padding: 18,
  },
  button: {
    padding: '10px 0',
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    borderRadius: 50,
    width: '100%',
    padding: 8,
    background: '#fff',
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 30%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 30%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.3);',
    '&:hover': {
      background: '#fff',
      '& $buttonNumber': {
        backgroundColor: '#dc3545',
        color: '#fff',
        boxShadow:
          '1px 0px 9px -2px rgb(220 53 69 / 100%); -webkit-box-shadow: 1px 0px 9px -2px rgb(220 53 69 / 100%); -moz-box-shadow: 1px 0px 9px -2px rgba(220,53,69,1);',
      },
    },
  },
  buttonNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 50,
    height: 50,
    fontSize: 18,
    padding: 10,
    borderRadius: '50%',
    color: '#212529',
    backgroundColor: '#cacaca',
    minWidth: 0,
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 30%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 30%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.3);',
  },
  buttonTitle: {
    fontSize: '1rem',
    paddingLeft: 16,
    color: '#212529',
  },
  closeIconButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: '#707070',
    padding: 0,
  },
  floorImg: {
    width: 100,
    position: 'absolute',
    right: 25
  }
}));

const FloorSelection = forwardRef((props) => {
  const classes = useStyles();
  let pathname = usePathname();
  const history = useHistory();
  const { poi, handleCloseModal } = props;

  const handleSelectLocation = (url) => {
    if (url) {
      history.push({
        pathname: pathname,
        search: url,
      });
      handleCloseModal();
    }
  };

  const handleCloseButtonClick = () => {
    if (handleCloseModal) {
      handleCloseModal();
    }
  };

  return (
    <>
      <Zoom in={true}>
        <div className={classes.container}>
          <IconButton color="secondary" className={classes.closeIconButton} onClick={handleCloseButtonClick}>
            <CloseIcon className={classes.icon} />
          </IconButton>
          <Grid container item direction="column" classes={{ root: classes.content }}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Select Floor
              </Typography>
            </Grid>
            <Grid item container xs={12} alignItems="center">
              {poi.locations
                .slice(0)
                .reverse()
                .map((location, index) => (
                  <Grid item container direction="column" alignItems="center" xs={12} className={classes.button} key={index}>
                    <Button classes={{ root: classes.buttonContent }} onClick={() => handleSelectLocation(location.url)}>
                      <Typography classes={{ root: classes.buttonNumber }} variant="button">
                        {location.floor}
                      </Typography>
                      <Typography variant="button" className={classes.buttonTitle}>
                        {location.title}
                      </Typography>
                      {location.imageUrl ? <img src={location.imageUrl} alt={location.title} className={classes.floorImg} /> : ''}
                    </Button>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </div>
      </Zoom>
    </>
  );
});
export default FloorSelection;
