import MockCoupons from '../mockdatas/coupons';
const KEY_COUPON = 'cp';
const KEY_COUPON_NOTIFICATION = 'cp-noti';
const KEY_POI = 'poi';

const PoiService = {
  moveFromHeadingAndPitch: function (worldHeading, worldPitch, modelHeading, modelPitch, svWidth, svHeight, svZoom) {
    var r = deg2rad(modelHeading);
    var h = deg2rad(modelPitch);
    var l = Math.cos(h);
    var u = svWidth / 2 / Math.tan((getFov(svZoom) * Math.PI) / 360);
    var d = u * l * Math.sin(r);
    var c = u * Math.sin(h);
    var g = u * l * Math.cos(r);
    var m = Math.cos(worldPitch);
    var f = Math.sin(worldPitch);
    var p = Math.cos(worldHeading);
    var v = Math.sin(worldHeading);
    var b = u * m * v;
    var w = u * f;
    var $ = u * m * p;
    var k = b * d + $ * g + w * c;
    var C = b * b + $ * $ + w * w;
    if (Math.abs(k) < 1e-6) {
      return {
        show: false,
      };
    } else {
      var y = C / k;
      if (y < 0) {
        return {
          show: false,
        };
      } else {
        var z = y * d;
        var M = y * g;
        var x = y * c;
        var P = -f * v;
        var I = -f * p;
        var S = m;
        var _ = m * p;
        var A = -m * v;
        var L = 0;
        var T = Math.sqrt(_ * _ + A * A + L * L);
        _ /= T;
        A /= T;
        L /= T;
        var F = z * _ + M * A + x * L;
        var O = z * P + M * I + x * S;
        var D = svWidth / 2;
        var V = svHeight / 2;
        var N = D + F;
        var H = V - O;
        return {
          show: true,
          left: N,
          bottom: svHeight - H,
          top: H,
          right: svWidth - N,
        };
      }
    }
  },
  getHeadingAndPitch: function (worldHeading, worldPitch, svWidth, svHeight, svZoom, u, v) {
    var PI = Math.PI;
    var cos = Math.cos;
    var sin = Math.sin;
    var tan = Math.tan;
    var sqrt = Math.sqrt;
    var atan2 = Math.atan2;
    var asin = Math.asin;

    var fov = (90 * PI) / 180.0;
    var width = svWidth;
    var height = svHeight;

    var h0 = (worldHeading * PI) / 180.0;
    var p0 = (worldPitch * PI) / 180.0;

    // var u = svWidth / 2 / Math.tan((getFov(svZoom) * Math.PI) / 360);
    // var f = (0.5 * width) / tan(0.5 * fov);
    var f = svWidth / 2 / Math.tan((getFov(svZoom) * Math.PI) / 360);

    var x0 = f * cos(p0) * sin(h0);
    var y0 = f * cos(p0) * cos(h0);
    var z0 = f * sin(p0);

    var du = u - width / 2;
    var dv = height / 2 - v;

    var ux = sgn(cos(p0)) * cos(h0);
    var uy = -sgn(cos(p0)) * sin(h0);
    var uz = 0;

    var vx = -sin(p0) * sin(h0);
    var vy = -sin(p0) * cos(h0);
    var vz = cos(p0);

    var x = x0 + du * ux + dv * vx;
    var y = y0 + du * uy + dv * vy;
    var z = z0 + du * uz + dv * vz;

    var R = sqrt(x * x + y * y + z * z);
    var h = atan2(x, y);
    var p = asin(z / R);

    return {
      heading: (h * 180.0) / PI,
      pitch: (p * 180.0) / PI,
    };
  },
  deg2rad: deg2rad,
  getPoiCouponDetailsById: function (couponId) {
    return getPoiCouponDetailsById(couponId);
  },
  updateCoupon: function (coupon) {
    updateCoupon(coupon);
  },
  addNewCollectedCouponNotification: function (couponId) {
    addNewCollectedCouponNotification(couponId);
  },
  getPoi: function () {
    return getPoi();
  },
  addPOIPriceTag: function (poiData) {
    addPOIPriceTag(poiData);
  },
  removePOIPriceTag: function (poiId) {
    removePOIPriceTag(poiId);
  },
  updatePOIPriceTag: function (poiData) {
    updatePOIPriceTag(poiData);
  },
  getPOIByPanoId: function (panoId) {
    return getPOIByPanoId(panoId);
  },
  getPOIByPoiId: function (poiId) {
    return getPOIByPoiId(poiId);
  },
};

function sgn(x) {
  return x >= 0 ? 1 : -1;
}

function getFov(e) {
  return 0.91667 * Math.pow(e, 3) - 1.35714 * Math.pow(e, 2) - 37.4881 * e + 127.186;
}

function deg2rad(deg) {
  deg = deg * 0.017453292519943295;
  while (deg < 0) {
    deg += 6.28318530718;
  }
  while (deg > 6.28318530718) {
    deg -= 6.28318530718;
  }
  return deg;
}

function getPoiCouponDetailsById(couponId) {
  let coupons = getCoupons();
  let coupon = coupons.find((x) => x.id === couponId);
  return coupon;
}

function getCoupons() {
  let dataJsonString = localStorage.getItem(KEY_COUPON);
  if (dataJsonString) {
    return JSON.parse(dataJsonString);
  } else {
    localStorage.setItem(KEY_COUPON, JSON.stringify(MockCoupons));
    return MockCoupons;
  }
}
function updateCoupon(coupon) {
  let coupons = getCoupons();

  coupons = coupons.filter((x) => x.id !== coupon.id);
  coupons.push(coupon);

  localStorage.setItem(KEY_COUPON, JSON.stringify(coupons));
}

function getCouponNotifications() {
  let dataJsonString = localStorage.getItem(KEY_COUPON_NOTIFICATION);

  if (dataJsonString) {
    return JSON.parse(dataJsonString);
  } else {
    return [];
  }
}

function addNewCollectedCouponNotification(couponId) {
  let couponNotifications = getCouponNotifications();
  couponNotifications.push({ couponId: couponId, type: 'collected' });
  localStorage.setItem(KEY_COUPON_NOTIFICATION, JSON.stringify(couponNotifications));
}

function getPoi() {
  let poiJsonString = localStorage.getItem(KEY_POI);
  if (poiJsonString) {
    return JSON.parse(poiJsonString);
  }
  return [];
}

function getPOIByPanoId(panoId) {
  let poi = getPoi();
  if (poi && poi.length > 0) {
    const poiByPano = poi.filter(x => x.panoId === panoId);
    return poiByPano;
  } else { return []; }
}

function addPOIPriceTag(poiData) {
  let poi = getPoi();
  poi.push(poiData);
  console.log('poi', poi);
  localStorage.setItem(KEY_POI, JSON.stringify(poi));
}

function updatePOIPriceTag(poiData) {
  const poi = getPoi();
  let poiNew = poi.filter(x => x.poiId !== poiData.poiId);
  poiNew.push(poiData);
  localStorage.setItem(KEY_POI, JSON.stringify(poiNew));
}

function removePOIPriceTag(poiId) {
  const poi = getPoi();
  const poiData = poi.filter(x => x.poiId !== poiId);
  // poi.push(poiData);
  console.log('poi', poiData);
  localStorage.setItem(KEY_POI, JSON.stringify(poiData));
}

function getPOIByPoiId(poiId) {
  let poi = getPoi();
  if (poi && poi.length > 0) {
    const poiByPoiId = poi.find(x => x.poiId === poiId);
    console.log("poiByPoiId", poiByPoiId);
    return poiByPoiId;
  } else { return null; }
}


export default PoiService;
