import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import ReplyIcon from '@material-ui/icons/Reply';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        color: '#fff',
        textTransform: 'initial',
        fontSize: 11,
        borderRadius: 5,
        width: 50,
        boxShadow: 'none; -webkit-box-shadow: none; -moz-box-shadow: none;',
        backgroundColor: '#007bff',
        '&:hover': {
            backgroundColor: '#0083cd',
            boxShadow: 'none; -webkit-box-shadow: none; -moz-box-shadow: none;',
        },
    },
    containedPrimary: {
        backgroundColor: '#007bff',
        '&:hover': {
            backgroundColor: '#0083cd',
            boxShadow: 'none; -webkit-box-shadow: none; -moz-box-shadow: none;',
        },
    },
    icon: {
        transform: 'scaleX(-1)',
        fontSize: '1.2rem'
    }
}))

const ShareIconButton = (props) => {
    const classes = useStyles();
    return <>
        <Button variant="contained" className={clsx(classes.containedPrimary)} classes={{ root: classes.root }} color="primary" {...props}>
            <ReplyIcon classes={{ root: classes.icon }} />
            Share
        </Button>
    </>
}

export default ShareIconButton;