import React, { Component, useState } from 'react';
import { Route } from 'react-router';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';

import './custom.css';
import { Main360view } from './components/Main360view';
import Layout from './components/Layout';
import { PanoramaContext } from './contexts/PanoramaContext';
import { BackgroundMediaContext } from './contexts/BackgroundMediaContext';
import { ShoppingCartContext } from './contexts/ShoppingCartContext';
import { CouponContext } from './contexts/CouponContext';
import { ChangingRoomContext } from './contexts/ChangingRoomContext';

export default function App() {
  const [panoramaContext, setPanoramaContext] = useState();
  const [backgroundMediaContext, setBackgroundMediaContext] = useState();
  const [shoppingCartContext, setShoppingCartContext] = useState();
  const [couponContext, setCouponContext] = useState();
  const [changingRoomContext, setChangingRoomContext] = useState({});
  return (
    <PanoramaContext.Provider value={[panoramaContext, setPanoramaContext]}>
      <BackgroundMediaContext.Provider value={[backgroundMediaContext, setBackgroundMediaContext]}>
        <ShoppingCartContext.Provider value={[shoppingCartContext, setShoppingCartContext]}>
          <ChangingRoomContext.Provider value={[changingRoomContext, setChangingRoomContext]}>
            <CouponContext.Provider value={[couponContext, setCouponContext]}>
              <Layout>
                {/* <Route exact path='/' component={Home} />
        <Route path='/counter' component={Counter} />
        <Route path='/fetch-data' component={FetchData} /> */}
                <Route path="/" component={Main360view} />
              </Layout>
            </CouponContext.Provider>
          </ChangingRoomContext.Provider>
        </ShoppingCartContext.Provider>
      </BackgroundMediaContext.Provider>
    </PanoramaContext.Provider>
  );
}
