const MockCoupons = [
  {
    id: '1',
    title: 'Grand opening coupons',
    description: 'Visit 10 locations in the store to get the coupons. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut metus nec neque facilisis finibus id at dolor. Aenean fermentum ultrices mi, ac fermentum ex pulvinar ut. Aliquam sodales lobortis justo, eu mollis magna placerat ac. Donec pellentesque convallis augue, at commodo lacus volutpat non. Nunc eu volutpat dolor. Nullam.',
    imageUrl: '',
    requirement: {
      type: 'CountLocations',
      totalLocations: 10,
      metLocationIds: [],
    },
    expiredDate: new Date().setMonth(12),
    collected: false,
  },
  {
    id: '2',
    title: '30% discount on Swimwear',
    description: 'Visit 5 locations in the store to get the coupons. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut metus nec neque facilisis finibus id at dolor. Aenean fermentum ultrices mi, ac fermentum ex pulvinar ut. Aliquam sodales lobortis justo, eu mollis magna placerat ac. Donec pellentesque convallis augue, at commodo lacus volutpat non. Nunc eu volutpat dolor. Nullam.',
    imageUrl: '',
    requirement: {
      type: 'CountLocations',
      totalLocations: 5,
      metLocationIds: [],
    },
    expiredDate: new Date().setMonth(12),
    collected: false,
  },
  {
    id: '3',
    title: 'Free Shipping around the world',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut metus nec neque facilisis finibus id at dolor. Aenean fermentum ultrices mi, ac fermentum ex pulvinar ut. Aliquam sodales lobortis justo, eu mollis magna placerat ac. Donec pellentesque convallis augue, at commodo lacus volutpat non. Nunc eu volutpat dolor. Nullam.',
    imageUrl: '',
    requirement: {
      type: 'Normal',
    },
    expiredDate: new Date().setMonth(12),
    collected: true,
  },
  {
    id: '4',
    title: 'Changing Room is now open: Free canvas bag',
    description: 'Visit changing room to get free canvas bag',
    imageUrl: '',
    requirement: {
      type: 'SpecificLocations',
      locations: ['men-3', 'men-changing-room'],
      metLocationIds: [],
    },
    expiredDate: new Date().setMonth(12),
    collected: false,
  },
  {
    id: '5',
    title: '10% discount',
    description: 'The coupon is at the store entrance, let\'s find out',
    imageUrl: '',
    requirement: {
      type: 'POICoupon',
    },
    expiredDate: new Date().setMonth(12),
    collected: false,
  },
  {
    id: '6',
    title: '20% discount',
    description: 'The coupon is at the store, let\'s find out',
    imageUrl: '',
    requirement: {
      type: 'POICoupon',
    },
    expiredDate: new Date().setMonth(12),
    collected: false,
    display: true
  },
];

export default MockCoupons;
