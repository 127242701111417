import { makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { ChangingRoomContext } from '../contexts/ChangingRoomContext';
import GenderToggleButton from '../styled-components/GenderToggleButton';
import ChangingRoomMeasurement from './ChangingRoom-Measurement';
import clsx from 'clsx';
import Draggable from 'react-draggable';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.8)',
    borderRadius: 5,
  },
  model: {
    // backgroundImage: 'url("images/model-men.png")',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    opacity: 1,
    position: 'relative',
    aspectRatio: '5/14',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
      cursor: 'pointer'
    },
    '& div': {
      // opacity: 0.5,
      display: 'flex',
      justifyContent: 'center',
      // maxHeight: '100%',
      // maxWidth: '100%',
      backgroundColor: 'transparent',
    },
  },
  headMale: {
    position: 'absolute',
    backgroundColor: 'green',
    width: '18%',
    height: '9%',
    top: '1%',
    left: '41%',
  },
  headFemale: {
    position: 'absolute',
    backgroundColor: 'green',
    width: '17%',
    height: '8%',
    top: '0%',
    left: '41%',
  },
  eyeMale: {
    position: 'absolute',
    backgroundColor: 'blue',
    width: '17%',
    height: '3%',
    top: '9.5%',
    left: '43%',
    alignItems: 'flex-start',
  },
  eyeFemale: {
    position: 'absolute',
    backgroundColor: 'blue',
    width: '16%',
    height: '3%',
    top: '7.5%',
    left: '42%',
    alignItems: 'flex-start',
  },
  body: {
    position: 'absolute',
    backgroundColor: 'red',
    width: '66%',
    height: '35%',
    top: '18%',
    left: '18%',
  },
  leg: {
    position: 'absolute',
    backgroundColor: 'yellow',
    width: '50%',
    height: '43%',
    top: '43%',
    left: '31%',
  },
  foot: {
    position: 'absolute',
    backgroundColor: 'pink',
    width: '66%',
    height: '7%',
    top: '90%',
    left: '17%',
  },
  genderToggleButton: {
    position: 'absolute',
    top: 10,
    left: 10,
  },
  measurementButton: {
    position: 'absolute',
    top: 70,
    left: 10,
  }
}));

const ChangingRoomDressUp = (props) => {
  let classes = useStyles();
  const [changingRoomContext, setChangingRoomContext] = useContext(ChangingRoomContext);
  const [isMale, setIsMale] = useState();

  const getImageUrl = (key) => {
    if (changingRoomContext && changingRoomContext.dressUp) {
      return changingRoomContext.dressUp[key]?.imageUrl;
    }
    return null;
  };

  useEffect(() => {
    console.log("changingRoomContext DressUp", changingRoomContext);
    if (changingRoomContext && Object.keys(changingRoomContext).length === 0 || changingRoomContext.gender === '' || changingRoomContext.gender === 'male') {
      setIsMale(true)
    } else if (changingRoomContext.gender === 'female') {
      setIsMale(false)
    }
  }, [])

  useEffect(() => {
    if (isMale) {
      setChangingRoomContext({ ...changingRoomContext, gender: 'male' })
    } else if (isMale === false) {
      setChangingRoomContext({ ...changingRoomContext, gender: 'female' })
    }
  }, [isMale])

  return (
    <div className={classes.root}>
      <div className={clsx(classes.model)} style={{ backgroundImage: `url(${isMale ? "images/model-manbody.png" : "images/model-womanbody.png"})` }}>
        <Draggable handle=".handleHead" bounds="parent">
          <div className={isMale ? classes.headMale : classes.headFemale}>{getImageUrl('head') && <img src={getImageUrl('head')} draggable="false" alt="head" className="handleHead"></img>}</div>
        </Draggable>
        <Draggable handle=".handleEyes" bounds="parent">
          <div className={isMale ? classes.eyeMale : classes.eyeFemale}>{getImageUrl('eyes') && <img src={getImageUrl('eyes')} draggable="false" alt="eyes" className="handleEyes"></img>}</div>
        </Draggable>
        <Draggable handle=".handleTops" bounds="parent">
          <div className={classes.body}>{getImageUrl('tops') && <img src={getImageUrl('tops')} draggable="false" alt="tops" className="handleTops"></img>}</div>
        </Draggable>
        <Draggable handle=".handleLegs" bounds="parent">
          <div className={classes.leg}>{getImageUrl('legs') && <img src={getImageUrl('legs')} draggable="false" alt="legs" className="handleLegs"></img>}</div>
        </Draggable>
        <div className={classes.foot}>{getImageUrl('feet') && <img src={getImageUrl('feet')} draggable="false" alt="feet"></img>}</div>
      </div>

      <GenderToggleButton
        className={classes.genderToggleButton}
        selected={isMale}
        onChange={() => {
          setIsMale(!isMale);
        }}
      />
      <ChangingRoomMeasurement className={classes.measurementButton} />
    </div>
  );
};
export default ChangingRoomDressUp;
