import React, { useEffect, useState } from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Checkbox,
  withStyles,
  FormControlLabel,
} from '@material-ui/core';
import CottonOnSvg from '../styled-components/CottonOnSvg';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import CouponService from '../services/coupon.service';
import CloseIcon from '@material-ui/icons/Close';

const BlackCheckbox = withStyles({
  root: {
    padding: 0,
    '&$checked': {
      color: '#000',
    },
  },
  checked: {},
})((props) => <Checkbox {...props} />);

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: 450,
    padding: theme.spacing(1),
    borderRadius: 16,
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  rewardTitleText: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1, 2),
    borderLeft: '2px solid #000',
    fontSize: 24,
    fontWeight: 'bold',
  },
  dialogContent: {
    padding: 0,
    '&:first-child': {
      paddingTop: 0,
    },
  },
  dialogActions: {
    padding: 0,
  },
  formControlLabel: {
    margin: theme.spacing(1),
  },
  container: {
    // borderTop: '2px solid #000',
    padding: 8,
    '& .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(2),
    },
  },
  firstBox: {},
  couponTitle: {
    fontWeight: 'bold',
  },
  icon: {
    fontSize: 32,
  },
  learnMoreBtn: {
    fontWeight: 'bold',
  },
  closeIconButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: '#707070',
    padding: 0,
  },
}));
const CouponNewsModal = (props) => {
  const classes = useStyles();
  const { showLoadingScreen } = props;
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [coupon, setCoupon] = useState();

  useEffect(() => {
    const couponsLocal = JSON.parse(localStorage.getItem('cp'));
    if (couponsLocal) {
      console.log('couponsLocal', couponsLocal);
      let coupon = couponsLocal.find((x) => x.display === true);
      console.log('coupon', coupon);
      setCoupon(coupon);
    }
  }, []);

  useEffect(() => {
    if (coupon && Object.keys(coupon).length > 0) {
      handleOpen();
    }
  }, [coupon]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    let couponResult = { ...coupon, display: false };
    CouponService.updateCoupon(couponResult);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    console.log('event.target.checked', event.target.checked);
  };
  return (
    <>
      {coupon && (
        <Dialog open={open} onClose={handleClose} maxWidth={'lg'} aria-labelledby="form-dialog-title" classes={{ paper: classes.dialogPaper }}>
          <IconButton color="secondary" className={classes.closeIconButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitle }} disableTypography>
            <CottonOnSvg width={150} />
            <span className={classes.rewardTitleText}>Gift Hunter</span>
          </DialogTitle>
          <DialogContent classes={{ root: classes.dialogContent }}>
            <Grid container className={classes.container}>
              <Grid item className={classes.secondBox}>
                <span className={classes.couponTitle}>LOOK FOR THE</span>
              </Grid>
              <Grid item className={classes.thirdBox}>
                <CardGiftcardIcon classes={{ root: classes.icon }} />
              </Grid>
              <Grid item className={classes.fourthBox}>
                <span>When you see this gift, grab it and see what you got</span>
                {/* <span className={classes.learnMoreBtn}>Learn More{' >>'}</span> */}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions classes={{ root: classes.dialogActions }}>
            <FormControlLabel
              control={<BlackCheckbox checked={checked} onChange={handleChange} />}
              label="Do not display again."
              classes={{ root: classes.formControlLabel }}
            />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default CouponNewsModal;
