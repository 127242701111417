import { Grid, makeStyles } from '@material-ui/core';
import React, { forwardRef } from 'react';
import ChangingRoomBot from './ChangingRoom-Bot';
import ChangingRoomDressUp from './ChangingRoom-DressUp';
import ChangingRoomItems from './ChangingRoom-Items';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 60px)',
    zIndex: 100,
    display: 'flex',
  },
  changingRoomContainer: {
    width: 750,
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: 10,
    display: 'flex',
    padding: 20,
    height: '100%',
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
    zIndex: 1,
    position: 'relative'
  },
  containerItem: {
    flexGrow: 1,
    height: '100%',
  },
}));

const ChangingRoom = forwardRef((props, ref) => {
  const { poi } = props
  let classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.changingRoomContainer}>
        <Grid container direction="row" spacing={2}>
          <Grid item className={classes.containerItem} xs={7}>
            <ChangingRoomDressUp></ChangingRoomDressUp>
          </Grid>
          <Grid item className={classes.containerItem} xs={5}>
            <ChangingRoomItems></ChangingRoomItems>
          </Grid>
        </Grid>
        <ChangingRoomBot poi={poi} />
      </div>
      {/* <div className={classes.advisorContainer}></div> */}
    </div>
  );
});

export default ChangingRoom;
