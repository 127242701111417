import { Box, IconButton, makeStyles, Slide, Badge, Tooltip } from '@material-ui/core';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons';
import MoneyIcon from '@material-ui/icons/Money';
import CouponsMenuContent from './CouponsMenuContent';
import CouponService from '../services/coupon.service';
import { CouponContext } from '../contexts/CouponContext';
import SpinningCoin from './SpinningCoin';

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: 20,
    left: 55,
    zIndex: 100,
    position: 'absolute',
  },
  iconButton: {
    padding: 0,
  },
  iconButtonContainer: {
    margin: -10,
    borderRadius: '50%',
    padding: 10,
    background: 'radial-gradient(circle, rgba(0,0,0,0.25) 0%, rgba(255,255,255,0) 65%)',
  },
  icon: {
    color: '#fff',
    fontSize: '1em',
    cursor: 'pointer',
  },
}));

const CouponsMenu = (props) => {
  const classes = useStyles();
  const [showMenuContent, setShowMenuContent] = useState(false);
  const [showSpiningCoin, setShowSpiningCoin] = useState(false);

  const [couponContext] = useContext(CouponContext);
  const [totalCouponNotifications, setTotalCouponNotifications] = useState(0);

  useEffect(() => {
    //NK: Just for feed mockdata
    let coupons = CouponService.getCoupons();

    if (couponContext) {
      let couponNotifications = CouponService.getCouponNotifications();
      console.log('couponNotifications', couponNotifications);
      setTotalCouponNotifications(couponNotifications.length);
    } else {
      CouponService.resetCoupons()
    }
  }, [couponContext]);

  const toggleMenuContent = () => {
    setShowMenuContent(true)
    setTimeout(() => {
      setShowMenuContent(false)
    }, 4000);
  }

  const toggleSpiningCoin = () => {
    setShowSpiningCoin(true)
    setTimeout(() => {
      setShowSpiningCoin(false)
    }, 2500);
  }

  useEffect(() => {
    if (totalCouponNotifications) {
      toggleMenuContent()
      toggleSpiningCoin()
    }
  }, [totalCouponNotifications])

  return (
    <>
      <Box className={classes.root}>
        <Tooltip title={`Rewards`} placement="top" arrow>
          <div className={classes.iconButtonContainer}>
            <Badge badgeContent={totalCouponNotifications} color="secondary">
              <IconButton
                color="secondary"
                className={classes.iconButton}
                onClick={() => {
                  setShowMenuContent(!showMenuContent);
                }}
              >
                <FontAwesomeIcon className={classes.icon} icon={faMoneyBillAlt} />
              </IconButton>
            </Badge>
          </div>
        </Tooltip>
        <Slide direction="up" in={showMenuContent} mountOnEnter unmountOnExit>
          <CouponsMenuContent
            onCloseButtonClick={() => {
              setShowMenuContent(false);
            }}
          />
        </Slide>
      </Box>
      <SpinningCoin showSpiningCoin={showSpiningCoin} />
    </>
  );
};

export default CouponsMenu;
