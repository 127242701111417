import React, { Component, useEffect, useRef, useState, forwardRef } from 'react';
import { Button, Modal, makeStyles, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    borderBottomLeftRadius: '50%',
    minWidth: 0,
  },
}));

const PoiButton = forwardRef((props, ref) => {
  const classes = useStyles();
  const { index, handleOpenModal } = props;

  const body = (
    <div className={classes.paper}>
      <h2 id="simple-modal-title">Title from {index}</h2>
      <p id="simple-modal-description">Duis mollis, est non commodo luctus, nisi erat porttitor ligula.</p>
    </div>
  );

  const handleOpen = () => {
    if (handleOpenModal) {
      handleOpenModal(body);
    }
  };

  return (
    <div ref={ref} className="poiPoint imagePoiButton poiIcon" data-poi-index={index}>
      <Tooltip title={`This is tooltips for ${index}`} aria-label="add">
        <Button variant="contained" color="primary" onClick={handleOpen} className={classes.button}>
          {index}
        </Button>
      </Tooltip>
    </div>
  );
});

export default PoiButton;
