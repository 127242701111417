import React, { useState, useEffect, forwardRef, useContext } from 'react';
import { Container, Grid, makeStyles, Button, Typography, IconButton } from '@material-ui/core';
import CottonOnSvg from '../styled-components/CottonOnSvg';
import ShoppingCartService from '../services/shopping-cart.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons/faCartPlus';
import { ShoppingCartContext } from '../contexts/ShoppingCartContext';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: 8,
    maxHeight: 'calc(100% - 100px)',
    margin: theme.spacing(0, 2),
  },
  shoppingCartTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  shoppingCartTitleText: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1, 2),
    borderLeft: '2px solid #000',
    fontSize: 24,
    fontWeight: 'bold',
  },
  //   checkoutBox: {
  //     maxHeight: 'calc(100% - 56px)',
  //   },
  productList: {
    maxHeight: 'calc(100vh - 205px)',
    padding: 0,
    overflowY: 'auto',
  },
  productContainer: {
    padding: theme.spacing(2, 0),
    borderBottom: '1px solid #b1b1b1',
    '&:first-child': {
      borderTop: '1px solid #b1b1b1',
    },
  },
  space: {
    padding: theme.spacing(0, 1),
  },
  image: {
    width: 120,
    height: 120,
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  checkoutContainer: {
    paddingLeft: theme.spacing(2),
  },
  checkoutDetails: {
    padding: theme.spacing(1),
    backgroundColor: '#e1e1e1',
    '& .MuiGrid-item': {
      marginBottom: theme.spacing(2),
    },
  },
  checkoutLabel: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkoutTotalLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #000',
    paddingTop: theme.spacing(1),
  },
  checkoutBtnBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  checkoutBtn: {
    textTransform: 'unset',
    color: '#fff',
    background: '#26a65b',
    '&:hover': {
      background: '#00884b',
    },
  },
  buttonShadow: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  removeBtn: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  salePrice: {
    color: 'red',
  },
  priceLineThrough: {
    textDecoration: 'line-through',
    color: '#979797',
    fontSize: 14,
  },
  emptyCart: {
    width: '100%',
    height: 'calc(100% - 56px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  iconEmptyCart: {
    width: '1em',
    height: '1em',
    fontSize: 50,
    color: 'rgba(0, 0, 0, 0.54)',
    margin: theme.spacing(2),
  },
  closeIconButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: '#707070',
    padding: 0,
  },
}));
const CheckOutModal = forwardRef((props) => {
  const classes = useStyles();
  const { handleCloseModal } = props;
  const [shoppingCartContext, setShoppingCartContext] = useContext(ShoppingCartContext);
  const [products, setProducts] = useState();
  const [orderTotalPrice, setOrderTotalPrice] = useState();

  useEffect(() => {
    const items = ShoppingCartService.getShoppingCartItems();
    console.log('items', items);
    if (items) {
      setProducts(items);
    }
  }, [shoppingCartContext]);

  useEffect(() => {
    console.log('products', products);
    if (products && products.length > 0) {
      const listPrice = products.map((x) => {
        if (x.salePrice) {
          return x.salePrice * x.totalItem;
        } else {
          return x.price * x.totalItem;
        }
      });
      const orderTotalPrice = listPrice.reduce((total, price) => total + price);
      setOrderTotalPrice(orderTotalPrice);
    }
  }, [products]);

  useEffect(() => {
    if (orderTotalPrice) {
      console.log('totalPrice', orderTotalPrice);
    }
  }, [orderTotalPrice]);

  const handleClickRemove = (productSku) => {
    console.log('Id remove', productSku);
    ShoppingCartService.removeShoppingCartItem(productSku);
    setShoppingCartContext({ ...shoppingCartContext, lastUpdated: new Date() });
  };
  return (
    <>
      <Container maxWidth="md" classes={{ root: classes.container }}>
        <IconButton color="secondary" className={classes.closeIconButton} onClick={handleCloseModal}>
          <CloseIcon className={classes.icon} />
        </IconButton>
        <div className={classes.shoppingCartTitle}>
          <CottonOnSvg width={150} />
          <span className={classes.shoppingCartTitleText}>Shopping Cart</span>
        </div>
        {products && products.length > 0 ? (
          <>
            <Grid container alignItems="flex-start" className={classes.checkoutBox}>
              <Grid item container sm={9} xs={12} classes={{ root: classes.productList }}>
                {products &&
                  products.map((product, index) => (
                    <Grid key={index} item container classes={{ root: classes.productContainer }}>
                      <Grid item>
                        <div className={classes.image}>
                          <img className={classes.img} alt="complex" src={product.imageUrl} />
                        </div>
                      </Grid>
                      <Grid item xs container>
                        <Grid item xs container direction="column">
                          <Grid item xs>
                            <Typography gutterBottom variant="subtitle1">
                              {product.name}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <strong>Color:</strong> {product.color}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Size:</strong> {product.size}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2" classes={{ root: classes.removeBtn }} onClick={() => handleClickRemove(product.productSku)}>
                              Remove
                            </Typography>
                          </Grid>
                        </Grid>
                        {/* <Grid item classes={{ root: classes.space }}>
                                <Typography variant="subtitle1">Size: <strong>{product.size}</strong></Typography>
                            </Grid> */}
                        <Grid item classes={{ root: classes.space }}>
                          <Typography variant="subtitle1">
                            Qty: <strong>{product.totalItem}</strong>
                          </Typography>
                        </Grid>
                        <Grid item classes={{ root: classes.space }}>
                          {product.salePrice ? (
                            <Typography variant="subtitle1" className={classes.salePrice}>
                              <span className={classes.priceLineThrough}>{`$${product.price * product.totalItem}`}</span>
                              <strong>{`$${product.salePrice * product.totalItem}`}</strong>
                            </Typography>
                          ) : (
                            <Typography variant="subtitle1">
                              <strong>{`$${product.price * product.totalItem}`}</strong>
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
              <Grid item container sm={3} xs={12} classes={{ root: classes.checkoutContainer }}>
                <Grid item xs container direction="column" justifyContent="space-evenly" classes={{ root: classes.checkoutDetails }}>
                  <Grid item xs classes={{ root: classes.checkoutLabel }}>
                    <Typography variant="subtitle1">
                      <strong>Price</strong>
                    </Typography>
                    <Typography variant="subtitle1">{orderTotalPrice && <strong>{`$${orderTotalPrice.toLocaleString()}`}</strong>}</Typography>
                  </Grid>
                  <Grid item xs classes={{ root: classes.checkoutLabel }}>
                    <Typography variant="subtitle1">
                      <strong>Delivery</strong>
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>$2.00</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs classes={{ root: classes.checkoutTotalLabel }}>
                    <Typography variant="subtitle1">
                      <strong>Total</strong>
                    </Typography>
                    <Typography variant="subtitle1">{orderTotalPrice && <strong>{`$${(orderTotalPrice + 2.0).toLocaleString()}`}</strong>}</Typography>
                  </Grid>
                  <Grid xs item classes={{ root: classes.checkoutBtnBox }}>
                    <Button variant="contained" classes={{ root: classes.checkoutBtn, contained: classes.buttonShadow }}>
                      Checkout Now
                    </Button>
                  </Grid>
                  <Grid xs item>
                    <img src="/images/payment.png" alt="payment" style={{ width: '100%' }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <div className={classes.emptyCart}>
            <FontAwesomeIcon icon={faCartPlus} className={classes.iconEmptyCart} />
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              <strong>Shopping Cart is Empty.</strong>
            </Typography>
            <Button variant="contained" classes={{ root: classes.checkoutBtn, contained: classes.buttonShadow }} onClick={handleCloseModal}>
              Go Shopping
            </Button>
          </div>
        )}
      </Container>
    </>
  );
});
export default CheckOutModal;
