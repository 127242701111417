import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Badge, Button, ButtonGroup, Container, IconButton, makeStyles, Paper, Tooltip, withStyles } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Product from './Product';
import clsx from 'clsx';
import TimeIcon from '@material-ui/icons/WatchLaterRounded';
import HotIcon from '@material-ui/icons/WhatshotRounded';
import useOnClickOutside from '../hooks/useOnClickOutside';
import EditIcon from '@material-ui/icons/EditRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import MoveIcon from '@material-ui/icons/OpenWithRounded';
import Draggable from 'react-draggable';
import ReactDOM from 'react-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    borderRadius: '50%',
  },
  sale: {
    backgroundColor: 'rgba(255, 0, 0, 0.5) !important',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 0, 0.7) !important',
    },
  },
  pulsing: {
    backgroundColor: 'red',
    animationName: 'pricePulse',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
  },
  fireBtn: {
    backgroundColor: '#fff !important',
    padding: '2px !important',
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
  },
  fireIcon: {
    fontSize: '2.1rem',
    color: 'red',
    animation: 'colorChange 2s infinite',
    animationTimingFunction: 'linear',
  },
  timeIcon: {
    fontSize: '2rem',
    animation: 'rotateIcon 5s infinite',
    animationTimingFunction: 'linear',
  },
  menu: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    backgroundColor: '#fff',
    borderRadius: 4,
    zIndex: 10000
  },
  btnGroup: {
    color: 'yellow !important',
    '& .MuiIconButton-root': {
      padding: 4,
    }
  },
  dragging: {
    transform: 'initial !important',
  },
  dragged: {
    transform: 'translate(0px, 0px) !important',
  }
}));

const NormalPriceButton = withStyles((theme, props) => ({
  root: {
    marginTop: '-50%',
    marginLeft: '-50%',
    padding: 5,
    border: '2px solid #FFF',
    color: '#FFF',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    minWidth: 0,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
  },
  label: {
    padding: 0,
    '& svg': {
      width: '0.65em',
      height: '0.65em',
    },
  },
}))(Button);

const SaleBadge = withStyles((theme) => ({
  badge: {
    top: -10,
    right: 3,
    backgroundColor: '#f11934',
  },
}))(Badge);

const NewArrivalBadge = withStyles((theme) => ({
  badge: {
    top: -10,
    right: 4,
    background: 'linear-gradient(90deg, rgba(255,186,81,1) 0%, rgba(247,167,55,1) 100%)',
    fontWeight: 'normal',
  },
}))(Badge);

const EditBadge = withStyles((theme) => ({
  badge: {
    top: -50,
    right: 36,
    background: '#f8f9fa',
    fontWeight: 'normal',
    padding: 4,
    height: 'auto',
    color: 'rgb(0 0 0 / 87%)',
    cursor: 'pointer',
    '&:hover': {
      background: '#babcbd',
    }
  },
}))(Badge);

const DeleteBadge = withStyles((theme) => ({
  badge: {
    top: -50,
    right: -36,
    background: '#f8f9fa',
    fontWeight: 'normal',
    padding: 4,
    height: 'auto',
    color: 'rgb(0 0 0 / 87%)',
    cursor: 'pointer',
    '&:hover': {
      background: '#babcbd',
    }
  },
}))(Badge);

const MoveBadge = withStyles((theme) => ({
  badge: {
    marginTop: -50,
    top: 0,
    right: 0,
    background: '#f8f9fa',
    fontWeight: 'normal',
    padding: 4,
    height: 'auto',
    color: 'rgb(0 0 0 / 87%)',
    cursor: 'pointer',
    '&:hover': {
      background: '#babcbd',
    },
  },
}))(Badge);

const PriceTooltip = withStyles((theme) => ({
  tooltip: {
    //marginTop: -10,
    right: 3,
    backgroundColor: '#333',
  },
  arrow: {
    color: '#333',
  },
}))((props) => <Tooltip placement="top-start" {...props} arrow />);

const PoiPriceTag = forwardRef((props, ref) => {
  const classes = useStyles();
  const btnRef = useRef();
  const [showMenu, setShowMenu] = useState(false);
  const { index, handleOpenModal, handleCloseModal, poi, pathname, removePoi, movePoi, openEditPoi } = props;

  const body = <Product productId={poi.productId} onCloseButtonClick={handleCloseModal} />;

  const handleOpen = (e) => {
    if (handleOpenModal) {
      handleOpenModal(body);
    }
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
  }

  const showIcon = (tagIcon) => {
    switch (tagIcon) {
      case 'time':
        return <TimeIcon className={classes.timeIcon} />;
      case 'hot':
        return <HotIcon className={classes.fireIcon} />;
      default:
        return <LocalOfferIcon />;
    }
  }

  const handleOnMouseUp = (e) => {
    if (e.button === 2 && pathname === '/admin') {
      e.stopPropagation();
      // console.log("Right Click!!!!!");
      setShowMenu(true);
    }
  }

  const handleDelete = () => {
    if (poi.poiId) {
      removePoi(poi.poiId);
      setShowMenu(false);
    }
  }

  const handleEdit = () => {
    if (poi.poiId) {
      openEditPoi(poi.poiId);
      setShowMenu(false);
    }
  }

  const handleDragStop = () => {
    // console.log("Move Move!!!!!")
    if (poi.poiId) {
      const position = btnRef.current.getBoundingClientRect()
      movePoi(poi, position.x, position.y);
      // console.log("handleDragStop!!!!!", poi.poiId);
    }
  }

  useOnClickOutside(btnRef, () => {
    handleCloseMenu()
  });

  return (
    <Draggable handle=".handleDrag" onStop={handleDragStop} positionOffset={{ x: 0, y: 0 }} position={{ x: 0, y: 0 }}>
      <div ref={ref} className="poiPoint" data-poi-index={index}>

        <NewArrivalBadge color="secondary" overlap="rectangular" badgeContent={poi && poi.newArrival ? 'New' : 0}>
          <SaleBadge color="secondary" overlap="rectangular" badgeContent={poi && poi.discount ? poi.discount : 0}>

            <span ref={btnRef} >
              <EditBadge overlap="rectangular" badgeContent={<EditIcon />} invisible={!showMenu} onClick={handleEdit} />
              <MoveBadge overlap="rectangular" className="handleDrag" badgeContent={<MoveIcon />} invisible={!showMenu} />
              <DeleteBadge overlap="rectangular" badgeContent={<DeleteIcon />} invisible={!showMenu} onClick={handleDelete} />
              <PriceTooltip title={`$${(poi && poi.price) || ''}`}>
                <NormalPriceButton color="primary" onClick={handleOpen} onMouseUp={handleOnMouseUp} className={clsx(classes.button, poi && poi.discount && classes.sale, poi && poi.pulsing && classes.pulsing, poi && poi.tagIcon === 'hot' && classes.fireBtn)} size="small">
                  {poi && showIcon(poi.tagIcon)}
                </NormalPriceButton>
              </PriceTooltip>
            </span>

          </SaleBadge>
        </NewArrivalBadge>

      </div>
    </Draggable>
  );
});

export default PoiPriceTag;
