import React from 'react';
import { Slider, withStyles } from '@material-ui/core';

const CustomerRateSlider = withStyles((theme) => ({
  track: {
    opacity: 0,
  },
  rail: {
    opacity: 0.33,
  },
  mark: {
    opacity: 1,
    backgroundColor: '#979797',
  },
  markActive: {
    opacity: 1,
    backgroundColor: '#979797',
  },
  thumb: {
    borderRadius: 0,
    border: '1px solid #979797',
    backgroundColor: '#0d6efd',
    '&.Mui-disabled': {
      width: 10,
      height: 10,
      marginTop: -4,
      marginLeft: -5,
    },
  },
}))((props) => <Slider disabled {...props} valueLabelDisplay="off" ThumbComponent="div" component="div" />);

export default CustomerRateSlider;
