import React, { useEffect, useState } from "react";
import { TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, makeStyles, IconButton } from '@material-ui/core';
import CottonOnSvg from "../styled-components/CottonOnSvg";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiBackdrop-root': {
            backgroundColor: '#f8f9fa',
        }
    },
    dialogPaper: {
        minWidth: 450,
        padding: theme.spacing(1),
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    dialogTitleText: {
        marginLeft: theme.spacing(2),
        padding: theme.spacing(1, 2),
        borderLeft: '2px solid #000',
        fontSize: 24,
        fontWeight: 'bold'
    },
    dialogContentText: {
        color: '#212529',
        fontWeight: 600,
        marginBottom: 0,
        marginTop: theme.spacing(1)
    },
    dialogActions: {
        padding: '8px 24px'
    },
    signInBtn: {
        textTransform: 'unset',
        background: '#26a65b',
        color: '#fff',
        '&:hover': {
            background: '#00884b'
        }
    },
}));

const LoginScreen = (props) => {
    const classes = useStyles();
    const { open, message, handleLogin } = props;
    const [username, setUsername] = useState();
    const [password, setPassword] = useState()
    const [usernameMessage, setUsernameMessage] = useState('');
    const [passwordMessage, setPasswordMessage] = useState('');

    const handleClick = (e) => {
        e.preventDefault();
        if (username && password) {
            const data = { username: username, password: password };
            setUsernameMessage('');
            setPasswordMessage('');
            handleLogin(data);
        } else {
            if (!username) {
                setUsernameMessage('Please Enter Username!!');
            } else { setUsernameMessage(''); }
            if (!password) {
                setPasswordMessage('Please Enter Password!!');
            } else { setPasswordMessage(''); }
        }
    }

    useEffect(() => {
        if (message === false) {
            setUsernameMessage('username or password invalid.');
            setPasswordMessage('username or password invalid.');
        }
    }, [message])

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleClick(e);
        }
    }

    return <>
        <Dialog open={open} aria-labelledby="form-dialog-title" classes={{ root: classes.root, paper: classes.dialogPaper }}>
            <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitle }} disableTypography>
                <CottonOnSvg width={150} />
                <span className={classes.dialogTitleText}>Login</span>
            </DialogTitle>
            <DialogContent>
                <DialogContentText classes={{ root: classes.dialogContentText }}>
                    Username
                </DialogContentText>
                <TextField
                    variant="outlined"
                    autoComplete="off"
                    margin="dense"
                    id="username"
                    type="text"
                    fullWidth
                    placeholder="Username"
                    helperText={usernameMessage}
                    error={usernameMessage ? true : false}
                    value={username || ''}
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <DialogContentText classes={{ root: classes.dialogContentText }}>
                    Password
                </DialogContentText>
                <TextField
                    variant="outlined"
                    autoComplete="off"
                    margin="dense"
                    id="password"
                    type="password"
                    fullWidth
                    placeholder="Password"
                    helperText={passwordMessage}
                    error={passwordMessage ? true : false}
                    value={password || ''}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogActions }}>
                <Button onClick={handleClick} variant="contained" classes={{ root: classes.signInBtn }}>
                    Sign Me In
                </Button>
            </DialogActions>
        </Dialog>
    </>
}
export default LoginScreen;