import { makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { BackgroundMediaContext } from '../contexts/BackgroundMediaContext';
import PlayToggleButton from '../styled-components/PlayToggleButton';
import VolumeSlider from '../styled-components/VolumeSlider';
import UserService from '../services/user.service';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    bottom: 18,
    left: 95,
    zIndex: 100,
    display: 'flex',
    alignItems: 'flex-end'
  },
  reactPlayer: {},
}));

const BackgroundMedia = (props) => {
  const classes = useStyles();
  const [url, setUrl] = useState('');
  const [playing, setPlaying] = useState(false);
  const [backgroundMediaContext] = useContext(BackgroundMediaContext);
  const [volume, setVolume] = useState()

  useEffect(() => {
    const userVolume = UserService.getUserVolume();
    console.log('userVolume', userVolume.volume);
    handleVolumeChange(userVolume.volume)
  }, [])

  useEffect(() => {
    let backgroundMediaUrl = '';
    if (backgroundMediaContext && backgroundMediaContext.url !== undefined) {
      backgroundMediaUrl = backgroundMediaContext.url;
    }
    setUrl(backgroundMediaUrl);

    if (backgroundMediaContext && backgroundMediaContext.playing !== undefined) {
      setPlaying(backgroundMediaContext.playing);
    }
  }, [backgroundMediaContext]);

  useEffect(() => {
    if (url) {
      setPlaying(true);
    } else {
      setPlaying(false);
    }
  }, [url]);

  const handlePlayerReady = () => {
    console.log('handlePlayerReady');
  };
  const handlePlayerStart = () => {
    console.log('handlePlayerStart');
  };
  const handlePlayerPlay = () => {
    console.log('handlePlayerPlay');
  };
  const handlePlayerError = (e) => {
    console.log('handlePlayerError: ', e);
    //setPlaying(false);
  };

  const handleVolumeChange = (newValue) => {
    setVolume(newValue);
  };

  useEffect(() => {
    UserService.updateUserVolume(volume);
  }, [volume])

  return (
    <div className={classes.root}>
      <ReactPlayer
        className={classes.reactPlayer}
        url={url}
        playing={playing}
        loop={true}
        volume={volume}
        width="auto"
        height="auto"
        onReady={handlePlayerReady}
        onStart={handlePlayerStart}
        onPlay={handlePlayerPlay}
        onError={handlePlayerError}
      />

      <PlayToggleButton
        selected={playing}
        onChange={() => {
          setPlaying(!playing);
        }}
      />
      {volume >= 0 && <VolumeSlider volume={volume} handleVolumeChange={handleVolumeChange} />}
    </div>
  );
};

export default BackgroundMedia;
