import React, { forwardRef, useContext } from 'react';
import { Badge, Button, makeStyles, Paper, Tooltip, withStyles } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Product from './Product';
import clsx from 'clsx';
import ReactPlayer from 'react-player';
import { BackgroundMediaContext } from '../contexts/BackgroundMediaContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
}));

const PoiButton = withStyles((theme, props) => ({
  root: {
    borderRadius: '50%',
    marginTop: '-50%',
    marginLeft: '-50%',
    padding: 2,
    border: '2px solid #FFF',
    color: '#FFF',
    backgroundColor: 'rgba(241, 25, 52, 0.8)',
    minWidth: 0,
    '&:hover': {
      backgroundColor: 'rgba(241, 25, 52, 1)',
    },
  },
  label: {
    padding: 0,
    '& svg': {
      width: '1em',
      height: '1em',
    },
  },
}))(Button);

const PoiVideo = forwardRef((props, ref) => {
  const classes = useStyles();
  const { index, handleOpenModal, handleCloseModal, poi } = props;
  const [backgroundMediaContext, setBackgroundMediaContext] = useContext(BackgroundMediaContext);

  const body = <ReactPlayer className={classes.reactPlayer} url={poi.url} playing={true} width="960px" height="540px" />;

  const handleOpen = () => {
    if (handleOpenModal) {
      setBackgroundMediaContext({ ...backgroundMediaContext, playing: false });
      handleOpenModal(body);
    }
  };

  return (
    <div ref={ref} className="poiPoint" data-poi-index={index}>
      <PoiButton color="primary" onClick={handleOpen} size="small">
        <PlayArrowIcon />
      </PoiButton>
    </div>
  );
});

export default PoiVideo;
