import Perspective from 'perspectivejs';
export class GifPoster {
  constructor(frames) {
    this.frameIndex = 0;
    this.frameImageData = null;
    this.animateTimer = null;
    this.requestAnimateId = null;
    this.posterCanvasCtx = null;
    this.drawArea = null;
    this.perspective = null;

    this.frames = frames;
    this.gifCanvas = document.createElement('canvas');
    this.gifCanvas.width = frames[0].dims.width;
    this.gifCanvas.height = frames[0].dims.height;
    this.gifCtx = this.gifCanvas.getContext('2d');

    this.tempCanvas = document.createElement('canvas');
    this.tempCtx = this.tempCanvas.getContext('2d');
  }

  _drawPatch(frame) {
    var dims = frame.dims;

    if (!this.frameImageData || dims.width != this.frameImageData.width || dims.height != this.frameImageData.height) {
      this.tempCanvas.width = dims.width;
      this.tempCanvas.height = dims.height;
      this.frameImageData = this.tempCtx.createImageData(dims.width, dims.height);
    }

    // set the patch data as an override
    this.frameImageData.data.set(frame.patch);

    // draw the patch back over the canvas
    this.tempCtx.putImageData(this.frameImageData, 0, 0);

    this.gifCtx.drawImage(this.tempCanvas, dims.left, dims.top);
  }

  _manipulate() {
    if (!this.perspective) {
      this.perspective = new Perspective(this.posterCanvasCtx, this.gifCanvas);
    }
    this.perspective.p.cvso = this.gifCanvas;
    this.perspective.p.ctxo = this.gifCtx;
    this.perspective.draw(this.drawArea);
  }

  _startAnimation() {
    // if (this.frameIndex % 50 == 0) {
    //   console.log('_startAnimation', this.frameIndex);
    // }
    // get the frame
    var frame = this.frames[this.frameIndex];

    var start = new Date().getTime();

    if (frame.disposalType === 2) {
      this.gifCtx.clearRect(0, 0, this.gifCanvas.width, this.gifCanvas.height);
    }

    // draw the patch
    this._drawPatch(frame);

    // perform manipulation
    this._manipulate();

    // update the frame index
    this.frameIndex++;
    if (this.frameIndex >= this.frames.length) {
      this.frameIndex = 0;
    }
    var end = new Date().getTime();
    var diff = end - start;

    // delay the next gif frame
    var self = this;
    this.animateTimer = setTimeout(function () {
      self.requestAnimateId = requestAnimationFrame(() => {
        self._startAnimation();
      });
    }, Math.max(0, Math.floor(frame.delay - diff)));
  }

  stopAnimation() {
    if (this.animateTimer) {
      clearTimeout(this.animateTimer);
      this.animateTimer = 0;
    }

    if (this.requestAnimateId) {
      cancelAnimationFrame(this.requestAnimateId);
    }
  }

  startAnimation(posterCanvasCtx, drawArea) {
    this.stopAnimation();
    this.posterCanvasCtx = posterCanvasCtx;
    this.drawArea = drawArea;
    this._startAnimation();
  }
}
