import React from "react";

const CottonOnSvg = (props) => {

    return <>
        <svg width="177" height="60" viewBox="0 0 177 60" {...props}>
            <g fill="none" fill-rule="evenodd">
                <g>
                    <g>
                        <path fill="#1D1D1B" d="M129.005 38.116c1.882 0 3.406-1.455 3.406-3.249s-1.524-3.248-3.406-3.248c-1.88 0-3.405 1.454-3.405 3.248 0 1.794 1.525 3.25 3.405 3.25m0-15.348c-1.88 0-3.405-1.454-3.405-3.248 0-1.794 1.525-3.248 3.405-3.248 1.882 0 3.406 1.454 3.406 3.248 0 1.794-1.524 3.248-3.406 3.248" transform="translate(-21 -84) translate(21 84)"></path>
                        <g transform="translate(-21 -84) translate(21 84) translate(3 11)">
                            <path fill="#E40E2E" d="M171.79 31.69V.7h-5.199v18.38l-2.043-4.933L158.836.699h-4.97V31.69h5.295V13.543l2.182 4.931 5.99 13.216h4.458zm-27.196-7.399V8.097c0-1.815-1.067-3.025-2.971-3.025-1.857 0-2.925 1.21-2.925 3.025V24.29c0 1.815 1.068 3.025 2.925 3.025 1.904 0 2.97-1.21 2.97-3.025zm5.805-.373c0 4.935-3.343 8.471-8.776 8.471-5.387 0-8.774-3.536-8.774-8.47V8.468c0-4.886 3.387-8.422 8.774-8.422 5.433 0 8.776 3.536 8.776 8.421v15.45zm-30.877 7.772V.7h-5.2v18.38l-2.043-4.933L106.57.699h-4.97V31.69h5.295V13.543l2.18 4.931 5.991 13.216h4.457zm-27.195-7.399V8.097c0-1.815-1.067-3.025-2.972-3.025-1.857 0-2.925 1.21-2.925 3.025V24.29c0 1.815 1.068 3.025 2.925 3.025 1.905 0 2.972-1.21 2.972-3.025zm5.805-.373c0 4.935-3.344 8.471-8.777 8.471-5.387 0-8.775-3.536-8.775-8.47V8.468c0-4.886 3.388-8.422 8.775-8.422 5.433 0 8.777 3.536 8.777 8.421v15.45zM77.938 5.816V.699H60.757v5.117h5.712V31.69h5.804V5.816h5.665zm-19.823 0V.699h-17.18v5.117h5.71V31.69h5.804V5.816h5.666zM32.488 24.291V8.097c0-1.815-1.068-3.025-2.971-3.025-1.857 0-2.925 1.21-2.925 3.025V24.29c0 1.815 1.068 3.025 2.925 3.025 1.903 0 2.971-1.21 2.971-3.025zm5.805-.373c0 4.935-3.343 8.471-8.776 8.471-5.387 0-8.776-3.536-8.776-8.47V8.468c0-4.886 3.39-8.422 8.776-8.422 5.433 0 8.776 3.536 8.776 8.421v15.45zm-21.02.373c0 4.654-2.88 8.098-8.359 8.098C3.39 32.39 0 28.666 0 23.873V8.515C0 3.722 3.389 0 8.914 0c5.478 0 8.358 3.444 8.358 8.097v3.77h-5.154V8.144c0-1.816-1.02-3.072-3.111-3.072-2.09 0-3.157 1.256-3.157 2.977v16.288c0 1.723 1.067 2.98 3.157 2.98s3.11-1.257 3.11-3.072v-3.724h5.155v3.77z"></path>
                        </g>
                        <path d="M0 0H177V60H0z" transform="translate(-21 -84) translate(21 84)"></path>
                    </g>
                </g>
            </g>
        </svg>
    </>
}
export default CottonOnSvg