import { withStyles } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import React from 'react';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale, faFemale } from '@fortawesome/free-solid-svg-icons';

const GenderToggleButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    borderRadius: 2,
    padding: 9,
    border: '1px solid #979797',
    width: 48,
    height: 48,
    borderRadius: 24,
    color: '#ee1f3b',
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
  },
  selected: {
    color: 'black !important',
    backgroundColor: '#fff !important',
  },
  icon: {
    fontSize: '2em',
    cursor: 'pointer',
  },
}))((props) => (
  <ToggleButton {...props} value="check" selected={props.selected} onChange={props.onChange}>
    {props.selected ? <FontAwesomeIcon className={props.classes.icon} icon={faMale} /> : <FontAwesomeIcon className={props.classes.icon} icon={faFemale} />}
  </ToggleButton>
));

export default GenderToggleButton;
