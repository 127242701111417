import React, { useEffect, useState, useContext } from 'react';
import { IconButton, makeStyles, Typography, Card, CardContent, withStyles, Button } from '@material-ui/core';
import { ChangingRoomContext } from '../contexts/ChangingRoomContext';

const useStyles = makeStyles((theme) => ({
    label: {
        width: 60,
        margin: 0,
        color: '#000',
    },
    card: {
        position: 'absolute',
        width: '100%',
        maxWidth: 220,
        minWidth: 100,
        background: '#fff',
        overflow: 'visible',
        zIndex: 1,
        boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
        padding: 16,
        borderRadius: 16,
        left: '102%',
        bottom: 150,
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: -15,
            left: -6,
            borderTop: '25px solid black',
            borderTopColor: '#fff',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            transform: 'rotate(22deg)',
        },
    },
    cardContent: {
        maxHeight: 500,
        padding: 0,
        textTransform: 'uppercase',
        scrollbarWidth: 'none',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '-ms-overflow-style': 'none',
        '&:last-child': {
            paddingBottom: 0
        }
    },
    imgButton: {
        width: 74,
        borderBottomLeftRadius: '30%',
        borderBottomRightRadius: '30%',
    }
}))

const BotIconButton = withStyles((theme) => ({
    root: {
        position: 'absolute',
        bottom: 16,
        right: -80,
        padding: 0,
        borderRadius: '35%',
        color: '#FFF',
        background: 'transparent',
        zIndex: 1,
        minWidth: 0,
    },
}))(Button);

const ChangingRoomBot = (props) => {
    const { poi } = props;
    const image = '/images/avatar-g.png'
    const classes = useStyles();
    const [message, setMessage] = useState();
    const [changingRoomContext] = useContext(ChangingRoomContext);
    //const flowxoWidgetKey = 'eyJ0aGVtZSI6IiM2N2MxOGUiLCJ3ZWIiOnsiYm90SWQiOiI2MTc3NjRkYzQ1NjVmNjAwN2NhY2Q3NzciLCJ0aGVtZSI6IiM2N2MxOGUifX0'
    const flowxoWidgetKey = 'eyJ0aGVtZSI6IiM2N2MxOGUiLCJ3ZWIiOnsiYm90SWQiOiI2MTdmOTVlMGJhYjQ3ODAwM2UwNWMxODYiLCJ0aGVtZSI6IiM2N2MxOGUifX0';
    function sendNewMessage(message) {
        const FxoMessenger = window.FxoMessenger;
        if (FxoMessenger) {
            FxoMessenger.sendMessage(message);
        }
    }

    function messengerReady() {
        const FxoMessenger = window.FxoMessenger;
        console.log('flowxo script callback', FxoMessenger, FxoMessenger.getState());
        FxoMessenger.create();
        // Wait until the messenger is fully
        // ready, then send a message
        FxoMessenger.on('stateChanged', function (state) {
            console.log('flowxo stateChanged', FxoMessenger);
            if (state === 'connected') {
                console.log('flowxo connected', FxoMessenger);
            }
        });

        // Subscribe to all messages received,
        // logging them to the console
        FxoMessenger.on('messageReceived', function (message) {
            console.log('messageReceived !!', message);
            setMessage(message.text);
        });
    }

    useEffect(() => {
        messengerReady();

        return () => {
            if (window.FxoMessenger) {
                window.FxoMessenger.removeListeners();
                window.FxoMessenger.destroy();
            }
        };
    }, []);

    const getDressUp = () => {
        if (changingRoomContext && changingRoomContext.dressUp) {
            let dressUpKey = Object.keys(changingRoomContext.dressUp).filter((key) => {
                return Object.keys(changingRoomContext.dressUp[key]).length !== 0;
            })
            let dressUp = dressUpKey.map((key) => {
                if (Object.keys(changingRoomContext.dressUp[key]).length !== 0) {
                    return changingRoomContext.dressUp[key]
                }
            })
            return dressUp;
        }
        return null;
    }

    useEffect(() => {
        if (changingRoomContext && Object.keys(changingRoomContext).length !== 0) {
            let dressUp = getDressUp();
            if (dressUp && dressUp.length !== 0) {
                if (dressUp.length === 1 && dressUp.find(x => x.productGroup === 'tops' && x.color === 'black' && x.name === 'T Shirt')) {
                    sendNewMessage('black T shirt')
                } else if (dressUp.length === 1 && dressUp.find(x => x.productGroup === 'legs' && x.color === 'blue' && x.name === 'Jeans')) {
                    sendNewMessage('blue jeans')
                } else if (dressUp.length === 1 && dressUp.find(x => x.productGroup === 'head' && x.color === 'red' && x.name === 'Cap')) {
                    sendNewMessage('red cap')
                } else if (dressUp.length === 4) {
                    sendNewMessage('pick all')
                } else if (dressUp.length >= 1 && dressUp.find(x => x.productGroup === 'eyes' && x.name === 'Sun glasses')) {
                    sendNewMessage('Sun glasses')
                } else if (dressUp.length > 1 && dressUp.find(x => x.productGroup === 'tops' && x.color === 'black' && x.name === 'T Shirt')) {
                    sendNewMessage('with black T shirt')
                }
            }
        }
    }, [changingRoomContext])

    const closeMessage = () => {
        setMessage()
    }

    return (
        <>
            <BotIconButton {...props} onClick={() => sendNewMessage('press bot')}>
                <img src={poi && poi.botAvatar || image} className={classes.imgButton} />
            </BotIconButton>

            {message && (
                <Card classes={{ root: classes.card }} variant="outlined" onClick={closeMessage}>
                    <CardContent classes={{ root: classes.cardContent }}>
                        <Typography color="initial" variant="body2">
                            {message}
                        </Typography>
                    </CardContent>
                </Card>
            )}
        </>
    );
};
export default ChangingRoomBot;
