import React, { useState } from "react";
import { TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, makeStyles, IconButton } from '@material-ui/core';
import CottonOnSvg from "../styled-components/CottonOnSvg";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    memberBtn: {
        textTransform: 'unset',
        paddingLeft: 16,
        cursor: 'pointer',
        color: '#212529',
        fontWeight: 'bold',
        '&:hover': {
            background: 'none'
        }
    },
    dialogPaper: {
        minWidth: 450,
        padding: theme.spacing(1)
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    dialogTitleText: {
        marginLeft: theme.spacing(2),
        padding: theme.spacing(1, 2),
        borderLeft: '2px solid #000',
        fontSize: 24,
        fontWeight: 'bold'
    },
    dialogContentText: {
        color: '#212529',
        fontWeight: 600,
        marginBottom: 0,
        marginTop: theme.spacing(1)
    },
    dialogActions: {
        padding: '8px 24px'
    },
    signInBtn: {
        textTransform: 'unset',
        background: '#26a65b',
        color: '#fff',
        '&:hover': {
            background: '#00884b'
        }
    },
    createAccountBtn: {
        color: '#212529',
        textTransform: 'none'
    },
    closeIconButton: {
        position: 'absolute',
        top: 10,
        right: 10,
        color: '#707070',
        padding: 0,
    },
}));

const LoginMenu = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return <>
        <Button disableRipple className={classes.memberBtn} onClick={handleOpen}>Members Only</Button>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" classes={{ paper: classes.dialogPaper }}>
            <IconButton color="secondary" className={classes.closeIconButton} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogTitle id="form-dialog-title" classes={{ root: classes.dialogTitle }} disableTypography>
                <CottonOnSvg width={150} />
                <span className={classes.dialogTitleText}>Login</span>
            </DialogTitle>
            <DialogContent>
                <DialogContentText classes={{ root: classes.dialogContentText }}>
                    Username
                </DialogContentText>
                <TextField
                    variant="outlined"
                    autoComplete="off"
                    margin="dense"
                    id="email"
                    label="Email"
                    type="email"
                    fullWidth
                />
                <DialogContentText classes={{ root: classes.dialogContentText }}>
                    Password
                </DialogContentText>
                <TextField
                    variant="outlined"
                    autoComplete="off"
                    margin="dense"
                    id="password"
                    label="Password"
                    type="password"
                    fullWidth
                />
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogActions }}>
                <Button onClick={handleClose} color="primary" classes={{ root: classes.createAccountBtn }}>
                    Create Account
                </Button>
                <Button onClick={handleClose} variant="contained" classes={{ root: classes.signInBtn }}>
                    Sign Me In
                </Button>
            </DialogActions>
        </Dialog>
    </>
}
export default LoginMenu