import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { Typography, Button, makeStyles, Grid, Card, CardHeader, CardContent, CardMedia, withStyles } from '@material-ui/core';
import CouponIcon from '@material-ui/icons/ConfirmationNumber';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import clsx from 'clsx';
import PoiService from '../services/poi.service';
import dateFormat from 'dateformat';
import { CouponContext } from '../contexts/CouponContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  pulsing: {
    backgroundColor: 'red',
    animationName: 'pricePulse',
    animationDuration: '1s',
    animationIterationCount: 'infinite',
  },
  card: {
    maxWidth: 600,
    margin: 32,
    background: '#ddd'
  },
  couponContainer: {
    position: 'relative',
    background: '#fff',
    borderRadius: '0.5em'
  },
  cardMedia: {
    height: 120,
    //paddingTop: '56.25%', // 16:9
  },
  couponContentTop: {
    padding: '1.5em'
  },
  couponContentBottom: {
    padding: '1.5em',
    textAlign: 'center'
  },
  couponDivider: {
    border: '1px dashed #dddddd',
    position: 'relative',
    '&::before': {
      content: '" "',
      display: 'block',
      height: 20,
      width: 20,
      position: 'absolute',
      top: '-0.5em',
      left: '-0.7em',
      borderRadius: '50%',
      background: '#ddd',
    },
    '&::after': {
      content: '" "',
      display: 'block',
      height: 20,
      width: 20,
      position: 'absolute',
      top: '-0.5em',
      right: '-0.7em',
      borderRadius: '50%',
      background: '#ddd'
    }
  },
  collectBtn: {
    borderRadius: '1.5em',
    marginBottom: '1em',
    color: '#ecf0f1',
    backgroundColor: '#e74c3c',
    '&:hover': {
      backgroundColor: '#c0392b',
    }, '&$disabled': {
      background: '#fff',
      color: 'red',
      boxShadow: 'none',
    }
  },
  disabled: {},
  display: {
    display: 'none'
  }
}));

const PoiButton = withStyles((theme, props) => ({
  root: {
    borderRadius: '50%',
    marginTop: '-50%',
    marginLeft: '-50%',
    padding: 5,
    border: '2px solid #FFF',
    color: '#FFF',
    backgroundColor: 'rgba(241, 25, 52, 0.8)',
    minWidth: 0,
    '&:hover': {
      backgroundColor: 'rgba(241, 25, 52, 1)',
    },
  },
  label: {
    padding: 0,
    '& svg': {
      width: '0.7em',
      height: '0.7em',
    },
  },
}))(Button);

const PoiCouponDetails = forwardRef((props, ref) => {
  const classes = useStyles();
  const Items = {
    image: '/images/shopping-img.jpg',
    name: 'Coupon Image',
  }
  const { couponId, handleCollectCoupon } = props;
  const [couponContext, setCouponContext] = useContext(CouponContext);
  const [collected, setCollected] = useState();
  const [coupon, setCoupon] = useState();

  useEffect(() => {
    const coupon = PoiService.getPoiCouponDetailsById(couponId)
    setCoupon(coupon)
    setCollected(coupon.collected)
  }, [])

  const handleClickCollect = () => {
    setCollected(true)
    const couponUpdated = { ...coupon, collected: true }
    PoiService.updateCoupon(couponUpdated)
    PoiService.addNewCollectedCouponNotification(couponUpdated.id)
    setCouponContext(new Date());
    handleCollectCoupon(couponUpdated.id)
  }
  return <>
    {coupon ? (<Card className={classes.card}>
      <CardMedia
        className={classes.cardMedia}
        image={Items.image}
        title={Items.name}
      />
      <CardHeader
        title="Coupon Details"
      />
      <CardContent>
        <Grid container className={classes.couponContainer}>
          <Grid item xs={12} className={classes.couponContentTop}>
            <Typography variant="body1" color="textPrimary" component="p" gutterBottom>
              <strong>{coupon.title}</strong>
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {coupon.description}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.couponDivider}></div>
          </Grid>

          <Grid item xs={12} className={classes.couponContentBottom}>
            <Button variant={collected ? "text" : "contained"} size="small" disabled={collected} onClick={handleClickCollect} classes={{ root: classes.collectBtn, disabled: classes.disabled }}>
              {collected ? "Collected" : "Collect"}
            </Button>
            <Typography variant="caption" color="textSecondary" component="p">
              Expired : {dateFormat(coupon.expiredDate, 'shortDate')}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>) : ('')}
  </>
})

const PoiCoupon = forwardRef((props, ref) => {
  const classes = useStyles();
  const { index, handleOpenModal, handleCollectCoupon, poi } = props;

  const body = <PoiCouponDetails couponId={poi.couponId} handleCollectCoupon={handleCollectCoupon} />;

  const handleOpen = () => {
    if (handleOpenModal) {
      handleOpenModal(body);
    }
  };

  return (
    <div ref={ref} className="poiPoint" data-poi-index={index} >
      <PoiButton color="primary" onClick={handleOpen} className={classes.pulsing} size="small">
        <CardGiftcardIcon />
      </PoiButton>
    </div>
  );
});

export default PoiCoupon;
