import { Box, Grid, makeStyles, Slide, Modal, Tooltip, TextField, Button, withStyles, Avatar, IconButton, Snackbar } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PublicIcon from '@material-ui/icons/Public';
import ShareIcon from '@material-ui/icons/Share';
import ShareIconButton from '../styled-components/ShareIconButton';
import LinkIcon from '@material-ui/icons/Link';
import clsx from 'clsx';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon } from 'react-share';
import CheckOutModal from './CheckOutModal';
import { useHistory, useLocation } from 'react-router-dom';
import { Emitter, EventName } from '../services/emitter';
import useOnClickOutside from '../hooks/useOnClickOutside'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const usePathname = () => {
  const location = useLocation();
  return location;
}

const PerksButton = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    padding: 0,
    border: 'none',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  startIcon: {
    margin: 0,
    '& .MuiAvatar-root': {
      borderRadius: 0,
      width: 64,
    },
    '& .MuiAvatar-img': {
      height: 'unset',
    }
  }
}))((props) => (
  <Button {...props} variant="contained" startIcon={<Avatar src="/images/perks_logo.png" />} disableElevation>
  </Button>
));

const CopyButton = withStyles((theme) => ({
  root: {
    width: 32,
    height: 32,
    background: '#212529',
    color: '#f8f9fa',
    '&:hover': {
      backgroundColor: '#212529'
    },
    '& svg': {
      width: '0.8em',
      height: '0.8em',
    }
  },
}))((props) => (
  <IconButton {...props} disableRipple>
    <LinkIcon />
  </IconButton>
));

const CopySnackbar = withStyles((theme) => ({
  root: {
    '& .MuiSnackbarContent-root': {
      minWidth: 'unset !important',
      backgroundColor: '#f8f9fa',
      color: '#212529',
      fontWeight: 600
    }
  }
}))(Snackbar)

const useStyles = makeStyles((theme) => ({
  // root: {
  //   top: '50%;',
  //   right: 1,
  //   zIndex: 100,
  //   position: 'absolute',
  //   transform: 'translate(0, -50%)',
  //   marginBottom: '-50%',
  // },
  root: {
    top: 75,
    right: 1,
    zIndex: 100,
    position: 'absolute',
  },
  menusContainer: {
    backgroundColor: '#fff',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
  },
  menuItem: {
    padding: theme.spacing(1),
    color: '#555',
    '& a': {
      color: '#555',
    },
    textAlign: 'center',
    position: 'relative',
    cursor: 'pointer',
  },
  shareButtonsContainer: {
    position: 'absolute',
    display: 'flex',
    right: 83,
    borderRadius: 8,
    top: 4,
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
    background: '#fff',
    '& button': {
      margin: 4,
      borderRadius: '50%',
      boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
    },
  },
  formEmailContainer: {
    position: 'absolute',
    display: 'flex',
    // alignItems: 'center',
    right: 83,
    borderRadius: 8,
    top: -16,
    padding: 8,
    width: 250,
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
    background: '#fff',
    '& button': {
      marginLeft: 8,
    },
  },
  submitBtn: {
    borderRadius: 0,
    background: '#212529',
    textTransform: 'unset',
    color: '#f8f9fa',
    '&:hover': {
      color: '#f8f9fa',
      background: '#212529bf',
    },
  }
}));

const NavigationMenu = (props) => {
  let query = useQuery();
  const classes = useStyles();
  const pathname = usePathname();
  const perksRef = useRef();
  const shareRef = useRef();
  const [showShareButtons, setShowShareButtons] = useState(false);
  const [open, setOpen] = useState(false)
  const [openFormEmail, setOpenFormEmail] = useState(false)
  const [panoView, setPanoView] = useState()
  const [shareUrl, setShareUrl] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const getCurrentUrl = () => {
    const panoId = query.get('id')
    const currentUrl = window.location.origin + window.location.pathname + `?id=${panoId}`;
    return currentUrl;
  };

  useOnClickOutside(perksRef, () => setOpenFormEmail(false));
  useOnClickOutside(shareRef, () => setShowShareButtons(false));

  const handleClickOpen = () => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  }

  const handleStreetViewOnPovChanged = (data) => {
    setPanoView(data.panoramaViewProps);
  };

  useEffect(() => {
    Emitter.on(EventName.StreetViewOnPovChanged, handleStreetViewOnPovChanged);
    return () => {
      Emitter.off(EventName.StreetViewOnPovChanged, handleStreetViewOnPovChanged);
    };
  }, []);

  useEffect(() => {
    if (showShareButtons) {
      const currentUrl = getCurrentUrl();
      const url = `${currentUrl}&zoom=${panoView.zoom}&heading=${panoView.heading}&pitch=${panoView.pitch}`
      console.log("New url", url);
      setShareUrl(url);
    }

  }, [showShareButtons])

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl);
    setOpenSnackbar(true);
  }

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  }
  // const body = <CheckOutModal handleCloseModal={handleClose} />;

  return (
    <Box className={clsx(classes.root, classes.menusContainer)}>
      <Grid container direction="column">
        <Grid item className={classes.menuItem}>
          <Tooltip title={`Sign In`} placement="left" arrow>
            <a href="https://cottonon.com/sign-in/" target="_blank">
              {/* <PersonIcon /> */}
              <svg class="my-account-logo" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                <g fill="none" fill-rule="evenodd">
                  <g>
                    <g>
                      <g class="myaccount-stroke" stroke="#555" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5">
                        <g>
                          <path
                            d="M20 20c0-4.079-2.445-7.581-5.947-9.137-1.087.943-2.502 1.518-4.053 1.518-1.552 0-2.966-.575-4.053-1.518C2.446 12.42 0 15.921 0 20"
                            transform="translate(-51 -458) translate(51 458) translate(2 2)"
                          ></path>
                          <path
                            d="M16.19 6.19c0 3.42-2.771 6.191-6.19 6.191-3.42 0-6.19-2.771-6.19-6.19C3.81 2.77 6.58 0 10 0c3.419 0 6.19 2.772 6.19 6.19z"
                            transform="translate(-51 -458) translate(51 458) translate(2 2)"
                          ></path>
                        </g>
                      </g>
                      <path d="M0 0H24V24H0z" transform="translate(-51 -458) translate(51 458)"></path>
                    </g>
                  </g>
                </g>
              </svg>
            </a>
          </Tooltip>
        </Grid>
        <Grid item className={classes.menuItem}>
          {/* <a href="https://cottonon.com/bag/" target="_blank">
            <ShoppingCartIcon />
          </a> */}
          <Tooltip title={`Shopping Cart`} placement="left" arrow>
            <ShoppingCartIcon onClick={handleClickOpen} />
          </Tooltip>
        </Grid>
        <Grid item className={classes.menuItem}>
          <Tooltip title={`cottonon.com`} placement="left" arrow>
            <a href="https://cottonon.com/" target="_blank">
              <PublicIcon />
            </a>
          </Tooltip>
        </Grid>
        <Grid item className={classes.menuItem} ref={perksRef}>
          <Tooltip title={`Subscribe`} placement="left" arrow>
            <PerksButton onClick={() => {
              setOpenFormEmail(!openFormEmail);
            }} />
          </Tooltip>
          <Slide direction="left" in={openFormEmail} mountOnEnter unmountOnExit>
            <div className={classes.formEmailContainer}>
              <TextField label="Enter your email" variant="outlined" />
              <Button variant="contained" classes={{ root: classes.submitBtn }}>
                Join
              </Button>
            </div>
          </Slide>
        </Grid>
        <Grid item className={classes.menuItem} ref={shareRef}>
          <Tooltip title={`Share`} placement="left" arrow>
            {/* <ShareIcon onClick={() => {
              setShowShareButtons(!showShareButtons);
            }}
            /> */}
            <ShareIconButton onClick={() => {
              setShowShareButtons(!showShareButtons);
            }} />
          </Tooltip>
          <Slide direction="left" in={showShareButtons} mountOnEnter unmountOnExit>
            <div className={classes.shareButtonsContainer}>
              <FacebookShareButton url={shareUrl} quote="Test share via facebook">
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl} title="Test share via twitter">
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <EmailShareButton url={shareUrl} subject="Test share via email" body="This is a test share, ">
                <EmailIcon size={32} round />
              </EmailShareButton>
              <CopyButton onClick={copyToClipboard} />
            </div>
          </Slide>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <CheckOutModal handleCloseModal={handleClose} />
      </Modal>
      <CopySnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={2500}
        open={openSnackbar}
        onClose={handleSnackbarClose}
        message="Copied to clipboard !!" />
    </Box>
  );
};

export default NavigationMenu;
