const product1 = {
  id: 1,
  items: [
    { productSku: '11xs', color: 1, size: 'XS' },
    { productSku: '11s', color: 1, size: 'S' },
    { productSku: '11m', color: 1, size: 'M' },
    { productSku: '11l', color: 1, size: 'L' },
    { productSku: '11xl', color: 1, size: 'XL' },
    { productSku: '12xs', color: 2, size: 'XS' },
    { productSku: '12s', color: 2, size: 'S' },
    { productSku: '12m', color: 2, size: 'M' },
    { productSku: '12l', color: 2, size: 'L' },
    { productSku: '12xl', color: 2, size: 'XL' },
    { productSku: '132xs', color: 3, size: '2XS' },
    { productSku: '13xs', color: 3, size: 'XS' },
    { productSku: '13s', color: 3, size: 'S' },
    { productSku: '13m', color: 3, size: 'M' },
    { productSku: '13l', color: 3, size: 'L' },
    { productSku: '14xs', color: 4, size: 'XS' },
    { productSku: '14s', color: 4, size: 'S' },
    { productSku: '14m', color: 4, size: 'M' },
    { productSku: '14l', color: 4, size: 'L' },
    { productSku: '14xl', color: 4, size: 'XL' },
    { productSku: '15xs', color: 5, size: 'XS' },
    { productSku: '15s', color: 5, size: 'S' },
    { productSku: '15m', color: 5, size: 'M' },
    { productSku: '15l', color: 5, size: 'L' },
    { productSku: '15xl', color: 5, size: 'XL' },
    { productSku: '16xs', color: 6, size: 'XS' },
    { productSku: '16s', color: 6, size: 'S' },
    { productSku: '16m', color: 6, size: 'M' },
    { productSku: '16l', color: 6, size: 'L' },
    { productSku: '16xl', color: 6, size: 'XL' },
    { productSku: '17xs', color: 7, size: 'XS' },
    { productSku: '17s', color: 7, size: 'S' },
    { productSku: '17m', color: 7, size: 'M' },
    { productSku: '17l', color: 7, size: 'L' },
    { productSku: '17xl', color: 7, size: 'XL' },
  ],
  name: 'The One Organic Rib Scoop Tank',
  price: 39.99,
  salePrice: 21.49,
  salePercent: '30%',
  weeklyInstallment: 1.4,
  star: 5,
  starTotal: 2,
  fitRate: 3,
  sizeGuide: [
    { size: 'XS', chest: 33.6, waist: 25.6, hip: 35.6 },
    { size: 'S', chest: 35.6, waist: 27.6, hip: 37.6 },
    { size: 'M', chest: 37.6, waist: 29.6, hip: 39.6 },
    { size: 'L', chest: 39.6, waist: 31.6, hip: 41.6 },
    { size: 'XL', chest: 41.6, waist: 33.6, hip: 43.6 },
  ],
  colors: [
    {
      id: 1,
      name: 'sage',
      optionImage: '/images/sample-product/sage-1.jpg',
      sizes: [
        { name: 'XS', available: true },
        { name: 'S', available: true },
        { name: 'M', available: true },
        { name: 'L', available: true },
        { name: 'XL', available: true },
      ],
      images: [
        '/images/sample-product/sage-1.jpg',
        '/images/sample-product/sage-2.jpg',
        '/images/sample-product/sage-3.jpg',
        '/images/sample-product/sage-4.jpg',
      ],
    },
    {
      id: 2,
      name: 'dusty pink',
      optionImage: '/images/sample-product/dusty-pink-1.jpg',
      sizes: [
        { name: 'XS', available: true },
        { name: 'S', available: true },
        { name: 'M', available: true },
        { name: 'L', available: true },
        { name: 'XL', available: true },
      ],
      images: [
        '/images/sample-product/dusty-pink-1.jpg',
        '/images/sample-product/dusty-pink-2.jpg',
        '/images/sample-product/dusty-pink-3.jpg',
        '/images/sample-product/dusty-pink-4.jpg',
      ],
    },
    {
      id: 3,
      name: 'linen taupe',
      optionImage: '/images/sample-product/linen-taupe-1.jpg',
      sizes: [
        { name: '2XS', available: false },
        { name: 'XS', available: true },
        { name: 'S', available: true },
        { name: 'M', available: true },
        { name: 'L', available: true },
      ],
      images: [
        '/images/sample-product/linen-taupe-1.jpg',
        '/images/sample-product/linen-taupe-2.jpg',
        '/images/sample-product/linen-taupe-3.jpg',
        '/images/sample-product/linen-taupe-4.jpg',
      ],
    },
    {
      id: 4,
      name: 'cocoa bean',
      optionImage: '/images/sample-product/cocoa-bean-1.jpg',
      sizes: [
        { name: 'XS', available: true },
        { name: 'S', available: true },
        { name: 'M', available: true },
        { name: 'L', available: true },
        { name: 'XL', available: true },
      ],
      images: [
        '/images/sample-product/cocoa-bean-1.jpg',
        '/images/sample-product/cocoa-bean-2.jpg',
        '/images/sample-product/cocoa-bean-3.jpg',
        '/images/sample-product/cocoa-bean-4.jpg',
      ],
    },
    {
      id: 5,
      name: 'black',
      optionImage: '/images/sample-product/black-1.jpg',
      sizes: [
        { name: 'XS', available: true },
        { name: 'S', available: true },
        { name: 'M', available: true },
        { name: 'L', available: true },
        { name: 'XL', available: true },
      ],
      images: [
        '/images/sample-product/black-1.jpg',
        '/images/sample-product/black-2.jpg',
        '/images/sample-product/black-3.jpg',
        '/images/sample-product/black-4.jpg',
      ],
    },
    {
      id: 6,
      name: 'light grey marle',
      optionImage: '/images/sample-product/light-grey-marle-1.jpg',
      sizes: [
        { name: 'XS', available: true },
        { name: 'S', available: true },
        { name: 'M', available: true },
        { name: 'L', available: true },
        { name: 'XL', available: true },
      ],
      images: [
        '/images/sample-product/light-grey-marle-1.jpg',
        '/images/sample-product/light-grey-marle-2.jpg',
        '/images/sample-product/light-grey-marle-3.jpg',
        '/images/sample-product/light-grey-marle-4.jpg',
      ],
    },
    {
      id: 7,
      name: 'white',
      optionImage: '/images/sample-product/white-1.jpg',
      sizes: [
        { name: 'XS', available: true },
        { name: 'S', available: true },
        { name: 'M', available: true },
        { name: 'L', available: true },
        { name: 'XL', available: true },
      ],
      images: [
        '/images/sample-product/white-1.jpg',
        '/images/sample-product/white-2.jpg',
        '/images/sample-product/white-3.jpg',
        '/images/sample-product/white-4.jpg',
      ],
    },
  ],
};

const product2 = {
  id: 2,
  items: [
    { productSku: '21s', color: 1, size: 'S' },
    { productSku: '21m', color: 1, size: 'M' },
    { productSku: '21l', color: 1, size: 'L' },
    { productSku: '21xl', color: 1, size: 'XL' },
    { productSku: '212xl', color: 1, size: '2XL' },
    { productSku: '22s', color: 2, size: 'S' },
    { productSku: '22m', color: 2, size: 'M' },
    { productSku: '22l', color: 2, size: 'L' },
    { productSku: '22xl', color: 2, size: 'XL' },
    { productSku: '222xl', color: 2, size: '2XL' },
    { productSku: '23xs', color: 3, size: 'XS' },
    { productSku: '23s', color: 3, size: 'S' },
    { productSku: '23m', color: 3, size: 'M' },
    { productSku: '23l', color: 3, size: 'L' },
    { productSku: '23xl', color: 3, size: 'XL' },
  ],
  name: 'Oversized Graphic Hoodie',
  price: 54.95,
  salePrice: 38.46,
  salePercent: '30%',
  weeklyInstallment: 3.85,
  star: 4,
  starTotal: 1,
  fitRate: 3,
  sizeGuide: [
    { size: 'XS', chest: 35.5, waist: 28, hip: 35 },
    { size: 'S', chest: 38, waist: 30, hip: 37 },
    { size: 'M', chest: 40, waist: 32, hip: 39 },
    { size: 'L', chest: 42, waist: 34, hip: 41 },
    { size: 'XL', chest: 44.5, waist: 36, hip: 43 },
  ],
  colors: [
    {
      id: 1,
      name: 'silver marle/west coast',
      optionImage: '/images/sample-product2/silver-marle-west-coast-1.jpg',
      sizes: [
        { name: 'S', available: false },
        { name: 'M', available: false },
        { name: 'L', available: false },
        { name: 'XL', available: true },
        { name: '2XL', available: false },
      ],
      images: [
        '/images/sample-product2/silver-marle-west-coast-1.jpg',
        '/images/sample-product2/silver-marle-west-coast-2.jpg',
        '/images/sample-product2/silver-marle-west-coast-3.jpg',
        '/images/sample-product2/silver-marle-west-coast-4.jpg',
      ],
    },
    {
      id: 2,
      name: 'black/ohio',
      optionImage: '/images/sample-product2/black-ohio-1.jpg',
      sizes: [
        { name: 'S', available: true },
        { name: 'M', available: true },
        { name: 'L', available: true },
        { name: 'XL', available: true },
        { name: '2XL', available: true },
      ],
      images: [
        '/images/sample-product2/black-ohio-1.jpg',
        '/images/sample-product2/black-ohio-2.jpg',
        '/images/sample-product2/black-ohio-3.jpg',
        '/images/sample-product2/black-ohio-4.jpg',
      ],
    },
    {
      id: 3,
      name: 'silver marle/san francisco',
      optionImage: '/images/sample-product2/silver-marle-san-francisco-1.jpg',
      sizes: [
        { name: 'XS', available: true },
        { name: 'S', available: true },
        { name: 'M', available: true },
        { name: 'L', available: true },
        { name: 'XL', available: true },
      ],
      images: [
        '/images/sample-product2/silver-marle-san-francisco-1.jpg',
        '/images/sample-product2/silver-marle-san-francisco-2.jpg',
        '/images/sample-product2/silver-marle-san-francisco-3.jpg',
        '/images/sample-product2/silver-marle-san-francisco-4.jpg',
      ],
    },
  ],
};

const product3 = {
  id: 3,
  items: [
    { productSku: '31xs', color: 1, size: 'XS' },
    { productSku: '31s', color: 1, size: 'S' },
    { productSku: '31m', color: 1, size: 'M' },
    { productSku: '31l', color: 1, size: 'L' },
    { productSku: '31xl', color: 1, size: 'XL' },
    { productSku: '32xs', color: 2, size: 'XS' },
    { productSku: '32s', color: 2, size: 'S' },
    { productSku: '32m', color: 2, size: 'M' },
    { productSku: '32l', color: 2, size: 'L' },
    { productSku: '32xl', color: 2, size: 'XL' },
    { productSku: '33xs', color: 3, size: 'XS' },
    { productSku: '33s', color: 3, size: 'S' },
    { productSku: '33m', color: 3, size: 'M' },
    { productSku: '33l', color: 3, size: 'L' },
    { productSku: '33xl', color: 3, size: 'XL' },
    { productSku: '34xs', color: 4, size: 'XS' },
    { productSku: '34s', color: 4, size: 'S' },
    { productSku: '34m', color: 4, size: 'M' },
    { productSku: '34l', color: 4, size: 'L' },
    { productSku: '34xl', color: 4, size: 'XL' },
    { productSku: '35xs', color: 5, size: 'XS' },
    { productSku: '35s', color: 5, size: 'S' },
    { productSku: '35m', color: 5, size: 'M' },
    { productSku: '35l', color: 5, size: 'L' },
    { productSku: '35xl', color: 5, size: 'XL' },
    { productSku: '36xs', color: 6, size: 'XS' },
    { productSku: '36s', color: 6, size: 'S' },
    { productSku: '36m', color: 6, size: 'M' },
    { productSku: '36l', color: 6, size: 'L' },
    { productSku: '36xl', color: 6, size: 'XL' },
    { productSku: '37xs', color: 7, size: 'XS' },
    { productSku: '37s', color: 7, size: 'S' },
    { productSku: '37m', color: 7, size: 'M' },
    { productSku: '37l', color: 7, size: 'L' },
    { productSku: '37xl', color: 7, size: 'XL' },
    { productSku: '38xs', color: 8, size: 'XS' },
    { productSku: '38s', color: 8, size: 'S' },
    { productSku: '38m', color: 8, size: 'M' },
    { productSku: '38l', color: 8, size: 'L' },
    { productSku: '38xl', color: 8, size: 'XL' },
    { productSku: '39xs', color: 9, size: 'XS' },
    { productSku: '39s', color: 9, size: 'S' },
    { productSku: '39m', color: 9, size: 'M' },
    { productSku: '39l', color: 9, size: 'L' },
    { productSku: '310xl', color: 9, size: 'XL' },
    { productSku: '310xs', color: 10, size: 'XS' },
    { productSku: '310s', color: 10, size: 'S' },
    { productSku: '310m', color: 10, size: 'M' },
    { productSku: '310l', color: 10, size: 'L' },
    { productSku: '310xl', color: 10, size: 'XL' },
  ],
  name: 'Regular Graphic T Shirt',
  price: 39.99,
  salePrice: 21.49,
  salePercent: '30%',
  weeklyInstallment: 1.4,
  star: 4.3,
  starTotal: 31,
  fitRate: 3.2,
  sizeGuide: [
    { size: 'XS', chest: 35.5, waist: 28, hip: 35 },
    { size: 'S', chest: 38, waist: 30, hip: 37 },
    { size: 'M', chest: 40, waist: 32, hip: 39 },
    { size: 'L', chest: 42, waist: 34, hip: 41 },
    { size: 'XL', chest: 44.5, waist: 36, hip: 43 },
  ],
  colors: [
    {
      id: 1,
      name: 'black/milwaukee state',
      optionImage: '/images/sample-product3/black-milwaukee-state-1.jpg',
      sizes: [
        { name: 'XS', available: true },
        { name: 'S', available: false },
        { name: 'M', available: false },
        { name: 'L', available: false },
        { name: 'XL', available: false },
      ],
      images: [
        '/images/sample-product3/black-milwaukee-state-1.jpg',
        '/images/sample-product3/black-milwaukee-state-2.jpg',
        '/images/sample-product3/black-milwaukee-state-3.jpg',
        '/images/sample-product3/black-milwaukee-state-4.jpg',
      ],
    },
    {
      id: 2,
      name: 'black/you re welcome',
      optionImage: '/images/sample-product3/black-you-re-welcome-1.jpg',
      sizes: [
        { name: 'XS', available: true },
        { name: 'S', available: true },
        { name: 'M', available: true },
        { name: 'L', available: true },
        { name: 'XL', available: true },
      ],
      images: [
        '/images/sample-product3/black-you-re-welcome-1.jpg',
        '/images/sample-product3/black-you-re-welcome-2.jpg',
        '/images/sample-product3/black-you-re-welcome-3.jpg',
        '/images/sample-product3/black-you-re-welcome-4.jpg',
      ],
    },
    {
      id: 3,
      name: 'silver marle/ohio',
      optionImage: '/images/sample-product3/silver-marle-ohio-1.jpg',
      sizes: [
        { name: 'XS', available: true },
        { name: 'S', available: false },
        { name: 'M', available: false },
        { name: 'L', available: false },
        { name: 'XL', available: true },
      ],
      images: [
        '/images/sample-product3/silver-marle-ohio-1.jpg',
        '/images/sample-product3/silver-marle-ohio-2.jpg',
        '/images/sample-product3/silver-marle-ohio-3.jpg',
        '/images/sample-product3/silver-marle-ohio-4.jpg',
      ],
    },
    {
      id: 4,
      name: 'white/cincinnati',
      optionImage: '/images/sample-product3/white-cincinnati-1.jpg',
      sizes: [
        { name: 'XS', available: true },
        { name: 'S', available: true },
        { name: 'M', available: true },
        { name: 'L', available: true },
        { name: 'XL', available: true },
      ],
      images: [
        '/images/sample-product3/white-cincinnati-1.jpg',
        '/images/sample-product3/white-cincinnati-2.jpg',
        '/images/sample-product3/white-cincinnati-3.jpg',
        '/images/sample-product3/white-cincinnati-4.jpg',
      ],
    },
    {
      id: 5,
      name: 'washed pine teal/boston state',
      optionImage: '/images/sample-product3/washed-pine-teal-boston-state-1.jpg',
      sizes: [
        { name: 'XS', available: true },
        { name: 'S', available: true },
        { name: 'M', available: true },
        { name: 'L', available: true },
        { name: 'XL', available: true },
      ],
      images: [
        '/images/sample-product3/washed-pine-teal-boston-state-1.jpg',
        '/images/sample-product3/washed-pine-teal-boston-state-2.jpg',
        '/images/sample-product3/washed-pine-teal-boston-state-3.jpg',
        '/images/sample-product3/washed-pine-teal-boston-state-4.jpg',
      ],
    },
    {
      id: 6,
      name: 'white/aspen ski club',
      optionImage: '/images/sample-product3/white-aspen-ski-club-1.jpg',
      sizes: [
        { name: 'XS', available: false },
        { name: 'S', available: false },
        { name: 'M', available: false },
        { name: 'L', available: true },
        { name: 'XL', available: true },
      ],
      images: [
        '/images/sample-product3/white-aspen-ski-club-1.jpg',
        '/images/sample-product3/white-aspen-ski-club-2.jpg',
        '/images/sample-product3/white-aspen-ski-club-3.jpg',
        '/images/sample-product3/white-aspen-ski-club-4.jpg',
      ],
    },
    {
      id: 7,
      name: 'white/nyyc',
      optionImage: '/images/sample-product3/white-nyyc-1.jpg',
      sizes: [
        { name: 'XS', available: false },
        { name: 'S', available: false },
        { name: 'M', available: false },
        { name: 'L', available: true },
        { name: 'XL', available: true },
      ],
      images: [
        '/images/sample-product3/white-nyyc-1.jpg',
        '/images/sample-product3/white-nyyc-2.jpg',
        '/images/sample-product3/white-nyyc-3.jpg',
        '/images/sample-product3/white-nyyc-4.jpg',
      ],
    },
    {
      id: 8,
      name: 'black/st moritz',
      optionImage: '/images/sample-product3/black-st-moritz-1.jpg',
      sizes: [
        { name: 'XS', available: false },
        { name: 'S', available: true },
        { name: 'M', available: true },
        { name: 'L', available: true },
        { name: 'XL', available: true },
      ],
      images: [
        '/images/sample-product3/black-st-moritz-1.jpg',
        '/images/sample-product3/black-st-moritz-2.jpg',
        '/images/sample-product3/black-st-moritz-3.jpg',
        '/images/sample-product3/black-st-moritz-4.jpg',
      ],
    },
    {
      id: 9,
      name: 'washed black/greenwich',
      optionImage: '/images/sample-product3/washed-black-greenwich-1.jpg',
      sizes: [
        { name: 'XS', available: false },
        { name: 'S', available: false },
        { name: 'M', available: false },
        { name: 'L', available: true },
        { name: 'XL', available: true },
      ],
      images: [
        '/images/sample-product3/washed-black-greenwich-1.jpg',
        '/images/sample-product3/washed-black-greenwich-2.jpg',
        '/images/sample-product3/washed-black-greenwich-3.jpg',
        '/images/sample-product3/washed-black-greenwich-4.jpg',
      ],
    },
    {
      id: 10,
      name: 'silver marle/michigan',
      optionImage: '/images/sample-product3/silver-marle-michigan-1.jpg',
      sizes: [
        { name: 'XS', available: true },
        { name: 'S', available: true },
        { name: 'M', available: true },
        { name: 'L', available: true },
        { name: 'XL', available: true },
      ],
      images: [
        '/images/sample-product3/silver-marle-michigan-1.jpg',
        '/images/sample-product3/silver-marle-michigan-2.jpg',
        '/images/sample-product3/silver-marle-michigan-3.jpg',
        '/images/sample-product3/silver-marle-michigan-4.jpg',
      ],
    },
  ],
};

const ProductsMockData = [product1, product2, product3];
export default ProductsMockData;
