import Perspective from 'perspectivejs';
export class MP4Poster {
  constructor(mediaData) {
    this.animateTimer = null;
    this.requestAnimateId = null;

    this.posterCanvasCtx = null;
    this.drawArea = null;
    this.perspective = null;

    this.mediaData = mediaData;
    this.videoCanvas = document.createElement('canvas');
    this.videoCanvas.width = mediaData.videoWidth;
    this.videoCanvas.height = mediaData.videoHeight;
    this.videoCtx = this.videoCanvas.getContext('2d');
  }

  _manipulate() {
    if (!this.perspective) {
      this.perspective = new Perspective(this.posterCanvasCtx, this.videoCanvas);
    }
    this.perspective.p.cvso = this.videoCanvas;
    this.perspective.p.ctxo = this.videoCtx;
    this.perspective.draw(this.drawArea);
  }

  _startAnimation() {
    this.videoCtx.drawImage(this.mediaData, 0, 0);

    // perform manipulation
    this._manipulate();

    // delay the next video frame
    var self = this;
    this.animateTimer = setTimeout(function () {
      self.requestAnimateId = requestAnimationFrame(() => {
        self._startAnimation();
      });
    }, 40);
  }

  stopAnimation() {
    if (this.animateTimer) {
      clearTimeout(this.animateTimer);
      this.animateTimer = 0;
    }

    if (this.requestAnimateId) {
      cancelAnimationFrame(this.requestAnimateId);
    }
  }

  startAnimation(posterCanvasCtx, drawArea) {
    this.stopAnimation();
    this.posterCanvasCtx = posterCanvasCtx;
    this.drawArea = drawArea;
    this._startAnimation();
  }
}
