import React, { useEffect, useRef } from 'react';
import Hotkeys from 'react-hot-keys';
import { useHistory } from 'react-router';
import { Emitter, EventName } from '../services/emitter';

const KeyBoardNavigationControl = (props) => {
  const navigateAngleDegree = 60;
  const { children, panoramaLinks } = props;
  const currentPanoramaViewProps = useRef({});
  const panoramaLinksRef = useRef([]);
  const history = useHistory();

  useEffect(() => {
    Emitter.on(EventName.StreetViewOnPovChanged, handleStreetViewOnPovChanged);
    return () => {
      Emitter.off(EventName.StreetViewOnPovChanged, handleStreetViewOnPovChanged);
    };
  }, []);

  useEffect(() => {
    panoramaLinksRef.current = panoramaLinks;
  }, [panoramaLinks]);

  const handleStreetViewOnPovChanged = (data) => {
    currentPanoramaViewProps.current = data.panoramaViewProps;
  };

  const onKeyDown = (keyName, e, handle) => {
    if (keyName === 'up') {
      moveForward();
    } else if (keyName === 'down') {
      moveBackward();
    }
  };

  const moveForward = () => {
    if (currentPanoramaViewProps.current && panoramaLinksRef.current) {
      let currentHeading = currentPanoramaViewProps.current.heading;
      let forwardLink = findNearestLinkByHeading(currentHeading);

      if (forwardLink) {
        history.push({
          pathname: '/',
          search: `?id=${forwardLink.pano}`,
        });
      }
    }
  };

  const moveBackward = () => {
    if (currentPanoramaViewProps.current && panoramaLinksRef.current) {
      let currentBackwardHeading = to360degree(currentPanoramaViewProps.current.heading + 180);
      let backwardLink = findNearestLinkByHeading(currentBackwardHeading);

      if (backwardLink) {
        history.push({
          pathname: '/',
          search: `?id=${backwardLink.pano}`,
        });
      }
    }
  };

  const findNearestLinkByHeading = (heading) => {
    let minNavigateAngle = to360degree(0 - navigateAngleDegree / 2);
    let maxNavigateAngle = to360degree(navigateAngleDegree / 2);

    let offsetLinks = [];
    panoramaLinks.forEach((panoramaLink) => {
      let linkHeading = to360degree(to360degree(panoramaLink.heading) - heading);
      if (linkHeading >= minNavigateAngle) {
        offsetLinks.push({ pano: panoramaLink.pano, offset: 360 - linkHeading });
      } else if (linkHeading <= maxNavigateAngle) {
        offsetLinks.push({ pano: panoramaLink.pano, offset: linkHeading });
      }
    });

    if (offsetLinks.length > 0) {
      let link = offsetLinks.sort((a, b) => a.offset - b.offset)[0];
      return link;
    } else {
      return null;
    }
  };

  const to360degree = (value) => {
    if (value < 0) {
      return value + 360;
    } else if (value > 360) {
      return value - 360;
    }
    return value;
  };

  return (
    <Hotkeys keyName="up,down" onKeyDown={onKeyDown}>
      {children}
    </Hotkeys>
  );
};

export default KeyBoardNavigationControl;
