import MockCoupons from '../mockdatas/coupons';

const KEY_COUPON = 'cp';
const KEY_COUPON_NOTIFICATION = 'cp-noti';

const CouponService = {
  updateCoupon: function (coupon) {
    updateCoupon(coupon);
  },
  getCoupons: function () {
    return getCoupons();
  },
  getCountLocationTravellingCoupons: function () {
    let coupons = getCoupons();
    let locationTravellingCoupons = [];
    coupons.forEach((coupon) => {
      if (coupon.requirement.type === 'CountLocations' && !coupon.collected) {
        locationTravellingCoupons.push(coupon);
      }
    });
    return locationTravellingCoupons;
  },
  getSpecificLocationTravellingCoupons: function () {
    let coupons = getCoupons();
    let specificLocationsCoupon = [];
    coupons.forEach((coupon) => {
      if (coupon.requirement.type === 'SpecificLocations' && !coupon.collected) {
        specificLocationsCoupon.push(coupon);
      }
    });
    return specificLocationsCoupon;
  },
  getCouponsListItems: function () {
    return getCouponsListItems();
  },
  getCouponDetailsById: function (couponId) {
    return getCouponDetailsById(couponId);
  },
  getCouponNotifications: function () {
    return getCouponNotifications();
  },
  addNewCollectedCouponNotification: function (couponId) {
    addNewCollectedCouponNotification(couponId);
  },
  clearCouponNotificationByCouponId: function (couponId) {
    clearCouponNotificationByCouponId(couponId);
  },
  resetCoupons: function () {
    resetCoupons();
  }
};

function getCouponDetailsById(couponId) {
  let coupons = getCoupons();
  let coupon = coupons.find((x) => x.id === couponId);
  return coupon;
}

function getCouponNotifications() {
  let dataJsonString = localStorage.getItem(KEY_COUPON_NOTIFICATION);

  if (dataJsonString) {
    return JSON.parse(dataJsonString);
  } else {
    return [];
  }
}

function clearCouponNotificationByCouponId(couponId) {
  let couponNotifications = getCouponNotifications();
  couponNotifications = couponNotifications.filter((x) => x.couponId !== couponId);
  localStorage.setItem(KEY_COUPON_NOTIFICATION, JSON.stringify(couponNotifications));
}

function addNewCollectedCouponNotification(couponId) {
  let couponNotifications = getCouponNotifications();
  couponNotifications.push({ couponId: couponId, type: 'collected' });
  localStorage.setItem(KEY_COUPON_NOTIFICATION, JSON.stringify(couponNotifications));
}

function getCoupons() {
  let dataJsonString = localStorage.getItem(KEY_COUPON);
  if (dataJsonString) {
    return JSON.parse(dataJsonString);
  } else {
    localStorage.setItem(KEY_COUPON, JSON.stringify(MockCoupons));
    return MockCoupons;
  }
}
function resetCoupons() {
  localStorage.setItem(KEY_COUPON, JSON.stringify(MockCoupons));
  localStorage.setItem(KEY_COUPON_NOTIFICATION, JSON.stringify([]));
}

function getCouponsListItems() {
  let coupons = getCoupons();
  let inprogressCoupons = coupons.filter((x) => !x.collected);
  let collectedCoupons = coupons.filter((x) => x.collected);
  let couponListItems = [];

  inprogressCoupons.forEach((coupon) => {
    let infoText = '';
    if (coupon.requirement.type === 'CountLocations') {
      infoText = `${coupon.requirement.metLocationIds.length}/${coupon.requirement.totalLocations}`;
    } else if (coupon.requirement.type === 'SpecificLocations') {
      infoText = `${coupon.requirement.metLocationIds.length}/${coupon.requirement.locations.length}`;
    } else if (coupon.requirement.type === 'POICoupon') {
      infoText = '0/1'
    }

    couponListItems.push({
      id: coupon.id,
      title: coupon.title,
      infoText: infoText,
      expiredDate: coupon.expiredDate,
    });
  });

  collectedCoupons.forEach((coupon) => {
    couponListItems.push({
      id: coupon.id,
      title: coupon.title,
      infoText: 'Collected',
      expiredDate: coupon.expiredDate,
    });
  });

  return couponListItems;
}

function updateCoupon(coupon) {
  let coupons = getCoupons();

  coupons = coupons.filter((x) => x.id !== coupon.id);
  coupons.push(coupon);

  localStorage.setItem(KEY_COUPON, JSON.stringify(coupons));
}

function insertOrUpdateShoppingCartItem(productSku, totalItem) {
  let shoppingCart = getCoupons();
  let shoppingCartItem;

  let item = shoppingCart.find((x) => x.productSku == productSku);
  if (item) {
    shoppingCartItem = item;
    shoppingCartItem.totalItem += totalItem;
  }

  if (!shoppingCartItem) {
    shoppingCartItem = {
      productSku: productSku,
      totalItem: totalItem,
    };
    shoppingCart.push(shoppingCartItem);
  }

  localStorage.setItem(KEY_COUPON, JSON.stringify(shoppingCart));
}

export default CouponService;
