import { Button, withStyles } from '@material-ui/core';
import React from 'react';

const AddToBagButton = withStyles((theme) => ({
  root: {
    textTransform: 'initial',
    fontSize: 18,
    borderRadius: 2,
  },
  containedPrimary: {
    backgroundColor: '#26a65b',
    '&:hover': {
      backgroundColor: '#1b7a42',
    },
  },
}))((props) => (
  <Button variant="contained" color="primary" {...props} disableElevation>
    Add To Bag
  </Button>
));

export default AddToBagButton;