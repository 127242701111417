import { Box, Breadcrumbs, Divider, Grid, IconButton, Link, List, ListItem, ListItemText, makeStyles, Paper } from '@material-ui/core';
import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStreetView } from '@fortawesome/free-solid-svg-icons/faStreetView';
import { getParsedCommandLineOfConfigFile } from 'typescript';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: 40,
    left: 0,
    zIndex: 100,
    position: 'absolute',
    backgroundColor: '#CACACA',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    color: '#EE1F3B',
    fontWeight: 'bold',
    fontSize: '1.85rem',
    marginBottom: 0,
    marginTop: theme.spacing(0.5),
    textAlign: 'center',
  },
  subHeader: {
    color: '#333',
    fontWeight: 'bold',
    fontSize: '1rem',
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    textAlign: 'center',
  },
  annouceBox: {
    padding: theme.spacing(1),
    maxHeight: 500,
    scrollbarWidth: 'none',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    color: '#707070',
  },
}));

const AnnouceMenuContent = (props, ref) => {
  const { onCloseButtonClick } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleCloseButtonClick = () => {
    if (onCloseButtonClick) {
      onCloseButtonClick();
    }
  };

  return (
    <Paper className={classes.root} ref={ref}>
      <p className={classes.header}>Sale</p>
      <p className={classes.subHeader}>up to 60%</p>
      <Box className={classes.annouceBox}>
        <img src="/images/annouce.png" width="100" />
      </Box>

      <IconButton color="secondary" className={classes.iconButton} onClick={handleCloseButtonClick}>
        <CloseIcon className={classes.icon} />
      </IconButton>
    </Paper>
  );
};

export default forwardRef(AnnouceMenuContent);
