import { Divider, FormControl, List, ListItem, ListItemText, makeStyles, MenuItem, Paper, Select } from '@material-ui/core';
import React, { forwardRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import RoomIcon from '@material-ui/icons/Room';
import LoginMenu from './LogInMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: 50,
    right: 0,
    zIndex: 100,
    position: 'absolute',
    padding: theme.spacing(1),
  },
  menusContainer: {
    alignItems: 'flex-end',
  },
  breadcrumbs: {
    fontSize: '0.75rem',
    backgroundColor: '#fff',
    borderRadius: 20,
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
    padding: '5px 10px',
    marginRight: 10,
  },
  streetViewIcon: {
    color: '#fff',
    fontSize: '2.5em',
  },
  header: {
    paddingLeft: theme.spacing(2),
    color: '#EE1F3B',
    fontWeight: 'bold',
    fontSize: '1rem',
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    textTransform: 'uppercase',
  },
  divider: {
    backgroundColor: '#707070',
    height: 2,
    marginLeft: theme.spacing(1),
  },
  list: {
    maxHeight: 350,
    minWidth: 220,
    overflowY: 'auto',
  },
  categoryTitle: {
    // marginTop: theme.spacing(1),
    '& span': {
      fontWeight: 'bold',
      fontSize: '1rem',
    },
  },
  menuItem: {
    cursor: 'pointer',
    paddingTop: 0,
    paddingBottom: 0,
    '& span': {
      //   fontWeight: 'bold',
    },
    '&.nolink': {
      cursor: 'default',
    },
  },
  formControl: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    width: '100%',
  },
  currentPlace: {
    marginBottom: 0,
    paddingLeft: theme.spacing(1),
    fontSize: '0.85rem',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center'
  },
  currentPlaceTitle: {
    fontWeight: 'normal',
    color: '#ee1f3b'
  },
}));

const CategoriesMenuContent = (props, ref) => {
  const { categories } = props;
  const classes = useStyles();
  const history = useHistory();
  const places = [
    { name: 'Brisbane', url: '?id=kid' },
    { name: 'Johannesburg', url: '?id=women' },
    { name: 'Hong Kong', url: '?id=women-bag' },
    { name: 'Los Angeles', url: '?id=men-shirt' },
    { name: 'Singapore', url: '?id=men-t-shirts' },
    { name: 'Kuala Lumpur', url: '?id=kid-dresses' },
    { name: 'London', url: '?id=women-bag' },
  ];

  const [placeName, setPlaceName] = useState('');

  const handleChange = (event) => {
    setPlaceName(event.target.value);
  };

  useEffect(() => {
    if (placeName) {
      let place = places.find((x) => x.name === placeName);
      if (place) {
        history.push({
          pathname: '/',
          search: place.url,
        });
      }
    }
  }, [placeName]);

  const handleBreadcumbClick = () => { };

  return (
    <Paper className={classes.root} ref={ref}>
      <p className={classes.header}>
        The Surfers
        <br /> Paradise Experience
      </p>
      <Divider className={classes.divider} />
      <FormControl className={classes.formControl}>
        <Select
          value={placeName}
          renderValue={(selected) => {
            return <em>Come travel with us?</em>;
          }}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {places.map((place, index) => {
            return (
              <MenuItem key={`place${index}`} value={place.name}>
                {place.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {placeName && (
        <p className={classes.currentPlace}>
          <span className={classes.currentPlaceTitle}><RoomIcon /></span> {placeName}
        </p>
      )}
      <LoginMenu />
      <List className={classes.list} dense={true}>
        {categories.map((category) => {
          return (
            <>
              <ListItem className={classes.categoryTitle}>
                <ListItemText primary={category.title} />
              </ListItem>
              {category.menus.map((menu) => {
                return (
                  <ListItem className={clsx(classes.menuItem, !menu.url && 'nolink')}>
                    <ListItemText
                      primary={menu.title}
                      onClick={() => {
                        if (menu.url) {
                          history.push({
                            pathname: '/',
                            search: menu.url,
                          });
                        }
                      }}
                    />
                  </ListItem>
                );
              })}
            </>
          );
        })}
      </List>
    </Paper>
  );
};

export default forwardRef(CategoriesMenuContent);
