import React, { useEffect, useState } from "react";
import { Button, makeStyles, Grid, TextField, InputAdornment, Divider, Switch, } from "@material-ui/core";
import SizeGuideService from "../services/size-guide.service";

const useStyles = makeStyles((theme) => ({
    headContent: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2, 0),
        borderBottom: '2px solid red'
    },
    buttonLabel: {
        justifyContent: 'space-between',
        padding: theme.spacing(0, 2),
    },
    switch: {
        '& .MuiSwitch-colorSecondary': {
            color: '#ee1f3b',
        },
        '& .MuiSwitch-colorSecondary.Mui-checked': {
            color: '#ee1f3b',
        },
        '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#ee1f3b',
            opacity: 0.7,
        },
    }
}))

const FormInputSize = (props) => {
    const classes = useStyles();
    const { cmToInc, handleSwitchChange, handleChangeValue, handleDoneClick, bodyMeasurement, productSizeGuide } = props;
    const [sizeKey, setSizeKey] = useState();

    useEffect(() => {
        if (productSizeGuide) {
            const key = SizeGuideService.getSizeGuideKey(productSizeGuide[0]);
            console.log("sizeKey", key);
            setSizeKey(key)
        }
    }, [])

    return (<>
        <div className={classes.headContent}>
            <h4>Required parameters</h4>
            <p>Please enter all body measurements.</p>

            <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>cm</Grid>
                <Grid item>
                    <Switch checked={cmToInc} onChange={handleSwitchChange} name="cmToInc" classes={{ root: classes.switch }} />
                </Grid>
                <Grid item>inc</Grid>
            </Grid>
        </div>
        <Grid container spacing={2}>
            {/* <Grid item container alignItems="center">
                <Grid item xs>Chest circumference</Grid>
                <Grid item xs={3}>
                    <TextField
                        type="number"
                        id="input-chest"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{cmToInc ? 'inc' : 'cm'}</InputAdornment>,
                        }}
                        variant="outlined"
                        size="small"
                        classes={{ root: classes.textField }}
                        value={bodyMeasurement.chest || ""}
                        onChange={(e) => handleChangeValue('chest', e.target.value)}
                    /></Grid>
            </Grid>
            <Grid item xs><Divider /></Grid>
            <Grid item container alignItems="center">
                <Grid item xs>Circumference at navel level</Grid>
                <Grid item xs={3}>
                    <TextField
                        type="number"
                        id="input-waist"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{cmToInc ? 'inc' : 'cm'}</InputAdornment>,
                        }}
                        variant="outlined"
                        size="small"
                        classes={{ root: classes.textField }}
                        value={bodyMeasurement.waist || ""}
                        onChange={(e) => handleChangeValue('waist', e.target.value)}
                    /></Grid>
            </Grid>
            <Grid item xs><Divider /></Grid>
            <Grid item container alignItems="center">
                <Grid item xs>Hip</Grid>
                <Grid item xs={3}>
                    <TextField
                        type="number"
                        id="input-hip"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{cmToInc ? 'inc' : 'cm'}</InputAdornment>,
                        }}
                        variant="outlined"
                        size="small"
                        classes={{ root: classes.textField }}
                        value={bodyMeasurement.hip || ""}
                        onChange={(e) => handleChangeValue('hip', e.target.value)}
                    /></Grid>
            </Grid>
            <Grid item xs><Divider /></Grid>
            <Grid item container alignItems="center">
                <Grid item xs>Height</Grid>
                <Grid item xs={3}>
                    <TextField
                        type="number"
                        id="input-height"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{cmToInc ? 'inc' : 'cm'}</InputAdornment>,
                        }}
                        variant="outlined"
                        size="small"
                        classes={{ root: classes.textField }}
                        value={bodyMeasurement.height || ""}
                        onChange={(e) => handleChangeValue('height', e.target.value)}
                    /></Grid>
            </Grid>
            <Grid item xs><Divider /></Grid> */}

            {sizeKey && sizeKey.map((key, index) => {
                return <Grid item container alignItems="center" key={key + index} spacing={2}>
                    <Grid item xs>{key}</Grid>
                    <Grid item xs={3}>
                        <TextField
                            type="number"
                            id="input-height"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{cmToInc ? 'inc' : 'cm'}</InputAdornment>,
                            }}
                            variant="outlined"
                            size="small"
                            classes={{ root: classes.textField }}
                            value={bodyMeasurement[key] || ""}
                            onChange={(e) => handleChangeValue(key, e.target.value)}
                        /></Grid>
                    <Grid item xs={12}><Divider /></Grid>
                </Grid>
            })}

            <Grid item container justifyContent="center">
                <Grid item>
                    <Button color="secondary" disableRipple onClick={handleDoneClick} >Done</Button></Grid>
            </Grid>
        </Grid></>);
}
export default FormInputSize;