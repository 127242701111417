const GetPanoramaMockDatas = (google) => {
  return [
    {
      panoId: 'entrance',
      imageUrl: 'https://ficklestorage.blob.core.windows.net/demo-vr-store/ct-entrance.JPG',
      panoramaData: {
        location: {
          pano: 'entrance',
          description: 'Cotton on store entrance',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [
          {
            heading: 30,
            description: 'Go to kid',
            pano: 'men-shirt',
          },
          {
            heading: -30,
            description: 'Go to kid',
            pano: 'women',
          },
        ],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(4096, 2048),
          worldSize: new google.maps.Size(4096, 2048),
          centerHeading: -10,
        },
      },
    },
    {
      panoId: 'men-shirt',
      imageUrl: 'https://ficklestorage.blob.core.windows.net/demo-vr-store/ct-men.JPG',
      panoramaData: {
        location: {
          pano: 'men-shirt',
          description: 'Fickle sample place 1',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [
          {
            heading: 0,
            description: 'Go to men',
            pano: 'men-t-shirts',
          },
          {
            heading: 180,
            description: 'Go to men',
            pano: 'entrance',
          },
          {
            heading: -90,
            description: 'Go to men',
            pano: 'women',
          },
          {
            heading: -35,
            description: 'Go to men',
            pano: 'women-2',
          },
        ],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(4096, 2048),
          worldSize: new google.maps.Size(4096, 2048),
          centerHeading: -10,
        },
      },
    },
    {
      panoId: 'men-t-shirts',
      imageUrl: 'https://ficklestorage.blob.core.windows.net/demo-vr-store/ct-men2.JPG',
      panoramaData: {
        location: {
          pano: 'men-t-shirts',
          description: 'Fickle sample place 1',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [
          {
            heading: 30,
            description: 'Go to kid',
            pano: 'men-3',
          },

          {
            heading: 180,
            description: 'Go to Men 2',
            pano: 'men-shirt',
          },

          {
            heading: -120,
            description: 'Go to Men 2',
            pano: 'women-2',
          },
        ],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(4096, 2048),
          worldSize: new google.maps.Size(4096, 2048),
          centerHeading: -10,
        },
      },
    },
    {
      panoId: 'men-3',
      imageUrl: 'https://ficklestorage.blob.core.windows.net/demo-vr-store/ct-men3.JPG',
      panoramaData: {
        location: {
          pano: 'men-3',
          description: 'Fickle sample place 1',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [
          {
            heading: 30,
            description: 'Go to kid',
            pano: 'men-4',
          },

          {
            heading: -120,
            description: 'Go to Men 2',
            pano: 'men-t-shirts',
          },
          {
            heading: 100,
            description: 'Go to Men Changing Room',
            pano: 'men-changing-room',
          },
        ],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(4096, 2048),
          worldSize: new google.maps.Size(4096, 2048),
          centerHeading: -10,
        },
      },
    },
    {
      panoId: 'men-changing-room',
      imageUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/ct-changing-room.jpg',
      panoramaData: {
        location: {
          pano: 'men-changing-room',
          description: 'Fickle sample place 1',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [
          {
            heading: 180,
            description: 'return to Men 3',
            pano: 'men-3',
          },
        ],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(4096, 2048),
          worldSize: new google.maps.Size(4096, 2048),
          centerHeading: -10,
        },
      },
    },
    {
      panoId: 'men-4',
      imageUrl: 'https://ficklestorage.blob.core.windows.net/demo-vr-store/ct-men4.JPG',
      panoramaData: {
        location: {
          pano: 'men-4',
          description: 'Fickle sample place 1',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [
          {
            heading: -70,
            description: 'Go to kid',
            pano: 'counter',
          },

          {
            heading: 180,
            description: 'Go to Men 2',
            pano: 'men-3',
          },
        ],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(4096, 2048),
          worldSize: new google.maps.Size(4096, 2048),
          centerHeading: 60,
        },
      },
    },
    {
      panoId: 'counter',
      imageUrl: 'https://ficklestorage.blob.core.windows.net/demo-vr-store/ct-counter.JPG',
      panoramaData: {
        location: {
          pano: 'counter',
          description: 'Fickle sample place 1',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [
          {
            heading: -45,
            description: 'Go to Men 2',
            pano: 'women-swim',
          },
          {
            heading: -90,
            description: 'Go to Men 2',
            pano: 't-shirt',
          },
          {
            heading: 110,
            description: 'Go to Men 2',
            pano: 'men-4',
          },
          {
            heading: -170,
            description: 'Go to Men 2',
            pano: 'women-accessories',
          },
        ],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(4096, 2048),
          worldSize: new google.maps.Size(4096, 2048),
          centerHeading: -70,
        },
      },
    },
    {
      panoId: 't-shirt',
      imageUrl: 'https://ficklestorage.blob.core.windows.net/demo-vr-store/ct-tshirt.JPG',
      panoramaData: {
        location: {
          pano: 't-shirt',
          description: 'Fickle sample place 1',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [
          {
            heading: 90,
            description: 'Go to Men 2',
            pano: 'women-swim',
          },
          {
            heading: -100,
            description: 'Go to Men 2',
            pano: 'women-bag',
          },
          {
            heading: 180,
            description: 'Go to Men 2',
            pano: 'counter',
          },
        ],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(4096, 2048),
          worldSize: new google.maps.Size(4096, 2048),
          centerHeading: 30,
        },
      },
    },
    {
      panoId: 'women-accessories',
      imageUrl: 'https://ficklestorage.blob.core.windows.net/demo-vr-store/ct-women-accessories.JPG',
      panoramaData: {
        location: {
          pano: 'women-accessories',
          description: 'Fickle sample place 1',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [
          {
            heading: 130,
            description: 'Go to men',
            pano: 'women-2',
          },
          {
            heading: 45,
            description: 'Go to men',
            pano: 'counter',
          },
          {
            heading: -170,
            description: 'Go to men',
            pano: 'women',
          },
        ],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(4096, 2048),
          worldSize: new google.maps.Size(4096, 2048),
          centerHeading: 0,
        },
      },
    },
    {
      panoId: 'women-2',
      imageUrl: 'https://ficklestorage.blob.core.windows.net/demo-vr-store/ct-women2.JPG',
      panoramaData: {
        location: {
          pano: 'women-2',
          description: 'Fickle sample place 1',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [
          {
            heading: -60,
            description: 'Go to men',
            pano: 'women-accessories',
          },
          {
            heading: -120,
            description: 'Go to men',
            pano: 'women',
          },
          {
            heading: 45,
            description: 'Go to men',
            pano: 'men-t-shirts',
          },
          {
            heading: 150,
            description: 'Go to men',
            pano: 'men-shirt',
          },
        ],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(4096, 2048),
          worldSize: new google.maps.Size(4096, 2048),
          centerHeading: 0,
        },
      },
    },
    {
      panoId: 'women',
      imageUrl: 'https://ficklestorage.blob.core.windows.net/demo-vr-store/ct-women.JPG',
      panoramaData: {
        location: {
          pano: 'women',
          description: 'Fickle sample place 1',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [
          {
            heading: 60,
            description: 'Go to men',
            pano: 'women-2',
          },
          {
            heading: -10,
            description: 'Go to men',
            pano: 'women-accessories',
          },
          {
            heading: -170,
            description: 'Go to men',
            pano: 'entrance',
          },
          {
            heading: 120,
            description: 'Go to men',
            pano: 'men-shirt',
          },
        ],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(4096, 2048),
          worldSize: new google.maps.Size(4096, 2048),
          centerHeading: 0,
        },
      },
    },
    {
      panoId: 'women-swim',
      imageUrl: 'https://ficklestorage.blob.core.windows.net/demo-vr-store/ct-women-swimming.JPG',
      panoramaData: {
        location: {
          pano: 'women-swim',
          description: 'Fickle sample place 1',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [
          {
            heading: 160,
            description: 'Go to men',
            pano: 'counter',
          },
          {
            heading: -140,
            description: 'Go to men',
            pano: 't-shirt',
          },
        ],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(4096, 2048),
          worldSize: new google.maps.Size(4096, 2048),
          centerHeading: -10,
        },
      },
    },
    {
      panoId: 'women-bag',
      imageUrl: 'https://ficklestorage.blob.core.windows.net/demo-vr-store/ct-women-bag.JPG',
      panoramaData: {
        location: {
          pano: 'women-bag',
          description: 'Fickle sample place 1',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [
          {
            heading: 120,
            description: 'Go to men',
            pano: 't-shirt',
          },
          {
            heading: 170,
            description: 'Go to men',
            pano: 'women-lingerie',
          },
        ],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(4096, 2048),
          worldSize: new google.maps.Size(4096, 2048),
          centerHeading: -10,
        },
      },
    },
    {
      panoId: 'women-lingerie',
      imageUrl: 'https://ficklestorage.blob.core.windows.net/demo-vr-store/ct-women-underwear.JPG',
      panoramaData: {
        location: {
          pano: 'women-lingerie',
          description: 'Fickle sample place 1',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [
          {
            heading: -150,
            description: 'Go to men',
            pano: 'women-bag',
          },
          {
            heading: -100,
            description: 'Go to men',
            pano: 'kid',
          },
        ],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(4096, 2048),
          worldSize: new google.maps.Size(4096, 2048),
          centerHeading: -10,
        },
      },
    },
    {
      panoId: 'kid',
      imageUrl: 'https://ficklestorage.blob.core.windows.net/demo-vr-store/ct-kid.JPG',
      panoramaData: {
        location: {
          pano: 'kid',
          description: 'Fickle sample place 1',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [
          {
            heading: -94,
            description: 'Exit',
            pano: 'kid-dresses',
          },
          {
            heading: 180,
            description: 'Go to Entrance',
            pano: 'women-lingerie',
          },
        ],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(4096, 2048),
          worldSize: new google.maps.Size(4096, 2048),
          centerHeading: 0,
        },
      },
    },
    {
      panoId: 'kid-dresses',
      imageUrl: 'https://ficklestorage.blob.core.windows.net/demo-vr-store/ct-kid2.JPG',
      panoramaData: {
        location: {
          pano: 'kid-dresses',
          description: 'Fickle sample place 1',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [
          {
            heading: 180,
            description: 'Exit',
            pano: 'kid',
          },
        ],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(4096, 2048),
          worldSize: new google.maps.Size(4096, 2048),
          centerHeading: 0,
        },
      },
    },
    {
      panoId: 'changing-room-11',
      imageUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/test-pano-5.jpg',
      panoramaData: {
        location: {
          pano: 'changing-room-11',
          description: 'Cotton on store entrance',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(2048, 1024),
          worldSize: new google.maps.Size(2048, 1024),
          centerHeading: 50,
        },
      },
    },
    {
      panoId: 'changing-room-3',
      imageUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/test-pano-6.jpg',
      panoramaData: {
        location: {
          pano: 'changing-room-3',
          description: 'Cotton on store entrance',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(2048, 1024),
          worldSize: new google.maps.Size(2048, 1024),
          centerHeading: 50,
        },
      },
    },
    {
      panoId: 'secondfloor-elevator',
      imageUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/ct-secondfloor-elevator1.jpg',
      panoramaData: {
        location: {
          pano: 'secondfloor-elevator',
          description: 'Fickle sample place 1',
          latLng: new google.maps.LatLng(18.808103950939206, 99.01803451609739),
        },
        links: [],
        copyright: '© 2021 Fidelity',
        tiles: {
          tileSize: new google.maps.Size(4096, 2048),
          worldSize: new google.maps.Size(4096, 2048),
          centerHeading: 60,
        },
      },
    },
  ];
};

export default GetPanoramaMockDatas;
