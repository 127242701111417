const KEY_USER = 'user';
const KEY_USER_VOLUME = 'user-volume';
const username = 'vrtest';
const password = 'vr100%';
const UserService = {
    addUser: function (data) {
        return addUser(data);
    },
    getUser: function () {
        return getUser();
    },
    getUserVolume: function () {
        return getUserVolume();
    },
    updateUserVolume: function (volume) {
        updateUserVolume(volume);
    }
};

function addUser(data) {
    if (data.username === username && data.password === password) {
        const user = data;
        localStorage.setItem(KEY_USER, JSON.stringify(user));
        return true
    } else {
        return false
    }
}

function getUser() {
    let userJsonString = localStorage.getItem(KEY_USER);
    if (userJsonString) {
        const user = JSON.parse(userJsonString);
        if (user.username === username && user.password === password) {
            return true;
        } else {
            return false;
        }
    }
    return false;
}

function getUserVolume() {
    const userVolumeJson = localStorage.getItem(KEY_USER_VOLUME);
    if (userVolumeJson) {
        const volume = JSON.parse(userVolumeJson);
        return volume;
    } else {
        return { volume: 1 };
    }
}

function updateUserVolume(volume) {
    const userVolume = { volume: volume };
    localStorage.setItem(KEY_USER_VOLUME, JSON.stringify(userVolume));
}

export default UserService;
