import React, { useState, useEffect, forwardRef, useContext } from 'react';
import {
    Container,
    TextField,
    makeStyles,
    Button,
    Typography,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel
} from '@material-ui/core';
import CottonOnSvg from '../styled-components/CottonOnSvg';
import CloseIcon from '@material-ui/icons/Close';
import TagIcon from '@material-ui/icons/LocalOffer';
import clsx from 'clsx';
import TimeIcon from '@material-ui/icons/WatchLaterRounded';
import HotIcon from '@material-ui/icons/WhatshotRounded';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3),
        borderRadius: 8,
        maxHeight: 'calc(100% - 100px)',
        margin: theme.spacing(0, 2),
        '& input::-webkit-inner-spin-button, input::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
        }
    },
    addProductTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    addProductTitleText: {
        marginLeft: theme.spacing(2),
        padding: theme.spacing(1, 2),
        borderLeft: '2px solid #000',
        fontSize: 24,
        fontWeight: 'bold',
    },
    addProductBtn: {
        fontSize: 18,
        textTransform: 'unset',
        color: '#fff',
        background: '#26a65b',
        '&:hover': {
            background: '#00884b',
        },
    },
    buttonShadow: {
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
    },
    addProductContent: {
        width: '100%',
        height: 'calc(100% - 56px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        padding: theme.spacing(2),
        '& .MuiTypography-colorTextSecondary': {
            color: '#212529',
        }
    },
    formControl: {
        minWidth: 120,
        '& .MuiInputBase-root': {
            minHeight: 64,
        },
        '& .MuiFormControlLabel-root': {
            minWidth: 100,
            '& .MuiRadio-root': {
                display: 'none'
            },
            '& .Mui-checked + .MuiFormControlLabel-label': {
                borderColor: '#212529',
                boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
            },
            '& .MuiFormControlLabel-label': {
                border: '2px solid #dee2e6',
                borderRadius: 16,
                width: '100%',
                height: 64,
                padding: theme.spacing(1),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: theme.spacing(0, 1),
            }
        },
    },
    closeIconButton: {
        position: 'absolute',
        top: 10,
        right: 10,
        color: '#707070',
        padding: 0,
    },
    newLabel: {
        borderRadius: 50,
        background: 'linear-gradient(90deg, rgba(255,186,81,1) 0%, rgba(247,167,55,1) 100%)',
        padding: theme.spacing(0, 1),
        color: '#f8f9fa'
    },
    discountLabel: {
        borderRadius: 50,
        background: '#f11934',
        padding: theme.spacing(0, 1),
        color: '#f8f9fa'
    },
    tagIcon: {
        fontSize: '2rem',
        color: '#343a40',
        borderRadius: '50%',
    },
    timeIcon: {
        // border: '2px solid #6c757d',
        color: '#f8f9fa',
        borderRadius: '50%',
        backgroundColor: '#343a40',
        fontSize: '2rem',
        animation: 'rotateIcon 5s infinite',
        animationTimingFunction: 'linear',
    },
    hotIcon: {
        fontSize: '2.1rem',
        color: 'red',
        animation: 'colorChange 2s infinite',
        animationTimingFunction: 'linear',
    },
    textFieldOutline: {
        borderColor: '#212529 !important'
    },
    textFieldErrorOutline: {
        borderColor: '#dc3545 !important'
    }
}));
const AddProductModal = forwardRef((props, ref) => {
    const classes = useStyles();
    const { handleCloseModal, hpCoords, addPoi, currentPanoId } = props;
    const [productCode, setProductCode] = useState('');
    const [productIcon, setProductIcon] = useState('price');
    const [productLabel, setProductLabel] = useState('');
    const [discountPercent, setDiscountPercent] = useState('');
    const [productCodeMessage, setProductCodeMessage] = useState('');

    const handleChangeProductCode = (event) => {
        setProductCodeMessage('')
        setProductCode(Number(event.target.value))
    }
    const handleSelectIconChange = (event) => {
        setProductIcon(event.target.value);
    };
    const handleSelectLabelChange = (event) => {
        setProductLabel(event.target.value);
    };
    const handleChangeProductDiscount = (event) => [
        setDiscountPercent(event.target.value)
    ]

    const handleClickAddProduct = (event) => {
        const randomId = Math.floor(Date.now() * Math.random());
        event.preventDefault();
        if (productCode) {
            let poiData = {
                poiId: randomId,
                panoId: currentPanoId,
                type: 'price',
                poiHeading: hpCoords.heading,
                poiPitch: hpCoords.pitch,
                price: 19.99,
                productId: productCode,
                tagIcon: productIcon,
                newArrival: productLabel === 'new' ? true : false,
                discount: productLabel === 'discount' ? `${discountPercent}%` : false,
            }
            console.log("Product: ", poiData);
            addPoi(poiData);
            handleCloseModal();
        } else {
            setProductCodeMessage('**Please enter product code')
        }
    }
    return (
        <>
            <Container maxWidth="sm" classes={{ root: classes.container }}>
                <IconButton color="secondary" className={classes.closeIconButton} onClick={handleCloseModal}>
                    <CloseIcon className={classes.icon} />
                </IconButton>
                <div className={classes.addProductTitle}>
                    <CottonOnSvg width={150} />
                    <span className={classes.addProductTitleText}>Add Product</span>
                </div>
                <div className={classes.addProductContent}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item container direction="column" >
                            <Grid item>
                                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                    <strong>Cotton-On Product Code</strong>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    autoComplete="off"
                                    margin="dense"
                                    id="cottononProductCode"
                                    placeholder="Product Code"
                                    helperText={productCodeMessage}
                                    error={productCodeMessage ? true : false}
                                    type="number"
                                    fullWidth
                                    value={productCode || ''}
                                    onChange={handleChangeProductCode}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: productCodeMessage ? classes.textFieldErrorOutline : classes.textFieldOutline,
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" >
                            <Grid item>
                                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                    <strong>Icon</strong>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <RadioGroup aria-label="icon" name="icon1" row value={productIcon} onChange={handleSelectIconChange}>
                                        <FormControlLabel value="price" control={<Radio />} label={<TagIcon classes={{ root: classes.tagIcon }} />} />
                                        <FormControlLabel value="time" control={<Radio />} label={<TimeIcon classes={{ root: classes.timeIcon }} />} />
                                        <FormControlLabel value="hot" control={<Radio />} label={<HotIcon classes={{ root: classes.hotIcon }} />} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" >
                            <Grid item>
                                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                    <strong>Label</strong>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <RadioGroup aria-label="productLabel" name="productLabel" row value={productLabel} onChange={handleSelectLabelChange}>
                                        <FormControlLabel value="" control={<Radio />} label={`None`} />
                                        <FormControlLabel value="new" control={<Radio />} label={`New`} classes={{ label: classes.newLabel }} />
                                        <FormControlLabel value="discount" control={<Radio />} label={`Discount`} classes={{ label: classes.discountLabel }} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {productLabel === 'discount' ? <Grid item>
                                <TextField
                                    variant="outlined"
                                    autoFocus
                                    autoComplete="off"
                                    margin="dense"
                                    id="productDiscount"
                                    placeholder="Product Discount"
                                    type="text"
                                    fullWidth
                                    value={discountPercent}
                                    required
                                    onChange={handleChangeProductDiscount}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: classes.textFieldOutline
                                        }
                                    }}
                                />
                            </Grid> : <></>}
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                            <Button variant="contained" classes={{ root: classes.addProductBtn, contained: classes.buttonShadow }} onClick={handleClickAddProduct} >
                                Add Product
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </>
    );
})
export default AddProductModal;