import React, { forwardRef, useState } from 'react';
import { Button, makeStyles, withStyles } from '@material-ui/core';
import FloorSelection from './FloorSelection';
import ElevatorIcon from '@material-ui/icons/ListAltRounded';
import PlayCircleFilledWhiteRounded from '@material-ui/icons/PlayCircleFilledWhiteRounded';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    borderRadius: 12,
  },
  up: {
    transform: 'rotate(-90deg)',
    marginBottom: 3,
  },
  down: {
    transform: 'rotate(90deg)',
    marginTop: 3,
  },
  imgBtn: {
    width: '100%',
  }
}));

const SelectionButton = withStyles((theme) => ({
  root: {
    zIndex: 100,
    marginTop: '-20%',
    marginLeft: '-47%',
    transform: 'rotate(354deg)',
    width: 150,
    // padding: 3,
    border: '2px solid #FFF',
    color: '#FFF',
    // background: 'radial-gradient(circle, rgba(0,0,0,0.5) 0%, rgba(238,31,59,1) 100%);',
    background: 'rgb(0 0 0 / 70%)',
    borderColor: '#cacaca',
    minWidth: 0,
    '&:hover': {
      backgroundColor: 'rgb(0 0 0 / 100%)',
      boxShadow: '1px 0px 9px 0px #e9ecef; -webkit-box-shadow: 1px 0px 9px 0px #e9ecef; -moz-box-shadow: 1px 0px 9px 0px #e9ecef;',
    },
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 30%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 30%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.3);',
  },
  label: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    '& svg': {
      fontSize: '1.75rem',
      width: '1em',
      height: '1em',
    },
  },
}))(Button);

const PoiSelcetion = forwardRef((props, ref) => {
  const classes = useStyles();
  const { index, poi, handleOpenModal, handleCloseModal } = props;

  const body = <FloorSelection poi={poi} handleCloseModal={handleCloseModal} />;

  const handleClick = () => {
    if (handleOpenModal) {
      handleOpenModal(body);
    }
  };

  return (
    <div ref={ref} className="poiPoint" data-poi-index={index}>
      <SelectionButton color="primary" onClick={handleClick} className={classes.button} size="small">
        {/* <PlayCircleFilledWhiteRounded className={classes.up} />
        <PlayCircleFilledWhiteRounded className={classes.down} /> */}
        <img src="/images/upstairs_03.png" className={classes.imgBtn} />
      </SelectionButton>
    </div>
  );
});

export default PoiSelcetion;
