const KEY_SHOPPING_CART = 'sc';
const ShoppingCartService = {
  addProduct: function (product, totalItem) {
    insertOrUpdateShoppingCartItem(product, totalItem);
  },
  getShoppingCartItems: function () {
    return getShoppingCartItems();
  },
  removeShoppingCartItem: function (productSku) {
    removeShoppingCartItem(productSku);
  }
};

function getShoppingCartItems() {
  let shoppingCartJsonString = localStorage.getItem(KEY_SHOPPING_CART);
  if (shoppingCartJsonString) {
    return JSON.parse(shoppingCartJsonString);
  }
  return [];
}

function insertOrUpdateShoppingCartItem(product, totalItem) {
  let shoppingCart = getShoppingCartItems();
  let shoppingCartItem;

  let item = shoppingCart.find((x) => x.productSku == product.productSku);
  if (item) {
    shoppingCartItem = item;
    shoppingCartItem.totalItem += totalItem;
  }

  if (!shoppingCartItem) {
    shoppingCartItem = {
      ...product,
      selected: false,
      totalItem: totalItem,
    };
    shoppingCart.push(shoppingCartItem);
  }
  console.log('shoppingCartItem', shoppingCartItem);
  localStorage.setItem(KEY_SHOPPING_CART, JSON.stringify(shoppingCart));
}

function removeShoppingCartItem(productSku) {
  let shoppingCart = getShoppingCartItems();

  let filtered = shoppingCart.filter(x => x.productSku !== productSku);
  console.log('filtered', filtered);
  localStorage.setItem(KEY_SHOPPING_CART, JSON.stringify(filtered));
}

export default ShoppingCartService;
