import { Box, Grid, makeStyles, Slide, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, Tooltip, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { Emitter, EventName } from '../services/emitter';

const BlackCheckbox = withStyles({
  root: {
    color: '#212529',
    '&$checked': {
      color: '#212529',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    top: 100,
    left: 1,
    zIndex: 100,
    position: 'absolute',
  },
  menusContainer: {
    backgroundColor: '#fff',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
  },
  menuItem: {
    padding: theme.spacing(1),
    color: '#555',
    '& a': {
      color: '#555',
    },
    textAlign: 'center',
    position: 'relative',
    cursor: 'pointer',
  },
  filterContainer: {
    position: 'absolute',
    display: 'flex',
    // justifyContent: 'center',
    left: 45,
    borderRadius: 8,
    top: 0,
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
    background: '#fff',
    minWidth: 120,
    padding: theme.spacing(1),
    '& .MuiFormControlLabel-root': {
      margin: 0,
    }
  },
  formControl: {
    display: 'flex',
    width: '100%',
  },
  formLabel: {
    color: '#212529',
    borderBottom: '1px solid #212529',
    fontSize: '1rem',
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    fontWeight: '600',
    margin: 0
  }
}));

const FilterMenu = (props) => {
  const classes = useStyles();
  const [showFilterContainer, setShowFilterContainer] = useState(false);
  const [filters, setFilters] = useState({
    discount: false,
    newArrival: false,
  });

  const handleChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  const getFilterKey = () => {
    return Object.keys(filters).filter(key => filters[key] === true);
  }
  useEffect(() => {
    const filterName = getFilterKey()
    Emitter.emit(EventName.FilterChanged, { filters: filterName });
  }, [filters])

  const { discount, newArrival } = filters;

  return (
    <Box className={clsx(classes.root, classes.menusContainer)}>
      <Grid container direction="column">
        <Grid item className={classes.menuItem}>
          <Tooltip title="Filter Menu" placement="right" arrow>
            <span>
              <FontAwesomeIcon icon={faFilter} onClick={() => { setShowFilterContainer(!showFilterContainer); }} />
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <Slide direction="right" in={showFilterContainer} mountOnEnter unmountOnExit>
        <div className={classes.filterContainer}>
          <FormControl className={classes.formControl}>
            <FormLabel focused={false} className={classes.formLabel}>Filter</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<BlackCheckbox checked={discount} onChange={handleChange} name="discount" />}
                label="Sale"
              />
              <FormControlLabel
                control={<BlackCheckbox checked={newArrival} onChange={handleChange} name="newArrival" />}
                label="New"
              />
            </FormGroup>
          </FormControl>
        </div>
      </Slide>
    </Box>
  );
};

export default FilterMenu;
