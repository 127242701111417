import React, { forwardRef } from 'react';
import { Button, makeStyles, Paper, Tooltip, withStyles } from '@material-ui/core';
import Product from './Product';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { useHistory, useLocation } from 'react-router-dom';

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    borderRadius: '50%',
  },
}));

const PortalButton = withStyles((theme) => ({
  root: {
    marginTop: '-50%',
    marginLeft: '-50%',
    padding: 10,
    border: '2px solid #FFF',
    color: '#FFF',
    background: 'radial-gradient(circle, rgba(0,0,0,0.5) 0%, rgba(238,31,59,1) 100%);',
    minWidth: 0,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
  },
  label: {
    padding: 0,
    '& svg': {
      width: '1em',
      height: '1em',
    },
  },
}))(Button);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 13,
    fontWeight: 'normal',
    maxWidth: 310,
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

const PoiPortal = forwardRef((props, ref) => {
  const classes = useStyles();
  const pathname = usePathname();
  const history = useHistory();
  const { index, poi, handleOpenModal } = props;

  const handleClick = () => {
    if (poi && poi.url) {
      if (poi.url.slice(0, 1) === '?') {
        history.push({
          pathname: pathname,
          search: poi.url,
        });
      } else {
        window.open(poi.url, '_blank');
      }
    }
  };

  return (
    <div ref={ref} className="poiPoint" data-poi-index={index}>
      <LightTooltip
        title={poi.title ? poi.title : `We have some new exciting offers for you upstairs. Go through this door and I will show you!`}
        aria-label="add"
        placement="top"
        arrow
      >
        <PortalButton color="primary" onClick={handleClick} className={classes.button} size="small">
          <MeetingRoomIcon />
        </PortalButton>
      </LightTooltip>
    </div>
  );
});

export default PoiPortal;
