import { Box, IconButton, makeStyles, Paper, Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import ProductHome from './Product-Home';
import ProductStylist from './Product-Stylist';
import ProductMembers from './Product-Members';

const useTabPanelStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 120px)',
    overflowY: 'auto',
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  const classes = useTabPanelStyles();

  return (
    <div className={classes.root} role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box>
          <div style={{ margin: 0 }}>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AntTabs = withStyles((theme) => ({
  root: {
    //borderBottom: '1px solid #e8e8e8',
    paddingLeft: theme.spacing(2),
  },
  indicator: {
    backgroundColor: 'transparent',
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 0,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: 0,
    '&:hover': {
      color: '#000',
      opacity: 1,
    },
    '&$selected': {
      color: '#000',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#000',
    },
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  selected: {
    textDecoration: 'underline',
  },
}))((props) => <Tab disableRipple {...props} />);

const TabSeperator = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 0,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: 0,
    '&:hover': {
      color: '#000',
      opacity: 1,
    },
    '&$selected': {
      color: '#000',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#000',
    },
    paddingLeft: 0,
    paddingRight: 0,
  },
  disabled: {
    color: '#000 !important',
  },
}))((props) => <Tab disableRipple disabled {...props} label="●" value={-1} />);

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    position: 'relative',
    minWidth: 850,
  },
  closeIconButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: '#707070',
    padding: 0,
  },
  poweredBy: {
    textAlign: 'right',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    fontSize: 14,
  },
}));

const Product = forwardRef((props, ref) => {
  const { onCloseButtonClick, productId = 1 } = props;
  const classes = useStyles();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };

  const handleCloseButtonClick = () => {
    if (onCloseButtonClick) {
      onCloseButtonClick();
    }
  };

  return (
    <Paper className={classes.root}>
      <AntTabs value={currentTabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
        <AntTab label="Home" value={0} />
        <TabSeperator />
        <AntTab label="Product Details" value={1} />
        <TabSeperator />
        <AntTab label="Stylist and Influencer Access" value={2} />
        <TabSeperator />
        <AntTab label="Members Only Access" value={3} style={{ fontWeight: 600, color: '#e40e2e' }} />
      </AntTabs>
      <TabPanel value={currentTabIndex} index={0}>
        <ProductHome productId={productId} />
      </TabPanel>
      <TabPanel value={currentTabIndex} index={1}>
        <img src="/images/product-details.png" width="800" style={{ display: 'block' }} />
        <h5 style={{ marginTop: 10 }}>Size Guide</h5>
        <img src="/images/size-guide.png" width="800" style={{ display: 'block' }} />
      </TabPanel>
      <TabPanel value={currentTabIndex} index={2}>
        <ProductStylist />
      </TabPanel>
      <TabPanel value={currentTabIndex} index={3}>
        <ProductMembers />
      </TabPanel>
      <IconButton color="secondary" className={classes.closeIconButton} onClick={handleCloseButtonClick}>
        <CloseIcon className={classes.icon} />
      </IconButton>
      <Box className={classes.poweredBy}>
        <span>
          Powered by <b>Fidelity™</b>
        </span>
      </Box>
    </Paper>
  );
});

export default Product;
