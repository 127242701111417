import React, { useRef, useEffect } from 'react';
import PosterService from '../services/poster.service';
import { Emitter, EventName } from '../services/emitter';
import { parseGIF, decompressFrames } from 'gifuct-js';

const PostersContainer = (props) => {
  const { posterList } = props;
  const postersCanvas = useRef();
  const dataLoadedRef = useRef(false);
  const posterMediasRef = useRef([]);
  const panoramaViewPropsRef = useRef();
  const videoElementsRef = useRef([]);

  const loadPosters = () => {
    posterMediasRef.current.map((posterMedia, index) => {
      if (posterMedia.poster.type === 'image') {
        loadImage(posterMedia);
      } else if (posterMedia.poster.type === 'gif') {
        loadGIF(posterMedia);
      } else if (posterMedia.poster.type === 'mp4') {
        loadMP4(posterMedia);
      }
    });
    dataLoadedRef.current = true;
  };

  const loadImage = (posterMedia) => {
    let img = new Image();
    img.onload = () => {
      posterMedia.mediaData = img;
      PosterService.updatePostersPosition(panoramaViewPropsRef.current, posterMediasRef.current, postersCanvas.current);
    };
    img.src = posterMedia.poster.imageUrl;
  };

  const loadGIF = (posterMedia) => {
    var oReq = new XMLHttpRequest();
    oReq.open('GET', posterMedia.poster.imageUrl, true);
    oReq.responseType = 'arraybuffer';

    oReq.onload = function (oEvent) {
      var arrayBuffer = oReq.response; // Note: not oReq.responseText
      if (arrayBuffer) {
        let gif = parseGIF(arrayBuffer);
        var frames = decompressFrames(gif, true);
        posterMedia.mediaData = { frames: frames };
        PosterService.updatePostersPosition(panoramaViewPropsRef.current, posterMediasRef.current, postersCanvas.current);
      }
    };

    oReq.send(null);
  };

  const loadMP4 = (posterMedia) => {
    let video = document.createElement('video');
    video.autoplay = true;
    video.muted = true; //Always muted for autoplay
    video.type = 'video/mp4';
    video.loop = true;
    video.onloadeddata = () => {
      posterMedia.mediaData = video;
      PosterService.updatePostersPosition(panoramaViewPropsRef.current, posterMediasRef.current, postersCanvas.current);
      video.play();
    };
    video.src = posterMedia.poster.videoUrl;

    videoElementsRef.current.push(video);
  };

  const handleStreetViewOnPovChanged = (data) => {
    panoramaViewPropsRef.current = data.panoramaViewProps;
    if (!dataLoadedRef.current) {
      loadPosters();
    } else {
      PosterService.updatePostersPosition(panoramaViewPropsRef.current, posterMediasRef.current, postersCanvas.current);
    }
  };

  useEffect(() => {
    Emitter.on(EventName.StreetViewOnPovChanged, handleStreetViewOnPovChanged);
    return () => {
      Emitter.off(EventName.StreetViewOnPovChanged, handleStreetViewOnPovChanged);
    };
  }, []);

  useEffect(() => {
    posterMediasRef.current = posterList.map((poster) => {
      return { poster: poster, mediaData: null };
    });
    dataLoadedRef.current = false;

    return () => {
      PosterService.cleanUp(posterMediasRef.current);
      if (videoElementsRef.current) {
        videoElementsRef.current.forEach((videoElement) => {
          videoElement.pause();
          videoElement.removeAttribute('src'); // empty source
          videoElement.load();
        });
        videoElementsRef.current = [];
      }
    };
  }, [posterList]);

  return <canvas width="1920" height="943" className="testCanvas" ref={postersCanvas}></canvas>;
};
export default PostersContainer;
