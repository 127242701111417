import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Dialog, DialogTitle, DialogContent, Typography } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import dateFormat from 'dateformat';
import CouponService from '../services/coupon.service';
import { CouponContext } from '../contexts/CouponContext';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },
    button: {
        borderRadius: '50%',
    },
    imgTitle: {
        height: 120,
        position: 'relative',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'top',
        background: '#c0392b',
    },
    dialogTitle: {
        background: '#dddddd',
    },
    dialogContent: {
        background: '#ddd'
    },
    contentBox: {
        borderRadius: '0.5em',
        // boxShadow: '0px 1px 9px -2px rgb(0 0 0 / 30%); -webkit-box-shadow: 0px 1px 5px -2px rgb(0 0 0 / 30%); -moz-box-shadow: 0px 1px 5px -2px rgba(0,0,0,0.3);',
        position: 'relative',
        background: '#fff',
        marginBottom: '1em',
        overflow: 'hidden'
    },
    devideLine: {
        border: '1px dashed #dddddd',
        position: 'relative',
        '&::before': {
            content: '" "',
            display: 'block',
            height: 20,
            width: 20,
            position: 'absolute',
            top: '-0.5em',
            left: '-0.7em',
            borderRadius: '50%',
            background: '#ddd',
        },
        '&::after': {
            content: '" "',
            display: 'block',
            height: 20,
            width: 20,
            position: 'absolute',
            top: '-0.5em',
            right: '-0.7em',
            borderRadius: '50%',
            background: '#ddd'
        }
    },
    contentDetailTop: {
        padding: '1.5em',
        color: '#000'
    },
    contentDetailBottom: {
        padding: '1em',
        textAlign: 'center'
    },
    status: {
        borderRadius: 50,
        // padding: '0.5em 1em',
        // background: 'red',
        color: 'red',
    },
}));

const CouponDetails = (props) => {
    const classes = useStyles();
    const Items = {
        image: '/images/shopping-img.jpg',
        name: 'Regular Graphic T Shirt',
    }
    const { open, onCloseCouponDetails, couponId } = props
    const [coupon, setCoupon] = useState([]);
    const [couponContext] = useContext(CouponContext);
    const [inprogressCoupon, setInprogressCoupon] = useState();

    useEffect(() => {
        let coupon = CouponService.getCouponDetailsById(couponId);
        setCoupon(coupon);
        console.log('getCouponDetailsById', coupon);
        isCollectedCoupons(coupon)
    }, [couponId]);

    const isCollectedCoupons = (coupon) => {
        if (coupon.collected) { setInprogressCoupon('Collected') }
        else if (!coupon.collected && coupon.requirement.type === 'CountLocations') {
            setInprogressCoupon(`${coupon.requirement.metLocationIds.length}/${coupon.requirement.totalLocations}`);
        } else if (!coupon.collected && coupon.requirement.type === 'SpecificLocations') {
            setInprogressCoupon(`${coupon.requirement.metLocationIds.length}/${coupon.requirement.locations.length}`);
        }
    }

    return <>
        <Dialog
            open={open}
            onClose={onCloseCouponDetails}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <img src={Items.image} className={classes.imgTitle} />
            {/* <div className={classes.imgTitle}></div> */}
            <DialogTitle className={classes.dialogTitle}>
                {"Coupon Details"}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <div className={classes.contentBox}>
                    <div className={classes.contentDetailTop}>
                        <Typography display="block" gutterBottom><strong>{coupon.title} Coupon.</strong></Typography>
                        <Typography variant="body1" display="block">
                            {coupon.description}
                        </Typography>
                    </div>
                    <div className={classes.devideLine}></div>
                    <div className={classes.contentDetailBottom}>
                        <Typography variant="button" display="block" gutterBottom className={classes.status}>{inprogressCoupon}</Typography>
                        <Typography variant="caption" display="block" > <WarningRoundedIcon />Expired date: {dateFormat(coupon.expiredDate, 'shortDate')}</Typography>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    </>
}
export default CouponDetails