import { withStyles } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import React from 'react';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';

const FavoriteToggleButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    borderRadius: 2,
    padding: 9,
    border: '1px solid #979797',
    color: '#333',
  },
  selected: {
    color: '#ee1f3b !important',
    backgroundColor: '#fff !important',
  },
}))((props) => (
  <ToggleButton {...props} value="check" selected={props.selected} onChange={props.onChange}>
    {props.selected ? <FavoriteIcon /> : <FavoriteBorderIcon />}
  </ToggleButton>
));

export default FavoriteToggleButton;
