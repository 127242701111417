import { Grid, makeStyles, Typography, Button } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    '& p': {
      paddingLeft: 16,
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    margin: 0,
  },
  imgContainer: {
    background: '#000000',
    '& img': {
      maxWidth: '800px',
      display: 'block',
      margin: 'auto',
    }
  },
  groupBtn: {
    display: 'flex',
    justifyContent: 'center',
    '& a': {
      textDecoration: 'none'
    },
    '& button': {
      borderRadius: 0,
      fontWeight: 'bold',
      textTransform: 'unset',
      borderStyle: 'solid',
      borderWidth: 2,
      margin: 4,
      width: 120,
      fontSize: '0.8rem',
    }
  },
  joinBtn: {
    background: '#f8f9fa',
    color: '#000',
    borderColor: '#f8f9fa',
    '&:hover': {
      background: '#f8f9fa',
      color: '#000',
      borderColor: '#f8f9fa',
    }
  },
  signinBtn: {
    background: '#000',
    color: '#f8f9fa',
    borderColor: '#f8f9fa',
    '&:hover': {
      background: '#000',
      color: '#f8f9fa',
      borderColor: '#f8f9fa',
    }
  }
}));

const ProductMembers = (props) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} direction="column">
      <Grid item>
        <p className={classes.title}>Membership Area.</p>
      </Grid>
      <Grid item>
        <p>As a member of <strong>Cotton On's</strong> Perks Program, you’ll receive exclusive offers like free shipping, no styling fees and $20 Perk Credits per 2000 points you earn. You're automatically enrolled upon creating an account with Cotton On, so you can start earning your Perk Points from day one.</p>
      </Grid>
      <Grid item className={classes.imgContainer} xs>
        <img src="/images/members-access/perk_011.gif" alt="members_access" />
        <div className={classes.groupBtn}>
          <a href="https://cottonon.com/AU/subscribe/" target="_blank">
            <Button classes={{ root: classes.joinBtn }}>
              Join Now
            </Button>
          </a>
          <a href="https://cottonon.com/AU/sign-in/" target="_blank">
            <Button classes={{ root: classes.signinBtn }}>
              Sign In
            </Button>
          </a>
        </div>
        <img src="/images/members-access/perk_05.jpg" alt="members_access" />
        <img src="/images/members-access/perk_04.jpg" alt="members_access" />
        <img src="/images/members-access/perk_02.jpg" alt="members_access" />
        <img src="/images/members-access/perk_03.jpg" alt="members_access" />
        <img src="/images/members-access/perk_05.jpg" alt="members_access" />
        <img src="/images/members-access/member_access_02.png" alt="members_access" />
        <img src="/images/members-access/perk_05.jpg" alt="members_access" />
      </Grid>
    </Grid>
  );
};

export default ProductMembers;
