import React, { useEffect, useState } from "react";
import { Button, makeStyles, Modal, Slide, IconButton, withStyles, Snackbar } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIosRounded';
import CloseIcon from '@material-ui/icons/Close';
import FormInputSize from "./FormInputSize";
import ResultSize from "./ResultSize";
import SizeGuideService from "../services/size-guide.service";

const DoneSnackbar = withStyles((theme) => ({
    root: {
        '& .MuiSnackbarContent-root': {
            minWidth: 'unset !important',
            backgroundColor: '#f8f9fa',
            color: '#212529',
            fontWeight: 600
        }
    }
}))(Snackbar)

const useStyles = makeStyles((theme) => ({
    sizeGuidBtn: {
        width: '100%',
        borderRadius: 50,
        backgroundColor: '#ffc107',
        lineHeight: 2.5,
        textTransform: 'unset',
        margin: theme.spacing(1, 0),
        '&:hover': {
            backgroundColor: '#e7ad00',
        }
    },
    modalBody: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        width: 500,
        backgroundColor: '#fff',
        padding: theme.spacing(4),
    },
    closeIconButton: {
        position: 'absolute',
        top: 10,
        right: 10,
        color: '#707070',
        padding: 0,
    },
    buttonLabel: {
        justifyContent: 'space-between',
        padding: theme.spacing(0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}))

const SizeGuide = (props) => {
    const { productName, productSizeGuide, productId } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [yourSize, setYourSize] = useState();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [bodyMeasurement, setBodyMeasurement] = useState({
        // height: 180,
        // chest: 100,
        // waist: 82,
        // hip: 99,
        // weight: 70,
        height: 70.9,
        chest: 39.4,
        waist: 32.3,
        hip: 39,
        weight: 0,
    });
    const [cmToInc, setCmToInc] = useState(false);
    const [resultSize, setResultSize] = useState();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSwitchChange = () => {
        setCmToInc(!cmToInc);
    }

    const handleChangeValue = (name, value) => {
        setBodyMeasurement(prev => ({ ...prev, [name]: Number(value) }))
    }

    const handleEditClick = () => {
        setYourSize();
    }

    const objectMap = (obj, fn) =>
        Object.fromEntries(
            Object.entries(obj).map(
                ([k, v], i) => [k, fn(v, k, i)]
            )
        )

    const convertCmToInc = (value) => {
        const valueInc = (value / 2.54).toLocaleString(undefined, { maximumFractionDigits: 1 });
        return Number(valueInc);
    }
    const convertIncToCm = (value) => {
        const valueCm = (value * 2.54).toLocaleString(undefined, { maximumFractionDigits: 1 });
        return Number(valueCm);
    }

    useEffect(() => {
        if (cmToInc) {
            const value = objectMap(bodyMeasurement, v => { return convertCmToInc(v) })
            setBodyMeasurement(value)
            console.log("convertCmToInc", value);
        } else {
            const value = objectMap(bodyMeasurement, v => { return convertIncToCm(v) })
            setBodyMeasurement(value)
            console.log("convertCmToInc", value);
        }
    }, [cmToInc])

    const handleDoneClick = () => {
        let data;
        if (cmToInc) {
            data = { id: productId, user: bodyMeasurement }
        } else {
            const value = objectMap(bodyMeasurement, v => { return convertCmToInc(v) })
            data = { id: productId, user: value }
        }
        const res = SizeGuideService.checkSize(data);
        console.log('res', res, data);
        setResultSize(res.result);
        setYourSize(res.fitSize);
        if (res.fitSize === false) {
            setOpenSnackbar(true)
        }
    }

    return (
        <>
            <Button classes={{ root: classes.sizeGuidBtn, label: classes.buttonLabel }} endIcon={<ArrowForwardIcon />} onClick={handleOpen}>{yourSize ? `${yourSize} Size` : 'Size Guide'}</Button>

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Slide direction="left" in={open} mountOnEnter unmountOnExit>
                    <div className={classes.modalBody}>
                        <IconButton color="secondary" className={classes.closeIconButton} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                        {!yourSize ? <FormInputSize cmToInc={cmToInc} handleSwitchChange={handleSwitchChange} handleChangeValue={handleChangeValue} handleDoneClick={handleDoneClick} bodyMeasurement={bodyMeasurement} productSizeGuide={productSizeGuide} /> : <>
                            <ResultSize yourSize={yourSize} productName={productName} handleEditClick={handleEditClick} productSizeGuide={productSizeGuide} resultSize={resultSize} />
                        </>}
                        <DoneSnackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            autoHideDuration={2500}
                            open={openSnackbar}
                            onClose={() => setOpenSnackbar(false)}
                            message="Data invalid !!" />
                    </div>
                </Slide>
            </Modal >
        </>
    );
}

export default SizeGuide;