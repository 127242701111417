import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { makeStyles, Avatar } from '@material-ui/core';
import Header from './Header';
import NavigationMenu from './NavigationMenu';
import CategoriesMenu from './CategoriesMenu';
import AnnouceMenu from './AnnouceMenu';
import BackgroundMedia from './BackgroundMedia';
import ChangingRoomMenu from './ChangingRoomMenu';
import CouponsMenu from './CouponsMenu';
import CouponManager from '../services/coupon.manager';
import UserService from '../services/user.service';
import { CouponContext } from '../contexts/CouponContext';
import FilterMenu from './FilterMenu';
import LoginScreen from './LoginScreen';
import GoToFidelityMallMenu from './GoToFidelityMallMenu';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 'none',
    height: '100vh',
    paddingLeft: 0,
    paddingRight: 0,
  },
  icon247: {
    position: 'absolute',
    bottom: 20,
    left: 160,
    zIndex: 100,
    width: 23,
    height: 23,
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
  }
}));

function Layout(props) {
  const classes = useStyles();
  const [couponContext, setCouponContext] = useContext(CouponContext);
  const [open, setOpen] = useState(true);
  const [message, setMessage] = useState();

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogin = (data) => {
    const msg = UserService.addUser(data);
    setMessage(msg)
    if (msg) {
      setOpen(false);
    }
  }


  useEffect(() => {
    // console.log('message', message);
    return () => {
      setMessage('')
    }
  }, [message])

  //const flowxoWidgetKey = 'eyJ0aGVtZSI6IiM2N2MxOGUiLCJ3ZWIiOnsiYm90SWQiOiI2MTc3NjRkYzQ1NjVmNjAwN2NhY2Q3NzciLCJ0aGVtZSI6IiM2N2MxOGUifX0'
  const flowxoWidgetKey = 'eyJ0aGVtZSI6IiM2N2MxOGUiLCJ3ZWIiOnsiYm90SWQiOiI2MTdmOTVlMGJhYjQ3ODAwM2UwNWMxODYiLCJ0aGVtZSI6IiM2N2MxOGUifX0'; //100

  useEffect(() => {
    const user = UserService.getUser();
    if (user) {
      setOpen(false);
    }
  }, [])

  useEffect(() => {
    CouponManager.start(setCouponContext);
  }, []);

  useEffect(() => {
    // const host = "https://devwidget.astrafit.com"
    // const fjs = document.getElementsByTagName("script")[0];
    // document.astrafitSettings = { host: host, shopID: 3844, locale: "en" };
    // const script = document.createElement("script");
    // script.id = "astrafit-loader";
    // script.async = true;
    // script.src = host + "/js/loader.starter.js";
    // fjs.parentNode.insertBefore(script, fjs);

    const scriptFXO = document.createElement('script');
    scriptFXO.src = 'https://widget.flowxo.com/embed.js';
    scriptFXO.setAttribute('data-fxo-widget', flowxoWidgetKey);
    scriptFXO.type = 'text/javascript';
    document.body.appendChild(scriptFXO);
    return () => {
      // fjs.parentNode.removeChild(script);
      document.body.removeChild(scriptFXO);
    }
  }, []);
  return (
    <div>
      <Header />
      <NavigationMenu />
      <FilterMenu />
      <GoToFidelityMallMenu />
      <CategoriesMenu />
      <AnnouceMenu />
      <CouponsMenu />
      <BackgroundMedia />
      <Avatar alt="24/7" src="/images/247hr.png" className={classes.icon247} />
      {/* <ChangingRoomMenu /> */}
      <LoginScreen open={open} handleClose={handleClose} handleLogin={handleLogin} message={message} />
      {!open && <Container className={classes.container}>{props.children}</Container>}
    </div>
  );
}
export default Layout;