import React, { useEffect, useState, useRef } from 'react';
import { Slider, Fade, makeStyles } from '@material-ui/core';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import useOnClickOutside from '../hooks/useOnClickOutside'

const useStyles = makeStyles((theme) => ({
    root: {
        height: 72,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(1)
    },
    iconVolume: {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        borderRadius: '50%',
        padding: 0,
        border: 'none',
        color: '#fff',
        boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
        '& .MuiSvgIcon-root': {
            width: 26,
            height: 26,
        },
    },
    slider: {
        color: '#f8f9fa',
        marginBottom: 12,
    },
    thumb: {
        boxShadow: 'none !important; -webkit-box-shadow: none !important; -moz-box-shadow: none !important;',
        '&:after': {
            display: 'none',
        }
    },
}));

export default function VolumeSlider(props) {
    const { volume, handleVolumeChange } = props;
    const ref = useRef();
    const classes = useStyles();
    const [mute, setMute] = useState(false);
    const [open, setOpen] = useState(false);

    const handleChange = (event, newValue) => {
        handleVolumeChange(newValue)
    };

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    useOnClickOutside(ref, () => setOpen(false));

    useEffect(() => {
        console.log('Mute', mute, volume);
        if (mute) {
            handleVolumeChange(0)
        } else {
            handleVolumeChange(volume)
        }
    }, [mute])

    useEffect(() => {
        if (volume === 0) { setMute(true) }
        else { setMute(false) }
    }, [volume])

    return (
        <div className={classes.root} ref={ref}>
            <Fade in={open} >
                <Slider
                    value={volume}
                    orientation="vertical"
                    onChange={handleChange}
                    aria-labelledby="volume-slider"
                    min={0}
                    step={0.1}
                    max={1}
                    scale={(x) => x - 0.1}
                    classes={{ root: classes.slider, thumb: classes.thumb, active: classes.active }}
                /></Fade>
            {!mute ? <VolumeUp classes={{ root: classes.iconVolume }} onClick={handleClick} /> : <VolumeOffIcon classes={{ root: classes.iconVolume }} onClick={handleClick} />}
        </div>
    );
}