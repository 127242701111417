import { Emitter, EventName } from './emitter';
import CouponService from './coupon.service';
import Utils from './utils.service';

var couponManagerInstance;

const CouponManagerInstance = (setCouponContext) => {
  const start = () => {
    Emitter.on(EventName.StreetViewLocationChanged, (payload) => {
      handleLocationChanged(payload);
      setCouponContext(new Date());
    });
  };
  const stop = () => {
    Emitter.off(EventName.StreetViewLocationChanged);
  };

  const handleLocationChanged = (payload) => {
    const { panoId } = payload;

    let countLocationsCoupon = CouponService.getCountLocationTravellingCoupons();
    countLocationsCoupon.forEach((coupon) => {
      if (!coupon.requirement.metLocationIds) {
        coupon.requirement.metLocationIds = [];
      }
      coupon.requirement.metLocationIds.push(panoId);
      coupon.requirement.metLocationIds = coupon.requirement.metLocationIds.filter(Utils.distinct);
      if (coupon.requirement.metLocationIds.length >= coupon.requirement.totalLocations) {
        coupon.collected = true;
        CouponService.addNewCollectedCouponNotification(coupon.id);
      }
      CouponService.updateCoupon(coupon);
    });

    let specificLocationsCoupon = CouponService.getSpecificLocationTravellingCoupons();
    specificLocationsCoupon.forEach((coupon) => {
      if (!coupon.requirement.metLocationIds) {
        coupon.requirement.metLocationIds = [];
      }

      if (coupon.requirement.locations.findIndex((x) => x === panoId) > -1) {
        coupon.requirement.metLocationIds.push(panoId);
        coupon.requirement.metLocationIds = coupon.requirement.metLocationIds.filter(Utils.distinct);
        if (coupon.requirement.metLocationIds.length >= coupon.requirement.locations.length) {
          coupon.collected = true;
          CouponService.addNewCollectedCouponNotification(coupon.id);
        }
      }

      CouponService.updateCoupon(coupon);
    });
  };

  return { start: start, stop: stop };
};

const CouponManager = {
  start: (setCouponContext) => {
    if (!couponManagerInstance) {
      couponManagerInstance = CouponManagerInstance(setCouponContext);
    }
    couponManagerInstance.start();
  },
  stop: () => {
    couponManagerInstance.stop();
    couponManagerInstance = null;
  },
};

export default CouponManager;
