import { Box, Breadcrumbs, Grid, IconButton, Tooltip, makeStyles, Slide, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import AnnouceMenuContent from './AnnouceMenuContent';

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: 20,
    left: 15,
    zIndex: 100,
    position: 'absolute',
  },
  iconButton: {
    padding: 0,
  },
  iconButtonContainer: {
    margin: -10,
    borderRadius: '50%',
    padding: 10,
    background: 'radial-gradient(circle, rgba(0,0,0,0.25) 0%, rgba(255,255,255,0) 65%)',
  },
  icon: {
    color: '#fff',
    fontSize: '1em',
    cursor: 'pointer',
  },
}));

const AnnouceMenu = (props) => {
  const classes = useStyles();
  const [showMenuContent, setShowMenuContent] = useState(false);

  const handleBreadcumbClick = () => { };

  return (
    <Tooltip title={`Sales`} placement="top" arrow>
      <Box className={classes.root}>
        <div className={classes.iconButtonContainer}>
          <IconButton
            color="secondary"
            className={classes.iconButton}
            onClick={() => {
              setShowMenuContent(!showMenuContent);
            }}
          >
            <FontAwesomeIcon className={classes.icon} icon={faBullhorn} />
          </IconButton>
        </div>
        <Slide direction="up" in={showMenuContent} mountOnEnter unmountOnExit>
          <AnnouceMenuContent
            onCloseButtonClick={() => {
              setShowMenuContent(false);
            }}
          />
        </Slide>
      </Box>
    </Tooltip>
  );
};

export default AnnouceMenu;
