import { Box, Breadcrumbs, Grid, IconButton, Link, makeStyles, Slide, Modal } from '@material-ui/core';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonBooth } from '@fortawesome/free-solid-svg-icons';
import ChangingRoom from './ChangingRoom';

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: 20,
    left: 90,
    zIndex: 100,
    position: 'absolute',
  },
  iconButton: {
    padding: 0,
  },
  iconButtonContainer: {
    margin: -10,
    borderRadius: '50%',
    padding: 10,
    background: 'radial-gradient(circle, rgba(0,0,0,0.25) 0%, rgba(255,255,255,0) 65%)',
  },
  icon: {
    color: '#fff',
    fontSize: '1em',
    cursor: 'pointer',
  },
}));

const ChangingRoomMenu = (props) => {
  const classes = useStyles();
  const [openChangingRoomModal, setOpenChangingRoomModal] = useState(true);

  return (
    <>
      <Box className={classes.root}>
        <div className={classes.iconButtonContainer}>
          <IconButton
            color="secondary"
            className={classes.iconButton}
            onClick={() => {
              setOpenChangingRoomModal(!openChangingRoomModal);
            }}
          >
            <FontAwesomeIcon className={classes.icon} icon={faPersonBooth} />
          </IconButton>
        </div>
      </Box>
      <Modal
        open={openChangingRoomModal}
        onClose={() => setOpenChangingRoomModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <ChangingRoom />
      </Modal>
    </>
  );
};

export default ChangingRoomMenu;
