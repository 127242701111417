import { Box, makeStyles, Paper, Tab, Tabs, withStyles, Slider } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState, } from 'react';
import PropTypes from 'prop-types';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { ButtonBack, ButtonNext, CarouselProvider, Slider as CorouselSlider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const useTabPanelStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  const classes = useTabPanelStyles();

  return (
    <div className={classes.root} role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box>
          <div style={{ margin: 0 }}>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AntTabs = withStyles((theme) => ({
  root: {
    //borderBottom: '1px solid #e8e8e8',
    paddingLeft: theme.spacing(2),
  },
  indicator: {
    backgroundColor: 'transparent',
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 0,
    fontSize: 18,
    fontWeight: 700,
    // fontWeight: theme.typography.fontWeightRegular,
    marginRight: 0,
    '&:hover': {
      color: '#000',
      opacity: 1,
    },
    '&$selected': {
      color: '#000',
      // fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#000',
    },
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  selected: {
    textDecoration: 'underline',
  },
}))((props) => <Tab disableRipple {...props} />);

const TabSeperator = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 0,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: 0,
    '&:hover': {
      color: '#000',
      opacity: 1,
    },
    '&$selected': {
      color: '#000',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#000',
    },
    paddingLeft: 0,
    paddingRight: 0,
  },
  disabled: {
    color: '#000 !important',
  },
}))((props) => <Tab disableRipple disabled {...props} label="●" value={-1} />);

const useHotsSectionStyles = makeStyles((theme) => ({
  root: {
    // paddingTop: theme.spacing(3),
  },
  header: {
    fontSize: 18,
    fontWeight: 700,
  },
  carouselProvider: {
    width: '830px',
    position: 'relative',
    display: 'flex',
  },
  carouselButton: {
    position: 'absolute',
    zIndex: 100,
    top: 90,
    width: 25,
    height: 25,
    borderRadius: '50%',
    border: 0,
    backgroundColor: '#333',
    opacity: '0.3',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.back': {
      left: -5,
    },
    '&.next': {
      right: -5,
    },
  },
  hotItem: {
    padding: theme.spacing(0.5),
    cursor: 'pointer',
    fontSize: 12,
    '& p': {
      marginBottom: 0,
    },
    '& img': {
      width: '100%',
      border: '2px solid #FFF',
    },
    '& .price': {
      fontSize: 16,
      fontWeight: 700,
      fontFamily: '"Roboto Condensed", sans-serif',
    },
  },
  priceWithSale: {
    color: '#979797',
    fontSize: 14,
    textDecoration: 'line-through',
    paddingRight: theme.spacing(0.5),
  },
  salePrice: {
    color: '#ee1f3b',
    paddingRight: theme.spacing(0.5),
  },
}));

const HotsSection = (props) => {
  const classes = useHotsSectionStyles();
  const hotItems = [
    {
      image: '/images/sample-product3/black-milwaukee-state-1.jpg',
      name: 'Regular Graphic T Shirt',
      price: 29.99,
      salePrice: 14.99,
    },
    {
      image: '/images/sample-product/sage-1.jpg',
      name: 'Regular Graphic T Shirt',
      price: 29.99,
      salePrice: 14.99,
    },
    {
      image: '/images/sample-product/dusty-pink-1.jpg',
      name: 'Regular Graphic T Shirt',
      price: 29.99,
      salePrice: 14.99,
    },
    {
      image: '/images/sample-product/linen-taupe-2.jpg',
      name: 'Regular Graphic T Shirt',
      price: 29.99,
      salePrice: 14.99,
    },
    {
      image: '/images/sample-product3/black-you-re-welcome-1.jpg',
      name: 'Regular Graphic T Shirt',
      price: 29.99,
      salePrice: 14.99,
    },
    {
      image: '/images/sample-product3/white-cincinnati-1.jpg',
      name: 'Regular Graphic T Shirt',
      price: 29.99,
      salePrice: 14.99,
    },
    {
      image: '/images/sample-product3/black-st-moritz-1.jpg',
      name: 'Regular Graphic T Shirt',
      price: 29.99,
      salePrice: 14.99,
    },
    {
      image: '/images/sample-product3/silver-marle-michigan-1.jpg',
      name: 'Regular Graphic T Shirt',
      price: 29.99,
      salePrice: 14.99,
    },
    {
      image: '/images/sample-product3/washed-pine-teal-boston-state-1.jpg',
      name: 'Regular Graphic T Shirt',
      price: 29.99,
      salePrice: 14.99,
    },
  ];
  return (
    <div className={classes.root}>
      {/* <span className={classes.header}>What's Hot Right Now</span> */}
      <CarouselProvider
        visibleSlides={7}
        naturalSlideWidth={50}
        naturalSlideHeight={75}
        totalSlides={hotItems ? hotItems.length : 7}
        isIntrinsicHeight={true}
        className={clsx(classes.carouselProvider)}
      >
        <ButtonBack className={clsx(classes.carouselButton, 'back')}>
          <NavigateBeforeIcon />
        </ButtonBack>
        <CorouselSlider className="flex-grow-1">
          {hotItems &&
            hotItems.map((hotItem, index) => {
              return (
                <Slide index={index} key={`hotItem${index}`} className={clsx(classes.hotItem)}>
                  <img src={hotItem.image} />
                  <p>{hotItem.name}</p>
                  <p className="price">
                    <span className={classes.priceWithSale}>${hotItem.price}</span>
                    <span className={classes.salePrice}>${hotItem.salePrice}</span>
                  </p>
                </Slide>
              );
            })}
        </CorouselSlider>
        <ButtonNext className={clsx(classes.carouselButton, 'next')}>
          <NavigateNextIcon />
        </ButtonNext>
      </CarouselProvider>
    </div>
  );
};
const ShopTheLookSection = (props) => {
  const classes = useHotsSectionStyles();
  const hotItems = [
    {
      image: '/images/sample-product-look/hat_01.jpg',
      name: 'Special Edition Dad Hat',
      price: 19.99,
      salePrice: 13.99,
    },
    {
      image: '/images/sample-product-look/pants_02.jpg',
      name: 'Loose Fit Pant - Yd Check',
      price: 59.99,
      salePrice: 41.99,
    },
    {
      image: '/images/sample-product-look/pants_01.jpg',
      name: 'Oxford Trouser',
      price: 49.99,
      salePrice: 34.99,
    },
    {
      image: '/images/sample-product-look/shorts_01.jpg',
      name: 'Chino Short',
      price: 49.99,
      salePrice: 34.99,
    },
    {
      image: '/images/sample-product-look/shorts_02.jpg',
      name: 'Slim Cut Denim Short',
      price: 39.95,
      salePrice: 27.96,
    },
    {
      image: '/images/sample-product-look/shoes_01.jpg',
      name: 'Double Buckle Sandal',
      price: 29.99,
      salePrice: 20.00,
    },
    {
      image: '/images/sample-product-look/shoes_02.jpg',
      name: 'Mens Strap Sandal',
      price: 29.99,
      salePrice: 20.00,
    },
    {
      image: '/images/sample-product-look/bag_01.jpg',
      name: 'Tote Bag',
      price: 29.95,
      salePrice: 20.96,
    },
    {
      image: '/images/sample-product-look/bag_02.jpg',
      name: 'Tote Bag',
      price: 29.95,
      salePrice: 20.96,
    },
  ];
  return (
    <div className={classes.root}>
      {/* <span className={classes.header}>Shop the look</span> */}
      <CarouselProvider
        visibleSlides={7}
        naturalSlideWidth={50}
        naturalSlideHeight={75}
        totalSlides={hotItems ? hotItems.length : 7}
        isIntrinsicHeight={true}
        className={clsx(classes.carouselProvider)}
      >
        <ButtonBack className={clsx(classes.carouselButton, 'back')}>
          <NavigateBeforeIcon />
        </ButtonBack>
        <CorouselSlider className="flex-grow-1">
          {hotItems &&
            hotItems.map((hotItem, index) => {
              return (
                <Slide index={index} key={`hotItem${index}`} className={clsx(classes.hotItem)}>
                  <img src={hotItem.image} />
                  <p>{hotItem.name}</p>
                  <p className="price">
                    <span className={classes.priceWithSale}>${hotItem.price}</span>
                    <span className={classes.salePrice}>${hotItem.salePrice}</span>
                  </p>
                </Slide>
              );
            })}
        </CorouselSlider>
        <ButtonNext className={clsx(classes.carouselButton, 'next')}>
          <NavigateNextIcon />
        </ButtonNext>
      </CarouselProvider>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minWidth: 850,
    boxShadow: 'none; -webkit-box-shadow: none; -moz-box-shadow: none;',
  },
}));

const SuggestionTab = (props,) => {
  const classes = useStyles();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };

  return (
    <Paper className={classes.root}>
      <AntTabs value={currentTabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
        <AntTab label="What's Hot Right Now" value={0} />
        <TabSeperator />
        <AntTab label="Shop The Look" value={1} />
      </AntTabs>
      <TabPanel value={currentTabIndex} index={0}>
        <HotsSection />
      </TabPanel>
      <TabPanel value={currentTabIndex} index={1}>
        <ShopTheLookSection />
      </TabPanel>
    </Paper>
  );
};

export default SuggestionTab;
