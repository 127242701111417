import React, { useState, useContext } from "react";
import { IconButton, Popover, TextField, InputAdornment, makeStyles, Typography, Grid, Tooltip, InputLabel, withStyles } from '@material-ui/core';
import RulerIcon from '@material-ui/icons/StraightenRounded';
import HelpIcon from '@material-ui/icons/HelpOutlineRounded';
import { ChangingRoomContext } from '../contexts/ChangingRoomContext';

const useStyles = makeStyles((theme) => ({
    button: {
        background: '#fff',
        color: '#000',
        boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
        '&:hover': {
            background: '#fff'
        }
    },
    paper: {
        marginTop: '0.5em',
        padding: '1em'
    },
    textField: {
        display: 'block',
        width: 110
    },
    helpBtn: {
        background: 'transparent',
        color: '#c4c4c4',
        height: '1em',
        display: 'block',
        padding: 0,
        marginLeft: 1
    },
    label: {
        width: 60,
        margin: 0,
        color: '#000'
    }
}))

const HelpTooltip = withStyles((theme) => ({
    tooltip: {
        fontSize: 13,
        fontWeight: 'normal',
        maxWidth: 250,
    },
}))(Tooltip);

const ChangingRoomMeasurement = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [changingRoomContext, setChangingRoomContext] = useContext(ChangingRoomContext);
    const [bodyMeasurement, setBodyMeasurement] = useState({
        height: 180,
        chest: 100,
        waist: 82,
        hip: 99,
        weight: 70,
        bodySize: 'M',
    })
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        console.log(bodyMeasurement);
        setChangingRoomContext({ ...changingRoomContext, body: bodyMeasurement });
    };
    const handleChangeValue = (name, value) => {
        setBodyMeasurement(prev => ({ ...prev, [name]: Number(value) }))
    }

    const open = Boolean(anchorEl);
    const howToMeasure = {
        chest: 'Measure the circumference at the fullest part of your chest. Keep the tape level.',
        waist: 'Measure the circumference at the narrowest part of your waistline. Keep the tape level.',
        hip: 'Measure the circumference at the fullest part of your hips. Keep the tape level.'
    }
    return <>
        <IconButton
            {...props}
            classes={{ root: classes.button }}
            onClick={handleClick}
        >
            <RulerIcon />
        </IconButton>
        <Popover
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            classes={{ paper: classes.paper }}
        >
            <Typography variant="body1" gutterBottom><strong>Measurement</strong></Typography>
            <Grid container direction="column" spacing={1}>
                <Grid item container alignItems="center">
                    <InputLabel htmlFor="input-height" classes={{ root: classes.label }}>Height</InputLabel>
                    <TextField
                        type="number"
                        // label="Height"
                        id="input-height"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                        }}
                        variant="outlined"
                        size="small"
                        classes={{ root: classes.textField }}
                        value={bodyMeasurement.height || ""}
                        onChange={(e) => handleChangeValue('height', e.target.value)}
                    />

                </Grid>
                <Grid item container alignItems="center">
                    <InputLabel htmlFor="input-chest" classes={{ root: classes.label }}>Chest</InputLabel>
                    <TextField
                        type="number"
                        id="input-chest"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                        }}
                        variant="outlined"
                        size="small"
                        classes={{ root: classes.textField }}
                        value={bodyMeasurement.chest || ""}
                        onChange={(e) => handleChangeValue('chest', e.target.value)}
                    />
                    <HelpTooltip title={howToMeasure.chest} placement="right" arrow>
                        <IconButton classes={{ root: classes.helpBtn }}>
                            <HelpIcon />
                        </IconButton>
                    </HelpTooltip>
                </Grid>
                <Grid item container alignItems="center">
                    <InputLabel htmlFor="input-nature-waist" classes={{ root: classes.label }}>Nature Waist</InputLabel>
                    <TextField
                        type="number"
                        //label="Nature Waist"
                        id="input-nature-waist"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                        }}
                        variant="outlined"
                        size="small"
                        classes={{ root: classes.textField }}
                        value={bodyMeasurement.waist || ""}
                        onChange={(e) => handleChangeValue('waist', e.target.value)}
                    />

                    <HelpTooltip title={howToMeasure.waist} placement="right" arrow>
                        <IconButton classes={{ root: classes.helpBtn }}>
                            <HelpIcon />
                        </IconButton>
                    </HelpTooltip>
                </Grid>
                <Grid item container alignItems="center">
                    <InputLabel htmlFor="input-hip" classes={{ root: classes.label }}>Hip</InputLabel>
                    <TextField
                        type="number"
                        //label="Hip"
                        id="input-hip"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                        }}
                        variant="outlined"
                        size="small"
                        classes={{ root: classes.textField }}
                        value={bodyMeasurement.hip || ""}
                        onChange={(e) => handleChangeValue('hip', e.target.value)}
                    />

                    <HelpTooltip title={howToMeasure.hip} placement="right" arrow>
                        <IconButton classes={{ root: classes.helpBtn }}>
                            <HelpIcon />
                        </IconButton>
                    </HelpTooltip>
                </Grid>
                <Grid item container alignItems="center">
                    <InputLabel htmlFor="input-weight" classes={{ root: classes.label }}>Weight</InputLabel>
                    <TextField
                        type="number"
                        id="input-weight"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                        }}
                        variant="outlined"
                        size="small"
                        classes={{ root: classes.textField }}
                        value={bodyMeasurement.weight || ""}
                        onChange={(e) => handleChangeValue('weight', e.target.value)}
                    />
                </Grid>
            </Grid>
        </Popover>
    </>
}
export default ChangingRoomMeasurement;