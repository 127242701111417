import { Box, Grid, makeStyles, Tooltip } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore } from '@fortawesome/free-solid-svg-icons/faStore';

const useStyles = makeStyles((theme) => ({
  root: {
    top: 55,
    left: 0,
    zIndex: 100,
    position: 'absolute',
  },
  menusContainer: {
    backgroundColor: '#552778',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
  },
  menuItem: {
    padding: theme.spacing(1),
    color: '#FFF',
    '& a': {
      color: '#FFF',
    },
    textAlign: 'center',
    position: 'relative',
    cursor: 'pointer',
  },
}));

const GoToFidelityMallMenu = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, classes.menusContainer)}>
      <Grid container direction="column">
        <Grid item className={classes.menuItem}>
          <Tooltip title="Go to Fidelity Mall" placement="right" arrow>
            <span>
              <FontAwesomeIcon
                icon={faStore}
                onClick={() => {
                  window.location.href = process.env.REACT_APP_FIDELITY_MALL_URL;;
                }}
              />
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GoToFidelityMallMenu;
