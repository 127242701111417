import React, { Component, useEffect, useRef, useState, forwardRef, useContext } from 'react';
import { GoogleMap, LoadScript, Marker, StreetViewPanorama, useJsApiLoader } from '@react-google-maps/api';
import { Button, Modal, makeStyles, Fade, Backdrop } from '@material-ui/core';
import PoiButton from './POI-Button';
import PoiService from '../services/poi.service';
import PoiPriceTag from './POI-PriceTag';
import PoiPortal from './POI-Portal';
import PoiChangingRoom from './POI-ChangingRoom';
import PoiCoupon from './POI-Coupon';
import { useHistory, useLocation, } from 'react-router-dom';
import useQueryString from '../hooks/useQueryString';
import VirtualLocationMockDatas from '../mockdatas/virtual-locations';
import GetPanoramaMockDatas from '../mockdatas/panoramas';
import { PanoramaContext } from '../contexts/PanoramaContext';
import { BackgroundMediaContext } from '../contexts/BackgroundMediaContext';
import PoiVideo from './POI-Video';
import ChangingRoom from './ChangingRoom';
import PostersContainer from './PostersContainer';
import { Emitter, EventName } from '../services/emitter';
import KeyBoardNavigationControl from './KeyBoardNavigationControl';
import PoiSelection from './POI-Selection';
import clsx from 'clsx';
import { ScaleLoader } from 'react-spinners';
import PoiCounter from './POI-Counter';
import CouponNewsModal from './CouponNewsModal';
import AddProductModal from './AddProductModal';
import { classicNameResolver } from 'typescript';
import PoiChangingRoomPortal from './POI-ChangingRoomPortal';
import EditProductModal from './EditProductModal';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
}

export function Main360view(props) {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyCNnoJXIcSjp3Ctw77pk3HERbsP8RvnmPc', // ,
    // ...otherOptions
  });

  let pathname = usePathname();
  let query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const [panoramaContext, setPanoramaContext] = useContext(PanoramaContext);
  const [backgroundMediaContext, setBackgroundMediaContext] = useContext(BackgroundMediaContext);

  const [panorama, setPanorama] = useState();
  const streetViewPOILayerRef = useRef(null);
  const streetViewPOILayerInnerRef = useRef(null);
  const panoramaViewPropsRef = useRef(null);
  const [poiList, setPoiList] = useState([]);
  const [posterList, setPosterList] = useState([]);
  const poisRef = useRef([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const virtualLocations = VirtualLocationMockDatas;
  const [customPanoramas, setCustomPanoramas] = useState([]);
  const [panoramaLinks, setPanoramaLinks] = useState([]);
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const fromShareLinkRef = useRef();
  const [pov, setPov] = useState();

  // const [firstPano, setFirstPano] = useQueryString('id');
  const [currentPanoId, setCurrentPanoId] = useQueryString('id'); //useState(''); //
  const [center, setCenter] = useState({
    lat: 18.8080439,
    lng: 99.0180237,
  });

  const [currentZoom, setCurrentZoom] = useState(0)
  const [filter, setFilter] = useState([])
  const poiListRef = useRef([]);

  const places = [
    {
      id: 1,
      bound: {
        north: 18.808177890149327,
        south: 18.80797,
        west: 99.01766214522307,
        east: 99.01824686676615,
      },
      entryPanoId: '3IBg3VUJjoFTOOQqzlFPDg',
    },
  ];

  const containerStyle = {
    width: '100%',
    height: '100vh',
  };

  // const pov = {
  //   heading: 146.67,
  //   pitch: -3.09,
  // };

  const googleMapOptions = {
    restriction: {
      latLngBounds: {
        north: 18.808177890149327,
        south: 18.80797,
        west: 99.01766214522307,
        east: 99.01824686676615,
      },
      strictBounds: true,
    },
  };

  const panoramaOptions = {
    scrollwheel: true,
    addressControl: false,
    clickToGo: false,
    disableDefaultUI: true,
    linksControl: true,
    panControl: false,
    zoomControl: false,
    streetViewControl: false,
    enableCloseButton: false,
    zoom: currentZoom,
  };
  const queryStringToNubmer = (key) => {
    const value = Number(query.get(key));
    return value;
  }
  useEffect(() => {
    const heading = queryStringToNubmer('heading');
    const pitch = queryStringToNubmer('pitch');
    const zoom = queryStringToNubmer('zoom');
    console.log("asdasdasds", heading, pitch, zoom);
    if (heading || pitch || zoom) {
      setCurrentZoom(zoom)
      setPov({ heading: heading, pitch: pitch, })
      fromShareLinkRef.current = { heading: heading, pitch: pitch, zoom: zoom, first: true };
    } else {
      fromShareLinkRef.current = { first: false };
    }
  }, [])

  useEffect(() => {
    let panoId = query.get('id');
    if (!panoId) {
      panoId = virtualLocations[0].panoId;
    }
    // setFirstPano(panoId);
    setCurrentPanoId(panoId);
    setPanoramaContext({ panoId: panoId });
    console.log('location-change: ', panoId);
  }, [location]);

  useEffect(() => {
    poisRef.current = poisRef.current.slice(0, poiList.length);
    updatePOIsPosition();
  }, [poiList]);

  useEffect(() => {
    console.log('currentPanoId', currentPanoId);
    if (currentPanoId) {
      let virtualLocation = virtualLocations.find((x) => x.panoId === currentPanoId);
      if (virtualLocation) {
        console.log('virtualLocation.backgroundMediaUrl: ', virtualLocation.backgroundMediaUrl);
        if (virtualLocation.pois) {
          // console.log("virtualLocation.pois", virtualLocation.pois);
          let VPoi = virtualLocation.pois;
          const localPois = PoiService.getPOIByPanoId(currentPanoId);
          if (localPois) {
            // localPois.forEach(x => {
            //   if (VPoi.every(p => JSON.stringify(p) !== JSON.stringify(x))) {
            //     VPoi.push(x);
            //   }
            // })
            VPoi = [...VPoi, ...localPois]
          }
          const coupons = JSON.parse(localStorage.getItem('cp'))
          const collectedCoupons = coupons.filter(x => x.requirement.type === 'POICoupon' && x.collected === true)
          const couponsId = collectedCoupons.map(x => x.id)
          const newPoiList = VPoi.filter(x => couponsId.indexOf(x.couponId) === -1)
          setPoiList(newPoiList)
          poiListRef.current = newPoiList

          filterPoiList()
        }
        // setPoiList(virtualLocation.pois);

        setPosterList(virtualLocation.posters);
        setBackgroundMediaContext({ url: virtualLocation.backgroundMediaUrl });
      } else {
        setPoiList([]);
        setPosterList([]);
        setBackgroundMediaContext({});
      }
      setPanoramaContext({ panoId: currentPanoId });
      // if (currentPanoId === 'men-changing-room') {
      //   handleOpenModal(<ChangingRoom />);
      // }
      setOpenModal(false);
    }
  }, [currentPanoId]);

  const handleGoogleMapOnLoad = React.useCallback(function callback(map) {
    let customPanoramas = GetPanoramaMockDatas(window.google);
    setCustomPanoramas(customPanoramas);

    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
  }, []);

  function handleStreetViewOnPovChanged() {
    if (panorama && streetViewPOILayerRef && streetViewPOILayerRef.current) {
      let panoramaViewProps;
      if (fromShareLinkRef.current.first) {
        panoramaViewProps = {
          zoom: fromShareLinkRef.current.zoom,
          heading: fromShareLinkRef.current.heading,
          pitch: fromShareLinkRef.current.pitch,
          width: streetViewPOILayerRef.current.offsetWidth,
          height: streetViewPOILayerRef.current.offsetHeight,
        };
        fromShareLinkRef.current.first = false;
      } else {
        panoramaViewProps = {
          zoom: currentZoom,
          heading: panorama.getPov().heading,
          pitch: panorama.getPov().pitch,
          width: streetViewPOILayerRef.current.offsetWidth,
          height: streetViewPOILayerRef.current.offsetHeight,
        };
      }
      panoramaViewPropsRef.current = panoramaViewProps;
      updatePOIsPosition();
      Emitter.emit(EventName.StreetViewOnPovChanged, { panoramaViewProps: panoramaViewPropsRef.current });
    }
  };

  function handleStreetViewOnZoomChanged() {
    const zoomChanged = this.getZoom();
    if (zoomChanged !== currentZoom) {
      setCurrentZoom(zoomChanged);
      if (panorama && streetViewPOILayerRef && streetViewPOILayerRef.current) {
        let panoramaViewProps = {
          zoom: zoomChanged,
          heading: panorama.getPov().heading,
          pitch: panorama.getPov().pitch,
          width: streetViewPOILayerRef.current.offsetWidth,
          height: streetViewPOILayerRef.current.offsetHeight,
        };
        panoramaViewPropsRef.current = panoramaViewProps;
        updatePOIsPosition();
        Emitter.emit(EventName.StreetViewOnPovChanged, { panoramaViewProps: panoramaViewPropsRef.current });
      }
    }
  }

  const showLoadingPanoramaScreen = (panoramaUrl) => {
    let isPanoLoaded = false,
      timeout = false;
    function preloadImage() {
      let img = new Image();
      img.onload = () => {
        console.log('nooktestgetimage,success', panoramaUrl);
        isPanoLoaded = true;
        if (timeout) {
          setShowLoadingScreen(false);
        }
      };
      img.onerror = () => {
        console.log('nooktestgetimage,error', panoramaUrl);
        isPanoLoaded = true;
        if (timeout) {
          setShowLoadingScreen(false);
        }
      };
      img.src = panoramaUrl;
    }

    preloadImage();

    setShowLoadingScreen(true);
    setTimeout(() => {
      timeout = true;
      if (isPanoLoaded) {
        setShowLoadingScreen(false);
      }
    }, 500);
  };

  const handleStreetViewOnLoaded = (panorama) => {
    console.log('handleStreetViewOnLoaded', panorama);

    panorama.registerPanoProvider((pano) => {
      let customPano = customPanoramas.find((x) => x.panoId === pano);
      if (customPano) {
        setPanoramaLinks(customPano.panoramaData.links);
        let customTiles = {
          ...customPano.panoramaData.tiles,
          getTileUrl: function (pano, zoom, tileX, tileY) {
            showLoadingPanoramaScreen(customPano.imageUrl);
            return customPano.imageUrl;
          },
        };

        let panoramaData = { ...customPano.panoramaData, tiles: customTiles };
        return panoramaData;
      }
      return null;
    });
    // Add a link to our custom panorama from outside the Google Sydney office.
    panorama.addListener('links_changed', () => {
      if (panorama.getPano() === '3IBg3VUJjoFTOOQqzlFPDg') {
        panorama.getLinks().push({
          description: 'testtestetsetset',
          heading: 1.73,
          pano: 'entrance',
        });
      }
    });
    setPanorama(panorama);
  };

  const handleStreetViewOnPositionChanged = () => {
    if (panorama && panorama.pano && panorama.pano !== currentPanoId) {
      setCurrentPanoId(panorama.pano);
      Emitter.emit(EventName.StreetViewLocationChanged, { panoId: panorama.pano });
      history.replace({
        pathname: pathname,
        search: `?id=${panorama.pano}`,
      });
    }
  };

  const handleStreetViewOnUnmount = (panorama) => {
    console.log('handleStreetViewOnUnmount', panorama);
  };

  const updatePOIsPosition = () => {
    if (poiList && poisRef && poisRef.current && panoramaViewPropsRef && panoramaViewPropsRef.current) {
      let panoramaViewProps = panoramaViewPropsRef.current;
      for (var i = 0; i < poiList.length; i++) {
        // Set CSS Position of POI Icon Element
        var poi = poiList[i];
        var poiElement = poisRef.current[i];

        if (poiElement) {
          var modelHeading = parseFloat(poi.poiHeading);
          var modelPitch = parseFloat(poi.poiPitch);
          var t = PoiService.moveFromHeadingAndPitch(
            PoiService.deg2rad(panoramaViewProps.heading),
            PoiService.deg2rad(panoramaViewProps.pitch),
            modelHeading,
            modelPitch,
            panoramaViewProps.width,
            panoramaViewProps.height,
            panoramaViewProps.zoom
          );

          if (t.top === undefined || t.left === undefined || poi.zoomLevel && panoramaViewProps.zoom < poi.zoomLevel) {
            // Hide if this POI Icon is out of panorama viewport.
            poiElement.style.display = 'none';
          } else {
            poiElement.style.display = 'block';
            poiElement.style.top = t.top + 'px';
            poiElement.style.left = t.left + 'px';
          }
        }
      }
    }
  };

  useEffect(() => {
    Emitter.emit(EventName.StreetViewOnPovChanged, { panoramaViewProps: panoramaViewPropsRef.current });
  }, [posterList]);

  const addPoi = (poiData) => {
    PoiService.addPOIPriceTag(poiData)
    setPoiList([...poiList, poiData]);
    // poiListRef.current = [...poiList, poiData];
  }

  const removePoi = (poiId) => {
    PoiService.removePOIPriceTag(poiId)
    const newPoi = poiList.filter(x => x.poiId !== poiId)
    // console.log('newPoi', newPoi);
    setPoiList(newPoi);
    // poiListRef.current = newPoi;
  }

  const movePoi = (poiData, x, y) => {
    if (panoramaViewPropsRef && panoramaViewPropsRef.current) {
      let hpCoords = PoiService.getHeadingAndPitch(
        panoramaViewPropsRef.current.heading,
        panoramaViewPropsRef.current.pitch,
        panoramaViewPropsRef.current.width,
        panoramaViewPropsRef.current.height,
        panoramaViewPropsRef.current.zoom,
        x,
        y
      );
      let poiMove = poiData;
      console.log('movePoi!!', poiData, hpCoords);
      poiMove.poiHeading = hpCoords.heading;
      poiMove.poiPitch = hpCoords.pitch;


      // const newPoi = poiList.filter(x => x.poiId !== poiMove.poiId);
      // poiListRef.current = [...newPoi, poiMove];
      PoiService.updatePOIPriceTag(poiMove);

      let newPoi = poiList.map(x => {
        if (x.poiId === poiData.poiId) {
          x.poiHeading = hpCoords.heading;
          x.poiPitch = hpCoords.pitch;
          return x;
        } else return x;
      })
      setPoiList(newPoi);
    }
  }

  const editPoi = (poiData) => {
    PoiService.updatePOIPriceTag(poiData);
    const newPoi = poiList.filter(x => x.poiId !== poiData.poiId);
    setPoiList([...newPoi, poiData]);
    poiListRef.current = [...newPoi, poiData];
  }

  const openEditPoi = (poiId) => {
    handleOpenModal(<EditProductModal handleCloseModal={handleCloseModal} poiId={poiId} editPoi={editPoi} />)
  }

  const handleOnMouseUp = (e) => {
    e.preventDefault();
    console.log('handleOnMouseUp', { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY }, e.button, panoramaViewPropsRef);
    if (e.button === 2 && panoramaViewPropsRef && panoramaViewPropsRef.current) {
      let hpCoords = PoiService.getHeadingAndPitch(
        panoramaViewPropsRef.current.heading,
        panoramaViewPropsRef.current.pitch,
        panoramaViewPropsRef.current.width,
        panoramaViewPropsRef.current.height,
        panoramaViewPropsRef.current.zoom,
        e.nativeEvent.offsetX,
        e.nativeEvent.offsetY
      );
      console.log("right Click", hpCoords, pathname);
      if (pathname === '/admin') {
        handleOpenModal(<AddProductModal handleCloseModal={handleCloseModal} hpCoords={hpCoords} addPoi={addPoi} currentPanoId={currentPanoId} />)
      }

      // setPoiList([
      //   ...poiList,
      //   {
      //     type: 'price',
      //     poiHeading: hpCoords.heading,
      //     poiPitch: hpCoords.pitch,
      //   },
      // ]);
    }
  };

  const handleFilterChanged = (data) => {
    const filters = data.filters;
    setFilter(filters);
  }

  useEffect(() => {
    Emitter.on(EventName.FilterChanged, handleFilterChanged);
    return () => {
      Emitter.off(EventName.FilterChanged, handleFilterChanged);
    };
  }, []);

  const handleCollectCoupon = (id) => {
    const newPoiList = poiList.filter(x => x.couponId !== id);
    const tmpPois = poiListRef.current;
    const newAllPoiList = tmpPois.filter(x => x.couponId !== id);
    poiListRef.current = newAllPoiList;
    setPoiList(newPoiList);
    setOpenModal(false);
    // setModalBody(<img src='/images/coin-flip.gif' width={150} />)
    // startSound();
    // setTimeout(() => {
    //   setOpenModal(false);
    // }, 1500);
  }

  const handleOpenModal = (body) => {
    setModalBody(body);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const filterPoiList = () => {
    if (filter && filter.length > 0) {
      const tmpPois = poiListRef.current;
      const notPrice = tmpPois.filter(x => x.type !== 'price');
      const filteredItems = tmpPois.filter(x => Object.keys(x).some(a => x[a] !== false && filter.includes(a)));
      setPoiList([...notPrice, ...filteredItems]);
    } else {
      setPoiList(poiListRef.current);
    }
  }

  useEffect(() => {
    filterPoiList()
  }, [filter])

  return (
    <>
      {isLoaded ? (
        <div onMouseUp={handleOnMouseUp} onContextMenu={e => e.preventDefault()}>
          {/* <LoadScript googleMapsApiKey="AIzaSyCNnoJXIcSjp3Ctw77pk3HERbsP8RvnmPc"> */}
          <GoogleMap mapContainerStyle={containerStyle} zoom={10} center={center} onLoad={handleGoogleMapOnLoad} options={googleMapOptions}>
            <KeyBoardNavigationControl panoramaLinks={panoramaLinks}>
              <StreetViewPanorama
                id="panorama"
                //position={center}
                visible={true}
                pov={pov}
                options={panoramaOptions}
                onPovChanged={handleStreetViewOnPovChanged}
                onLoad={handleStreetViewOnLoaded}
                onPositionChanged={handleStreetViewOnPositionChanged}
                onUnmount={handleStreetViewOnUnmount}
                // pano={firstPano}
                pano={currentPanoId}
                zoom={currentZoom}
                onZoomChanged={handleStreetViewOnZoomChanged}
              />
            </KeyBoardNavigationControl>

            <div ref={streetViewPOILayerRef} className="streetViewPOILayer">
              <div ref={streetViewPOILayerInnerRef} className="streetViewPOILayerInner">
                {poiList &&
                  poiList.length > 0 &&
                  poiList.map((poi, index) => {
                    if (poi.type === 'button') {
                      return <PoiButton key={index} handleOpenModal={handleOpenModal} index={index} ref={(el) => (poisRef.current[index] = el)} />;
                    } else if (poi.type === 'portal') {
                      return <PoiPortal key={index} handleOpenModal={handleOpenModal} index={index} poi={poi} ref={(el) => (poisRef.current[index] = el)} />;
                    } else if (poi.type === 'changingroomportal') {
                      return <PoiChangingRoomPortal key={index} handleOpenModal={handleOpenModal} index={index} poi={poi} ref={(el) => (poisRef.current[index] = el)} />;
                    } else if (poi.type === 'video') {
                      return <PoiVideo key={index} handleOpenModal={handleOpenModal} index={index} poi={poi} ref={(el) => (poisRef.current[index] = el)} />;
                    } else if (poi.type === 'selection') {
                      return (
                        <PoiSelection
                          key={index}
                          handleOpenModal={handleOpenModal}
                          handleCloseModal={handleCloseModal}
                          index={index}
                          poi={poi}
                          ref={(el) => (poisRef.current[index] = el)}
                        />
                      );
                    } else if (poi.type === 'counter') {
                      return (
                        <PoiCounter
                          key={index}
                          handleOpenModal={handleOpenModal}
                          handleCloseModal={handleCloseModal}
                          index={index}
                          poi={poi}
                          ref={(el) => (poisRef.current[index] = el)}
                        />
                      );
                    } else if (poi.type === 'coupon') {
                      return <PoiCoupon key={index} handleOpenModal={handleOpenModal} handleCollectCoupon={handleCollectCoupon} index={index} poi={poi} ref={(el) => (poisRef.current[index] = el)} />;
                    } else if (poi.type === 'changingroom') {
                      return (
                        <PoiChangingRoom key={index} handleOpenModal={handleOpenModal} index={index} poi={poi} ref={(el) => (poisRef.current[index] = el)} />
                      );
                    } else {
                      return (
                        <PoiPriceTag
                          key={index}
                          handleOpenModal={handleOpenModal}
                          handleCloseModal={handleCloseModal}
                          index={index}
                          poi={poi}
                          ref={(el) => (poisRef.current[index] = el)}
                          pathname={pathname}
                          removePoi={removePoi}
                          movePoi={movePoi}
                          openEditPoi={openEditPoi}
                        />
                      );
                    }
                  })}
              </div>
              {posterList && posterList.length > 0 && <PostersContainer posterList={posterList} currentPanoId={currentPanoId} />}
            </div>
          </GoogleMap>
          {/* </LoadScript> */}

          {!showLoadingScreen && <CouponNewsModal />}
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            {modalBody}
          </Modal>
          {pathname === '/admin' && <span className="adminText">Admin</span>}
        </div>
      ) : (
        ''
      )}

      <Fade in={showLoadingScreen} timeout={{ enter: 0, exit: 400 }}>
        <div className={clsx('loadingScreen')}>
          <ScaleLoader color="#ffffff" loading={true} height={30} width={4} radius={2} margin={3} />
        </div>
      </Fade>
    </>
  );
}
