import React, { useEffect, useState } from "react";
import { Fade, Grid, Divider, makeStyles, IconButton, Button } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIosRounded';
import SizeGuideService from "../services/size-guide.service";

const useStyles = makeStyles((theme) => ({
    headContent: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2, 0),
        borderBottom: '2px solid red'
    },
    yourSize: {
        color: '#fff;',
        backgroundColor: '#333',
        padding: theme.spacing(1, 3),
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    yourFits: {
        fontSize: 28,
        fontWeight: 700,
        color: '#26a65b',
    },
    sizeText: {
        fontSize: 11,
    },
    rateTextY: {
        color: '#26a65b',
        textAlign: 'center',
        display: 'block',
    },
    rateTextN: {
        color: '#ffa500',
        textAlign: 'center',
        display: 'block',
    }
}))

const ResultSize = (props) => {
    const classes = useStyles();
    const { yourSize, productName, handleEditClick, productSizeGuide, resultSize } = props
    const [fade, setFade] = useState(false)
    const [sizeKey, setSizeKey] = useState();

    useEffect(() => {
        if (yourSize) {
            setFade(true)
        }
        if (productSizeGuide) {
            const key = SizeGuideService.getSizeGuideKey(productSizeGuide[0]);
            console.log("sizeKey", key, resultSize);
            setSizeKey(key)
        }
    }, [])

    const getMessage = (size) => {
        if (size && size === yourSize) {
            return 'Fits well';
        } else { return 'Fits not so good'; }
    }

    return (<>
        <Fade in={fade}>
            <>
                <div className={classes.headContent}>
                    <h4>Your Fitting Size</h4>
                    <p>{productName}</p>
                </div>
                <Grid container spacing={2}>
                    <Grid item container alignItems="center" justifyContent="space-between" spacing={1} xs>
                        {/* <Grid item>
                            <IconButton>
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid> */}
                        <Grid item>
                            <div className={classes.yourSize}>
                                <span className={classes.sizeText}>Size</span>
                                <span>{yourSize}</span>
                            </div>
                        </Grid>

                        {/* <Grid item>
                            <IconButton>
                                <ArrowForwardIcon />
                            </IconButton>
                        </Grid> */}
                    </Grid>
                    <Grid item container alignItems="center" spacing={1}>
                        <Grid item xs>
                            <span className={classes.yourFits}>Fits well</span>
                        </Grid>
                    </Grid>
                    <Grid item xs><Divider /></Grid>
                    {/* <Grid item container alignItems="center">
                        <Grid item xs>Chest circumference</Grid>
                        <Grid item xs={3}>
                            <span className={classes.rateText}>Fits well</span>
                        </Grid>
                    </Grid>
                    <Grid item xs><Divider /></Grid>
                    <Grid item container alignItems="center">
                        <Grid item xs>Circumference at navel level</Grid>
                        <Grid item xs={3}>
                            <span className={classes.rateText}>Fits well</span>
                        </Grid>
                    </Grid>
                    <Grid item xs><Divider /></Grid>
                    <Grid item container alignItems="center">
                        <Grid item xs>Hip</Grid>
                        <Grid item xs={3}>
                            <span className={classes.rateText}>Fits well</span>
                        </Grid>
                    </Grid>
                    <Grid item xs><Divider /></Grid>
                    <Grid item container alignItems="center">
                        <Grid item xs>Height</Grid>
                        <Grid item xs={3}>
                            <span className={classes.rateText}>Fits well</span>
                        </Grid>
                    </Grid>
                    <Grid item xs><Divider /></Grid> */}

                    {sizeKey && sizeKey.map((key, index) => {
                        const sizeFromKey = resultSize.find(x => Object.keys(x)[0] === key);
                        return <Grid item container alignItems="center" spacing={2} key={key + index}>
                            <Grid item xs>{key}</Grid>
                            <Grid item xs={4}>
                                <span className={sizeFromKey && sizeFromKey[key] === yourSize ? classes.rateTextY : classes.rateTextN}>{sizeFromKey ? getMessage(sizeFromKey[key]) : 'No result'}</span>
                            </Grid>
                            <Grid item xs={12}><Divider /></Grid>
                        </Grid>

                    })}
                    <Grid item container justifyContent="center" >
                        <Grid item>
                            <Button color="secondary" disableRipple onClick={handleEditClick} >Edit</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </Fade>
    </>);
}
export default ResultSize;