const VirtualLocationMockDatas = [
  {
    panoId: 'entrance',
    center: { lat: 19.88665960103361, lng: 99.83433396149655 },
    backgroundMediaUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/005.%20Glass%20Animals%20-%20Heat%20Waves.mp3',
    pois: [
      {
        type: 'video',
        poiHeading: -1.1430388944554386,
        poiPitch: 26.59053498112021,
        url: 'https://www.youtube.com/watch?v=RQ7MjNeqBu0',
      },
      {
        type: 'video',
        poiHeading: -44.84633753342524,
        poiPitch: -1.5706972660296463,
        url: 'https://player.vimeo.com/external/588691749.sd.mp4?s=7a9625d262f45d8031335327a2f10c6b83970895&profile_id=165',
      },
      {
        type: 'coupon',
        poiHeading: 102.15697268754745,
        poiPitch: 6.418279554566042,
        couponId: '5',
      },
    ],
    posters: [
      {
        type: 'image',
        position: {
          topLeft: { poiHeading: -22.241479661631285, poiPitch: 6.775703660868389 },
          topRight: { poiHeading: 24.3704376029751, poiPitch: 8.100075882755371 },
          bottomLeft: { poiHeading: -22.455516244964205, poiPitch: 1.7192651764483775 },
          bottomRight: { poiHeading: 24.509179806846266, poiPitch: 2.5378402609429265 },
        },
        imageUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/street-front.jpg',
        linkUrl: '?id=kid',
      },
      {
        type: 'image',
        position: {
          topLeft: { poiHeading: 27.186711609385288, poiPitch: 9.455220679130738 },
          topRight: { poiHeading: 31.15104025533527, poiPitch: 11.819908207417722 },
          bottomLeft: { poiHeading: 27.32660659624357, poiPitch: -1.0578680829141995 },
          bottomRight: { poiHeading: 31.888306848421163, poiPitch: -0.08949270862650595 },
        },
        imageUrl: 'https://cdn.impactinit.com/cdn/x/x@97519b789f/smss52/smsimg30/pv/ingimagecontributors/ing_38192_62555.jpg',
      },
      {
        type: 'gif',
        position: {
          topLeft: { poiHeading: -32.57869789159248, poiPitch: 11.54106711734277 },
          topRight: { poiHeading: -24.728457151540884, poiPitch: 7.6412512203084955 },
          bottomLeft: { poiHeading: -33.31349697519593, poiPitch: -19.71874025945245 },
          bottomRight: { poiHeading: -25.168962576528767, poiPitch: -16.947405816142382 },
        },
        imageUrl: 'https://media.giphy.com/media/rpJt6RpyW5WLra267l/giphy.gif',
      },
      {
        type: 'gif',
        position: {
          topLeft: { poiHeading: 65.9390197382228, poiPitch: 5.734138647868286 },
          topRight: { poiHeading: 73.40745280442182, poiPitch: 6.665537381039806 },
          bottomLeft: { poiHeading: 66.65715334739839, poiPitch: -8.389026290986896 },
          bottomRight: { poiHeading: 75.03811992481292, poiPitch: -8.259488764058068 },
        },
        imageUrl: 'https://media.giphy.com/media/CtAfHpEvBlUpGLDAE9/giphy.gif',
      },
      {
        type: 'image',
        position: {
          topLeft: { poiHeading: -68.9964518566199, poiPitch: 0.8312507030352897 },
          topRight: { poiHeading: -62.784327946181214, poiPitch: 0.0721437442001169 },
          bottomLeft: { poiHeading: -69.9710394634844, poiPitch: -9.970292281800006 },
          bottomRight: { poiHeading: -63.49541054693055, poiPitch: -10.181494180437234 },
        },
        imageUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/ct-streetfront-poster-beach.png',
      },
      {
        type: 'image',
        position: {
          topLeft: { poiHeading: -80.25470105185077, poiPitch: 5.5276217501916936 },
          topRight: { poiHeading: -71.04728329255916, poiPitch: 4.800052502635793 },
          bottomLeft: { poiHeading: -80.60497407658374, poiPitch: 2.4557602720979617 },
          bottomRight: { poiHeading: -71.28523642767938, poiPitch: 1.8665042380422103 },
        },
        imageUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/ct-streetfront-poster-cottonon.jpg',
      },
      {
        type: 'image',
        position: {
          topLeft: { poiHeading: 78.01794616464582, poiPitch: 6.696341360109521 },
          topRight: { poiHeading: 86.9006233151422, poiPitch: 7.733755807692672 },
          bottomLeft: { poiHeading: 78.22935821813887, poiPitch: 3.71797466254569 },
          bottomRight: { poiHeading: 87.1834434260432, poiPitch: 4.68827061435201 },
        },
        imageUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/ct-streetfront-poster-cottonon.jpg',
      },
      // {
      //   type: 'mp4',
      //   position: {
      //     topLeft: { poiHeading: -52.801523140788326, poiPitch: 4.239409380075297 },
      //     topRight: { poiHeading: -40.72079786497249, poiPitch: 6.016133553001135 },
      //     bottomLeft: { poiHeading: -53.47112712221633, poiPitch: -10.956024999489108 },
      //     bottomRight: { poiHeading: -42.04579904975256, poiPitch: -13.722930881295468 },
      //   },
      //   videoUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/pexels-yan-krukov-7313744.mp4',
      // },
      {
        type: 'mp4',
        position: {
          topLeft: { poiHeading: -52.622130249614116, poiPitch: 2.3750886102587785 },
          topRight: { poiHeading: -48.97492297455005, poiPitch: 2.4684702539285386 },
          bottomLeft: { poiHeading: -53.30531410669783, poiPitch: -9.146765230533486 },
          bottomRight: { poiHeading: -49.762448552021446, poiPitch: -9.826833825409686 },
        },
        videoUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/pexels-shvets-production-7198483.mp4',
      },
    ],
  },
  {
    panoId: 'kid',
    center: { lat: 18.808103950939206, lng: 99.01803451609739 },
    backgroundMediaUrl: '',
    pois: [
      {
        type: 'price',
        poiHeading: 145.5437742787478,
        poiPitch: -5.200130359961904,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: 134.25925841722855,
        poiPitch: -3.9691140574702595,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: 122.17922462671402,
        poiPitch: -3.9030509561473727,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: 111.6534421074271,
        poiPitch: -2.123978564871599,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: 95.53197988613378,
        poiPitch: -2.1585618971616065,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: 69.16952662771006,
        poiPitch: -1.7284754883178246,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: 153.77374013424784,
        poiPitch: -6.652534473089829,
        price: 35.9,
        newArrival: true,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: 157.38045605065693,
        poiPitch: -18.392723675561516,
        price: 35.9,
        newArrival: true,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: 151.18018957736172,
        poiPitch: -25.71246007714949,
        price: 35.9,
        newArrival: true,
      },
      {
        type: 'price',
        poiHeading: 141.5107134736849,
        poiPitch: -25.13779478184278,
        price: 35.9,
        newArrival: true,
      },
      {
        type: 'price',
        poiHeading: 130.13333170357123,
        poiPitch: -28.15546614078991,
        price: 35.9,
        newArrival: true,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: 114.17424986568837,
        poiPitch: -31.981829076413206,
        price: 35.9,
        newArrival: true,
      },
      {
        type: 'price',
        poiHeading: 90.12646426811267,
        poiPitch: -31.458546363693618,
        price: 35.9,
        newArrival: true,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: 65.31315617900914,
        poiPitch: -36.260277430295154,
        price: 35.9,
        newArrival: true,
      },
      {
        type: 'price',
        poiHeading: 154.9189789757691,
        poiPitch: -33.06359140262354,
      },
      {
        type: 'price',
        poiHeading: 145.87075965435182,
        poiPitch: -38.31957991285575,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: 138.57393284160102,
        poiPitch: -44.39419987518173,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: 124.02154911768226,
        poiPitch: -49.197205821080836,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: 108.21064415707332,
        poiPitch: -51.52478729593362,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: 86.36776167781412,
        poiPitch: -52.99865222351214,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: 62.957703119710004,
        poiPitch: -50.69592608977476,
      },
      {
        type: 'price',
        poiHeading: 46.03414220885453,
        poiPitch: -44.01847116009299,
      },
      {
        type: 'price',
        poiHeading: 48.35739418011547,
        poiPitch: -29.25164653022863,
      },
      {
        type: 'price',
        poiHeading: 35.4133666605014,
        poiPitch: -37.2972262506287,
      },
      {
        type: 'price',
        poiHeading: 50.79001714417559,
        poiPitch: -0.7423274274992793,
      },
      {
        type: 'price',
        poiHeading: 38.008963962960635,
        poiPitch: 3.969931203436787,
      },
      {
        type: 'price',
        poiHeading: 39.01300439634594,
        poiPitch: -18.590452072038314,
      },
      {
        type: 'price',
        poiHeading: 28.24947967848703,
        poiPitch: -29.465101278662846,
      },
      {
        type: 'price',
        poiHeading: 27.655135649254476,
        poiPitch: -12.158864964401193,
      },
      {
        type: 'price',
        poiHeading: 21.47896153771071,
        poiPitch: -17.767722681828502,
      },
      {
        type: 'price',
        poiHeading: 16.24444445572569,
        poiPitch: -13.201404259400714,
      },
      {
        type: 'price',
        poiHeading: 13.715946696675521,
        poiPitch: -11.694647410887338,
      },
      {
        type: 'price',
        poiHeading: 28.770101123688924,
        poiPitch: 7.000381712976524,
      },
      {
        type: 'price',
        poiHeading: 22.09680299673609,
        poiPitch: 11.170848759340963,
      },
      {
        type: 'price',
        poiHeading: 18.327572514444498,
        poiPitch: 5.7675546865660605,
      },
      {
        type: 'price',
        poiHeading: 16.508891958635722,
        poiPitch: -0.36558572911797677,
      },
      {
        type: 'price',
        poiHeading: 9.111007083297803,
        poiPitch: -1.2637583808336452,
      },
      {
        type: 'price',
        poiHeading: 3.1689817555916497,
        poiPitch: -1.640890708104545,
      },
      {
        type: 'price',
        poiHeading: -3.0720095143038093,
        poiPitch: -2.172335958470023,
      },
      {
        type: 'price',
        poiHeading: 9.809626161672524,
        poiPitch: -15.027644969648044,
      },
      {
        type: 'price',
        poiHeading: 2.6625058918436797,
        poiPitch: -15.295691188601555,
      },
      {
        type: 'price',
        poiHeading: -4.475996271931031,
        poiPitch: -14.62802965099248,
      },
      {
        type: 'price',
        poiHeading: -26.717021706958548,
        poiPitch: -8.641055391291092,
      },
      {
        type: 'price',
        poiHeading: -31.196244356301513,
        poiPitch: -10.864412768361307,
      },
      {
        type: 'price',
        poiHeading: -37.11922716095636,
        poiPitch: -13.54721319881326,
      },
      {
        type: 'price',
        poiHeading: -53.93345321053244,
        poiPitch: -28.50040601217368,
      },
      {
        type: 'price',
        poiHeading: -61.92135322998063,
        poiPitch: -25.258371246722515,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: -66.39569141939624,
        poiPitch: -22.52006743483251,
      },
      {
        type: 'price',
        poiHeading: -49.29814328403877,
        poiPitch: -16.991118074514542,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: -53.417322415476804,
        poiPitch: -16.211446357051248,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -57.00582560220772,
        poiPitch: -14.762471393388704,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -61.03923458210288,
        poiPitch: -14.370218904660414,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: -64.6722011852779,
        poiPitch: -10.680542663635498,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -76.16724923634148,
        poiPitch: 0.8932416394465004,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -72.96581030426134,
        poiPitch: 1.2082461026400269,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: -69.77807262142908,
        poiPitch: 1.641981623972409,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -75.92127311169632,
        poiPitch: -10.037222729799488,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: -71.69109508690876,
        poiPitch: -8.86749433657427,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -67.98842499584407,
        poiPitch: -8.64406508431015,
        newArrival: true,
        productId: 2,
        price: 35.9,
      },
      {
        type: 'price',
        poiHeading: -65.00241467287756,
        poiPitch: -2.0524543652062928,
        newArrival: true,
        productId: 2,
        price: 35.9,
      },
      {
        type: 'price',
        poiHeading: -63.31906976921207,
        poiPitch: -1.9635952669944494,
        newArrival: true,
        productId: 2,
        price: 35.9,
      },
      {
        type: 'price',
        poiHeading: -61.48300689436464,
        poiPitch: -1.31969790568347,
        newArrival: true,
        productId: 2,
        price: 35.9,
      },
      {
        type: 'price',
        poiHeading: -59.50524815076714,
        poiPitch: -1.240652080353422,
        newArrival: true,
        productId: 2,
        price: 35.9,
      },
      {
        type: 'price',
        poiHeading: -21.875971335058313,
        poiPitch: 11.490720011427667,
        newArrival: true,
        productId: 2,
        price: 35.9,
      },
      {
        type: 'price',
        poiHeading: -25.249392927012533,
        poiPitch: 10.856669763931565,
        newArrival: true,
        productId: 2,
        price: 35.9,
      },
      {
        type: 'price',
        poiHeading: -36.65375845579096,
        poiPitch: 9.333019966872246,
        newArrival: true,
        productId: 2,
        price: 35.9,
      },
      {
        type: 'price',
        poiHeading: -39.77650583594254,
        poiPitch: 8.65101416942525,
        newArrival: true,
        productId: 2,
        price: 35.9,
      },
      {
        type: 'price',
        poiHeading: -59.419731357400075,
        poiPitch: 6.823973461004474,
        newArrival: true,
        productId: 2,
        price: 35.9,
      },
      {
        type: 'price',
        poiHeading: -66.00898167425822,
        poiPitch: 6.221096298520379,
        newArrival: true,
        productId: 2,
        price: 35.9,
      },
      {
        type: 'price',
        poiHeading: -50.89489039683252,
        poiPitch: 3.8591436765153366,
        newArrival: true,
        productId: 2,
        price: 35.9,
      },
    ],
  },
  {
    panoId: 'men-t-shirts',
    center: { lat: 19.88665960103361, lng: 99.83433396149655 },
    backgroundMediaUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/016.%20The%20Kid%20Laroi%20-%20STAY.mp3',
    pois: [
      {
        type: 'price',
        poiHeading: 3.887530362105869,
        poiPitch: -13.48260070014962,
        price: 35.9,
        newArrival: true,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: 9.672645284246261,
        poiPitch: -20.113843824733767,
        price: 35.9,
        newArrival: true,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: 1.1570289034039585,
        poiPitch: -48.914620261011436,
        price: 35.9,
        newArrival: true,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: -31.135937812726795,
        poiPitch: -25.394808392396083,
        price: 35.9,
        discount: '-30%',
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: 35.65688036965149,
        poiPitch: -19.707902796558262,
        price: 35.9,
        discount: '-30%',
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: -51.21607490247135,
        poiPitch: -14.036758085777734,
        price: 35.9,
        discount: '-30%',
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: -75.33454895907772,
        poiPitch: -16.29557189219707,
      },
      {
        type: 'price',
        poiHeading: 52.50780571454853,
        poiPitch: -9.372084501213484,
      },
      {
        type: 'price',
        poiHeading: 45.17285043396784,
        poiPitch: -2.314412857380445,
      },
      {
        type: 'price',
        poiHeading: 55.179467263075075,
        poiPitch: 14.740486700464839,
      },
      {
        type: 'price',
        poiHeading: 88.25490191952704,
        poiPitch: -2.0022280916612427,
      },
      {
        type: 'price',
        poiHeading: 88.10921549476956,
        poiPitch: -20.838974222976454,
      },
      {
        type: 'price',
        poiHeading: 72.40870694033217,
        poiPitch: -3.524499201908846,
      },
      {
        type: 'price',
        poiHeading: 99.09498530527034,
        poiPitch: -1.553508816527899,
      },
      {
        type: 'price',
        poiHeading: 111.49196151048226,
        poiPitch: -1.1150133997313556,
      },
      {
        type: 'price',
        poiHeading: 85.33477755032456,
        poiPitch: 13.537081750374085,
      },
      {
        type: 'price',
        poiHeading: 94.99200743131355,
        poiPitch: 15.798865067539454,
      },
      {
        type: 'price',
        poiHeading: 130.16223958510264,
        poiPitch: 14.13211582433787,
      },
      {
        type: 'price',
        poiHeading: 135.5874979318316,
        poiPitch: 13.131846806767806,
      },
      {
        type: 'price',
        poiHeading: 126.92787157913861,
        poiPitch: 5.154832241032237,
      },
      {
        type: 'price',
        poiHeading: 27.42309139917141,
        poiPitch: -1.1825400868831235,
        pulsing: true,
      },
      {
        type: 'video',
        poiHeading: -21.72844313274096,
        poiPitch: -3.4496813679372647,
        url: 'https://www.youtube.com/watch?v=9J1xjlCLQzE',
      },
      {
        type: 'coupon',
        poiHeading: -58.008015715082685,
        poiPitch: -10.502169893413555,
        couponId: '6',
      },
      {
        type: 'changingroomportal',
        poiHeading: 29.94118795341296,
        poiPitch: 9.246610301509007,
        url: '?id=men-changing-room',
        title: 'Try It On',
      },
    ],
    posters: [
      // {
      //   type: 'image',
      //   position: {
      //     topLeft: { poiHeading: 25.54004344685634, poiPitch: 8.890046635313489 },
      //     topRight: { poiHeading: 29.400609586201938, poiPitch: 8.691042058418681 },
      //     bottomLeft: { poiHeading: 25.54004344685634, poiPitch: 1.7203252372780928 },
      //     bottomRight: { poiHeading: 29.559457738727584, poiPitch: 1.5296641279846832 },
      //   },
      //   imageUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/changing_room_sign_02.png',
      //   linkUrl: '?id=men-3',
      // },
    ],
  },
  {
    panoId: 'men-shirt',
    center: { lat: 19.88665960103361, lng: 99.83433396149655 },
    backgroundMediaUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/016.%20The%20Kid%20Laroi%20-%20STAY.mp3',
    pois: [
      {
        type: 'price',
        poiHeading: 26.321945472263128,
        poiPitch: -11.47248463923697,
        price: 19.99,
        productId: 1,
        newArrival: true,
      },
      {
        type: 'price',
        poiHeading: -25.894757946714602,
        poiPitch: -10.188310646183231,
        price: 19.99,
        productId: 2,
        newArrival: true,
      },
      {
        type: 'price',
        poiHeading: -46.33945531424073,
        poiPitch: -5.067675839556639,
        price: 19.99,
        productId: 3,
        newArrival: true,
      },
      {
        type: 'price',
        poiHeading: 26.258150944488868,
        poiPitch: -50.92131459007453,
      },
      {
        type: 'price',
        poiHeading: -57.0190440282966,
        poiPitch: -26.14718294784736,
      },
      {
        type: 'price',
        poiHeading: 91.37308527719868,
        poiPitch: -0.3991175825030621,
      },
      {
        type: 'price',
        poiHeading: 83.61719266172491,
        poiPitch: 0.0025159815508880557,
      },
      {
        type: 'price',
        poiHeading: 76.4002358041747,
        poiPitch: -0.35687759764278004,
      },
      {
        type: 'price',
        poiHeading: 67.85717310887172,
        poiPitch: -0.6472235705974952,
      },
      {
        type: 'price',
        poiHeading: 58.83813761138283,
        poiPitch: 0.6916259232449007,
      },
      {
        type: 'price',
        poiHeading: 54.510889437615724,
        poiPitch: 0.7564610822004468,
      },
      {
        type: 'price',
        poiHeading: 51.264175269025294,
        poiPitch: 0.6418695978335074,
        discount: '-30%',
        price: 19.99,
        productId: 1,
        tagIcon: 'time',
      },
      {
        type: 'price',
        poiHeading: 59.147651464103696,
        poiPitch: -4.301735297767646,
        discount: '-30%',
        price: 19.99,
        productId: 1,
        tagIcon: 'time',
      },
      {
        type: 'price',
        poiHeading: 54.73315617246912,
        poiPitch: -4.364853122884659,
        discount: '-30%',
        price: 19.99,
        productId: 1,
        tagIcon: 'time',
      },
      {
        type: 'price',
        poiHeading: 51.61534736258796,
        poiPitch: -4.320976979756884,
      },
      {
        type: 'price',
        poiHeading: 73.90705900450622,
        poiPitch: -20.398813064473238,
        zoomLevel: 0.7,
      },
      {
        type: 'price',
        poiHeading: 65.88925716217356,
        poiPitch: -18.453302844217603,
        tagIcon: 'hot',
        zoomLevel: 0,
      },
      {
        type: 'price',
        poiHeading: 52.704375260207875,
        poiPitch: -16.400503829448823,
        zoomLevel: 0.7,
      },
      {
        type: 'price',
        poiHeading: 44.15299615012227,
        poiPitch: -13.46414449664616,
        zoomLevel: 0.7,
      },
      {
        type: 'price',
        poiHeading: 41.29843132227237,
        poiPitch: -12.532417787263741,
        zoomLevel: 0.7,
      },
    ],
  },
  {
    panoId: 'women-swim',
    center: { lat: 19.88665960103361, lng: 99.83433396149655 },
    backgroundMediaUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/016.%20The%20Kid%20Laroi%20-%20STAY.mp3',
    pois: [
      {
        type: 'video',
        poiHeading: -27.063910877355898,
        poiPitch: 33.32816431102956,
        url: 'https://www.youtube.com/watch?v=GM1QwQK7jtg',
      },
      {
        type: 'price',
        poiHeading: -76.90350281034002,
        poiPitch: 3.367274265487347,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -58.4883253152249,
        poiPitch: 4.030124529183988,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -39.7332631162618,
        poiPitch: 3.3410545448480646,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -26.29066130649157,
        poiPitch: 2.4350768203982094,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -12.9468241785861,
        poiPitch: 1.0439102673058773,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -3.482862250976024,
        poiPitch: 0.16270203618864523,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: 2.6787365709626334,
        poiPitch: -0.3948710668656431,
      },
      {
        type: 'price',
        poiHeading: 15.44698317402941,
        poiPitch: -1.1285954998724343,
      },
      {
        type: 'price',
        poiHeading: 22.7420667069913,
        poiPitch: -0.6793494234183279,
      },
      {
        type: 'price',
        poiHeading: 29.868277636398805,
        poiPitch: -0.6807758583340605,
      },
      {
        type: 'price',
        poiHeading: 36.900926134210735,
        poiPitch: -0.34165104226094867,
      },
      {
        type: 'price',
        poiHeading: 44.437510169524536,
        poiPitch: -0.6818932524234143,
      },
      {
        type: 'price',
        poiHeading: 50.87183321721943,
        poiPitch: 0.11486707351130816,
      },
      {
        type: 'price',
        poiHeading: 56.38895944989338,
        poiPitch: -0.10960367623471438,
      },
      {
        type: 'price',
        poiHeading: 62.737924192043415,
        poiPitch: 1.347525802779382,
      },
    ],
  },
  {
    panoId: 'women-bag',
    center: { lat: 19.88665960103361, lng: 99.83433396149655 },
    backgroundMediaUrl: '',
    pois: [
      {
        type: 'price',
        poiHeading: -38.031289897291366,
        poiPitch: -34.97776852342122,
        discount: '$10',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -30.088056074351442,
        poiPitch: -39.285021030082866,
        discount: '$10',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -20.812559387232586,
        poiPitch: -42.87264985182695,
        discount: '$10',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -10.676098128567597,
        poiPitch: -44.064732676103226,
        discount: '$10',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: 0.20101940601620005,
        poiPitch: -45.010805925904805,
        discount: '$10',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -21.52104097131589,
        poiPitch: -20.482725870647673,
      },
      {
        type: 'price',
        poiHeading: 19.94991215498065,
        poiPitch: -37.12536574449135,
      },
      {
        type: 'price',
        poiHeading: -41.115055557002805,
        poiPitch: -11.909509751491258,
      },
      {
        type: 'price',
        poiHeading: -97.05805092570785,
        poiPitch: -15.147142929796985,
      },
      {
        type: 'price',
        poiHeading: -98.82564296795377,
        poiPitch: -28.78537438634717,
      },
      {
        type: 'price',
        poiHeading: -99.59289614576016,
        poiPitch: -41.78922338600746,
      },
      {
        type: 'price',
        poiHeading: -134.9143752637425,
        poiPitch: -21.2923736396245,
      },
      {
        type: 'price',
        poiHeading: -133.939485151491,
        poiPitch: -11.067556893853327,
      },
      {
        type: 'price',
        poiHeading: -133.83027371214285,
        poiPitch: -1.6927144447265412,
      },
      {
        type: 'price',
        poiHeading: 80.4009389985936,
        poiPitch: -12.8033802382563,
      },
      {
        type: 'price',
        poiHeading: 48.439475228285005,
        poiPitch: -16.889737133388135,
      },
      {
        type: 'price',
        poiHeading: 35.97982911409819,
        poiPitch: -13.031061792435784,
      },
      {
        type: 'price',
        poiHeading: 9.320676788383363,
        poiPitch: -3.8458120519089776,
      },
      {
        type: 'price',
        poiHeading: -4.073808420540001,
        poiPitch: -1.2800104959839786,
      },
      {
        type: 'portal',
        poiHeading: -129.18574752397043,
        poiPitch: 4.963191630820143,
        url: '?id=men-changing-room',
        title: 'Try It On',
      },
    ],
  },
  {
    panoId: 'women-lingerie',
    center: { lat: 19.88665960103361, lng: 99.83433396149655 },
    backgroundMediaUrl: '',
    pois: [
      {
        type: 'price',
        poiHeading: 60.4795399963355,
        poiPitch: -5.057768580200184,
        price: 35.9,
        newArrival: true,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: -62.805744599846044,
        poiPitch: -2.1621177648998806,
        price: 35.9,
        newArrival: true,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: -16.582848281522477,
        poiPitch: 4.552548941869139,
        price: 35.9,
        newArrival: true,
        productId: 2,
      },
    ],
  },
  {
    panoId: 'kid-dresses',
    center: { lat: 19.88665960103361, lng: 99.83433396149655 },
    backgroundMediaUrl: '',
    pois: [
      {
        type: 'price',
        poiHeading: -117.80367052643489,
        poiPitch: -32.3088276748413,
        price: 35.9,
        newArrival: true,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: -22.108999248987782,
        poiPitch: 18.920543951096153,
        price: 35.9,
        newArrival: true,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: -13.676183621388695,
        poiPitch: 23.304269120765134,
        price: 35.9,
        newArrival: true,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: -3.9560820253444895,
        poiPitch: 26.157110997775398,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: 4.760264243211896,
        poiPitch: -7.220441818482438,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -16.775107547924524,
        poiPitch: -10.247370732028871,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -23.174370312930368,
        poiPitch: -2.8216722577928026,
        discount: '-30%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: 36.31815962679528,
        poiPitch: -15.862698915639589,
      },
      {
        type: 'price',
        poiHeading: 18.965794735626933,
        poiPitch: -6.796809087932758,
      },
      {
        type: 'price',
        poiHeading: -8.374464082533866,
        poiPitch: -31.678087314061322,
      },
      {
        type: 'price',
        poiHeading: -21.920003338165582,
        poiPitch: -25.723480368572982,
      },
      {
        type: 'price',
        poiHeading: -29.553085226758153,
        poiPitch: -23.507557479922596,
      },
      {
        type: 'price',
        poiHeading: -96.45286914348962,
        poiPitch: -17.249445702200855,
      },
    ],
  },
  {
    panoId: 'women',
    center: { lat: 19.88665960103361, lng: 99.83433396149655 },
    backgroundMediaUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/016.%20The%20Kid%20Laroi%20-%20STAY.mp3',
    pois: [
      {
        type: 'video',
        poiHeading: -58.69041204896192,
        poiPitch: 15.362422387213023,
        url: 'https://www.youtube.com/watch?v=o6kEJT0WeOs',
      },
      {
        type: 'price',
        poiHeading: -51.66213547883924,
        poiPitch: 15.684136072276948,
        price: 55.9,
        newArrival: true,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -41.05402144658012,
        poiPitch: 6.569894966973731,
        price: 55.9,
        newArrival: true,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -8.65868922899691,
        poiPitch: 11.240027170786261,
        price: 55.9,
        newArrival: true,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: 10.919672471711669,
        poiPitch: 15.278568894609819,
        price: 55.9,
        newArrival: true,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: -2.1305858107510844,
        poiPitch: -6.2478497386261695,
        price: 55.9,
        newArrival: true,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: 56.66034841251813,
        poiPitch: 9.64284716841147,
        price: 55.9,
        newArrival: true,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: 73.50024635821148,
        poiPitch: -12.256944015054565,
        price: 55.9,
        newArrival: true,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: -100.2673333011322,
        poiPitch: -31.71520191070845,
        discount: '$20',
        price: 20,
        productId: 1,
      },
    ],
  },
  {
    panoId: 'women-accessories',
    center: { lat: 19.88665960103361, lng: 99.83433396149655 },
    backgroundMediaUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/016.%20The%20Kid%20Laroi%20-%20STAY.mp3',
    pois: [
      {
        type: 'video',
        poiHeading: -91.91154357077089,
        poiPitch: 9.876816053479926,
        url: 'https://www.youtube.com/watch?v=o6kEJT0WeOs',
      },
      {
        type: 'price',
        poiHeading: -102.81932257159094,
        poiPitch: 4.904271633133975,
        price: 55.9,
        newArrival: true,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -103.96184149914126,
        poiPitch: -14.58928263337415,
        price: 55.9,
        newArrival: true,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: -20.993845615488592,
        poiPitch: 7.975707943581598,
        price: 55.9,
        newArrival: true,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: 5.311084918128351,
        poiPitch: 8.415027805585396,
        price: 55.9,
        newArrival: true,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: 18.488211855788645,
        poiPitch: 7.847690060570127,
        price: 55.9,
        newArrival: true,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: -6.962318625506929,
        poiPitch: -11.212794197785607,
        price: 55.9,
        newArrival: true,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: -78.46166841784378,
        poiPitch: -2.470839219432721,
        price: 25.9,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: -52.00607315579262,
        poiPitch: 4.542537304021404,
        price: 25.9,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: -77.88711876888387,
        poiPitch: -14.752901833468108,
        price: 25.9,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -88.51827865636395,
        poiPitch: -4.000789305059265,
        price: 25.9,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: -62.282959945956684,
        poiPitch: 3.1919900002277064,
        price: 25.9,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: -49.79678468999634,
        poiPitch: -10.884084733697552,
        price: 25.9,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -78.02402826655965,
        poiPitch: -45.38748297860957,
        price: 25.9,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: -52.67667413095527,
        poiPitch: -47.45875018618997,
        price: 25.9,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: -41.73432479483599,
        poiPitch: 6.296388946494475,
        price: 25.9,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: -40.176030313698405,
        poiPitch: -6.1561566249662265,
        price: 25.9,
        productId: 3,
      },
    ],
  },
  {
    panoId: 'women-2',
    center: { lat: 19.88665960103361, lng: 99.83433396149655 },
    backgroundMediaUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/016.%20The%20Kid%20Laroi%20-%20STAY.mp3',
    pois: [
      {
        type: 'price',
        poiHeading: -52.54979382532007,
        poiPitch: -3.054735849121107,
        price: 49.9,
        newArrival: true,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -50.245575649705735,
        poiPitch: -7.6001482426354485,
        price: 49.9,
        newArrival: true,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: -44.920810403835105,
        poiPitch: -6.624225317871453,
        price: 49.9,
        newArrival: true,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: -37.22506863118923,
        poiPitch: -15.301785060006745,
      },
      {
        type: 'price',
        poiHeading: -3.5520208250133165,
        poiPitch: -12.63386785100967,
      },
      {
        type: 'price',
        poiHeading: 16.00512259451127,
        poiPitch: -29.403834048224898,
      },
      {
        type: 'price',
        poiHeading: 57.27081610609728,
        poiPitch: -12.337438030212724,
      },
      {
        type: 'price',
        poiHeading: 34.461155263295915,
        poiPitch: -4.076666576312604,
      },
      {
        type: 'price',
        poiHeading: -19.786998445208702,
        poiPitch: -5.148860683327823,
      },
    ],
  },
  {
    panoId: 'men-3',
    center: { lat: 19.88665960103361, lng: 99.83433396149655 },
    backgroundMediaUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/016.%20The%20Kid%20Laroi%20-%20STAY.mp3',
    pois: [
      {
        type: 'video',
        poiHeading: -33.993608859142476,
        poiPitch: -1.9768188102864355,
        url: 'https://www.youtube.com/watch?v=3zOEKCkYRKQ',
      },
      {
        type: 'price',
        poiHeading: -16.844406324698824,
        poiPitch: 7.2501849477389895,
        price: 49.9,
        newArrival: true,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -6.426974088730857,
        poiPitch: 4.019404447935735,
        price: 49.9,
        newArrival: true,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: -25.32361582451694,
        poiPitch: 2.398117190250446,
        price: 49.9,
        newArrival: true,
        productId: 3,
      },
      {
        type: 'price',
        poiHeading: 49.09996508111726,
        poiPitch: -0.289444752260235,
        pulsing: true,
      },
      {
        type: 'price',
        poiHeading: -60.64032225969321,
        poiPitch: -31.519953565363664,
      },
      {
        type: 'price',
        poiHeading: -54.13744844522851,
        poiPitch: -9.258290365655773,
      },
      {
        type: 'price',
        poiHeading: -39.4594819602609,
        poiPitch: -12.819775072449511,
      },
      {
        type: 'price',
        poiHeading: -28.679112906515453,
        poiPitch: -17.773315609728467,
      },
      {
        type: 'price',
        poiHeading: 1.7950913431194004,
        poiPitch: -24.800946533558072,
      },
      {
        type: 'changingroomportal',
        poiHeading: 50.15668143315456,
        poiPitch: 18.421156978751156,
        url: '?id=men-changing-room',
        title: 'Try It On',
      },
    ],
    posters: [
      {
        type: 'image',
        position: {
          topLeft: { poiHeading: 22.0656975961499, poiPitch: 6.864324127768305 },
          topRight: { poiHeading: 33.87582914980029, poiPitch: 6.3983866697634175 },
          bottomLeft: { poiHeading: 22.262019938474747, poiPitch: 4.601103023497862 },
          bottomRight: { poiHeading: 33.74368091451613, poiPitch: 4.470615989130583 },
        },
        imageUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/street-front.jpg',
        linkUrl: '?id=men-3',
      },
      // {
      //   type: 'image',
      //   position: {
      //     topLeft: { poiHeading: 50.37033344979453, poiPitch: 25.174073668001622 },
      //     topRight: { poiHeading: 56.33452145672571, poiPitch: 23.05442982907929 },
      //     bottomLeft: { poiHeading: 50.564807696028495, poiPitch: 13.57630845034977 },
      //     bottomRight: { poiHeading: 56.3452944386612, poiPitch: 12.307785350145435 },
      //   },
      //   imageUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/changing_room_sign_02.png',
      //   linkUrl: '?id=men-3',
      // },
    ],
  },
  {
    panoId: 'men-changing-room',
    center: { lat: 19.88665960103361, lng: 99.83433396149655 },
    backgroundMediaUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/016.%20The%20Kid%20Laroi%20-%20STAY.mp3',
    pois: [
      {
        type: 'changingroom',
        // poiHeading: 0.9617949626697727,
        // poiPitch: -5.311470445797613,
        poiHeading: -0.16967603480153465,
        poiPitch: -10.149563862330726,
        title: 'Changing room',
        botAvatar: '/images/asts_03.png',
      },
      {
        type: 'changingroom',
        // poiHeading: -63.474547798358984,
        // poiPitch: -7.882594241102538,
        poiHeading: -60.32489413608201,
        poiPitch: -19.239407294626986,
        title: 'Changing room 2',
        botAvatar: '/images/asts_01.png',
      },
      {
        type: 'changingroom',
        // poiHeading: 21.74769803528084,
        // poiPitch: -6.760564495873228,
        poiHeading: 22.762701547176658,
        poiPitch: -20.031240122881773,
        title: 'Changing room 10',
        botAvatar: '/images/asts_02.png',
      },
      {
        type: 'portal',
        poiHeading: 39.77858596890971,
        poiPitch: -15.053919726715153,
        url: '?id=changing-room-11',
        title: 'Desert',
      },
      {
        type: 'portal',
        poiHeading: -26.85266307303977,
        poiPitch: -9.684756629513153,
        url: 'https://cottonon.com/AU/',
        title: 'cottonon.com',
      },
    ],
    posters: [
      {
        type: 'image',
        position: {
          topLeft: { poiHeading: -79.87752369333819, poiPitch: 16.93411266962601 },
          topRight: { poiHeading: -47.46111177153116, poiPitch: 11.521037416437714 },
          bottomLeft: { poiHeading: -85.71697207333052, poiPitch: -53.627579616851634 },
          bottomRight: { poiHeading: -50.81043970996409, poiPitch: -49.82116481048791 },
        },
        imageUrl: '/images/asts_01.png',
      },
      {
        type: 'image',
        position: {
          topLeft: { poiHeading: -2.948145239288471, poiPitch: 1.6795287079061625 },
          topRight: { poiHeading: 11.034335103441059, poiPitch: 2.4265865183745507 },
          bottomLeft: { poiHeading: -3.575727050016166, poiPitch: -18.001131077091493 },
          bottomRight: { poiHeading: 11.479626548166399, poiPitch: -18.07466792100178 },
        },
        imageUrl: '/images/asts_03.png',
      },
      {
        type: 'image',
        position: {
          topLeft: { poiHeading: 19.3261826139012, poiPitch: 4.23020234735781 },
          topRight: { poiHeading: 24.619020257375723, poiPitch: 6.923959948199092 },
          bottomLeft: { poiHeading: 20.767704378205146, poiPitch: -33.29005518972623 },
          bottomRight: { poiHeading: 26.713767445081196, poiPitch: -40.577027963423006 },
        },
        imageUrl: '/images/asts_02.png',
      },
    ],
  },
  {
    panoId: 'men-4',
    center: { lat: 19.88665960103361, lng: 99.83433396149655 },
    backgroundMediaUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/016.%20The%20Kid%20Laroi%20-%20STAY.mp3',
    pois: [
      // {
      //   type: 'portal',
      //   poiHeading: 6.1086562834812375,
      //   poiPitch: 0.6462558964370175,
      //   url: '?id=kid',
      //   title: 'Go to Kids',
      // },
      {
        type: 'counter',
        poiHeading: -39.580700513902386,
        poiPitch: -1.041172404787869,
      },
      {
        type: 'selection',
        poiHeading: 6.6306711604680455,
        poiPitch: -3.161671458969629,
        locations: [
          {
            floor: '1',
            url: '?id=kid',
            title: 'COTTON ON KIDS',
            imageUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/cottonon-kids-logo.png',
          },
          {
            floor: '2',
            url: '?id=secondfloor-elevator',
            title: 'Supre',
            imageUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/supre-logo.png',
          },
          {
            floor: '3',
            url: '?id=men-4',
            title: 'Factorie',
            imageUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/factorie-logo.png',
          },
        ],
      },
      {
        type: 'price',
        poiHeading: 45.83044193077854,
        poiPitch: -1.9945590609451944,
        discount: '$30',
        price: 30,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: 116.62449243420268,
        poiPitch: -28.783615947260373,
        discount: '$39.99',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: 0.40489828417626644,
        poiPitch: -49.10508199556618,
        discount: '$7',
        price: 7,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -44.09625097979047,
        poiPitch: -31.549643144789393,
        discount: '$10',
        price: 10,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: 128.44341035627917,
        poiPitch: -14.919098719047508,
      },
      {
        type: 'price',
        poiHeading: 73.02715459491895,
        poiPitch: 6.684382197755773,
      },
      {
        type: 'price',
        poiHeading: 80.03837138466957,
        poiPitch: 6.557380094219879,
      },
      {
        type: 'price',
        poiHeading: 89.56778138535546,
        poiPitch: 7.782187373209289,
      },
      {
        type: 'price',
        poiHeading: 97.11743427088153,
        poiPitch: 7.5678988553322695,
      },
      {
        type: 'price',
        poiHeading: 106.64529369967686,
        poiPitch: 5.075683717280153,
      },
      {
        type: 'price',
        poiHeading: 93.21215732628337,
        poiPitch: 18.753898261168665,
      },
    ],
    posters: [
      {
        type: 'image',
        position: {
          topLeft: { poiHeading: -20.64014403428275, poiPitch: 19.55355565785398 },
          topRight: { poiHeading: 19.828486089404056, poiPitch: 23.458682568563123 },
          bottomLeft: { poiHeading: -19.38762495306415, poiPitch: 13.223079891554722 },
          bottomRight: { poiHeading: 20.325012615166806, poiPitch: 17.600416536593226 },
        },
        imageUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/street-front.jpg',
        linkUrl: '?id=men-4',
      },
    ],
  },
  {
    panoId: 'counter',
    center: { lat: 19.88665960103361, lng: 99.83433396149655 },
    backgroundMediaUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/016.%20The%20Kid%20Laroi%20-%20STAY.mp3',
    pois: [
      {
        type: 'video',
        poiHeading: -144.65040760707396,
        poiPitch: -13.042391222722332,
        url: 'https://player.vimeo.com/external/588691749.sd.mp4?s=7a9625d262f45d8031335327a2f10c6b83970895&profile_id=165',
      },
      {
        type: 'price',
        poiHeading: -20.329105896311678,
        poiPitch: -12.117613914246148,
        discount: '$15',
        price: 30,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: -129.21894096747835,
        poiPitch: -3.05751757625305,
        price: 35.9,
        newArrival: true,
        productId: 2,
      },
      {
        type: 'price',
        poiHeading: -63.95270217154874,
        poiPitch: -2.429742394149554,
      },
      {
        type: 'price',
        poiHeading: 168.76194956080002,
        poiPitch: -17.453992358928218,
      },
      {
        type: 'price',
        poiHeading: 35.765611885017776,
        poiPitch: -47.600679112073514,
      },
      {
        type: 'video',
        poiHeading: -56.96679893043307,
        poiPitch: 17.18034882865184,
        url: 'https://www.youtube.com/watch?v=GM1QwQK7jtg',
      },
      {
        type: 'counter',
        poiHeading: 16.044492622138858,
        poiPitch: -3.8775545955048862,
      },
    ],
  },
  {
    panoId: 't-shirt',
    center: { lat: 19.88665960103361, lng: 99.83433396149655 },
    backgroundMediaUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/016.%20The%20Kid%20Laroi%20-%20STAY.mp3',
    pois: [
      {
        type: 'price',
        poiHeading: -23.52742670235822,
        poiPitch: 15.424148465582702,
        discount: '50%',
        price: 19.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: 38.03559591200296,
        poiPitch: 7.944261366339791,
        discount: '$29.99',
        price: 29.99,
        productId: 1,
      },
      {
        type: 'price',
        poiHeading: 104.66213727403724,
        poiPitch: -19.95287210202764,
      },
      {
        type: 'price',
        poiHeading: 75.44254409548131,
        poiPitch: -0.22518423437818322,
      },
      {
        type: 'price',
        poiHeading: -105.81366442848537,
        poiPitch: -24.354764642633352,
      },
    ],
    posters: [
      {
        type: 'mp4',
        position: {
          topLeft: { poiHeading: -77.74267057794974, poiPitch: 12.57233958156741 },
          topRight: { poiHeading: -66.68770783605783, poiPitch: 19.61907094666054 },
          bottomLeft: { poiHeading: -74.53228462199279, poiPitch: -20.638009146886564 },
          bottomRight: { poiHeading: -61.13062612943201, poiPitch: -25.478742012681916 },
        },
        videoUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/pexels-mikhail-nilov-6507740.mp4',
      },
    ],
  },
  {
    panoId: 'changing-room-11',
    center: { lat: 19.88665960103361, lng: 99.83433396149655 },
    backgroundMediaUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/Star%20Wars%20Theme%20Song%20By%20John%20Williams.mp3',
    pois: [
      {
        type: 'portal',
        poiHeading: -45.34389343373073,
        poiPitch: -2.9066574359895325,
        url: '?id=men-changing-room',
        title: 'Back to store',
      },
    ],
  },
  {
    panoId: 'changing-room-3',
    center: { lat: 19.88665960103361, lng: 99.83433396149655 },
    backgroundMediaUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/Star%20Wars%20Theme%20Song%20By%20John%20Williams.mp3',
    pois: [
      {
        type: 'portal',
        poiHeading: -45.34389343373073,
        poiPitch: -2.9066574359895325,
        url: '?id=men-changing-room',
        title: 'Back to store',
      },
    ],
  },
  {
    panoId: 'secondfloor-elevator',
    center: { lat: 19.88665960103361, lng: 99.83433396149655 },
    backgroundMediaUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/016.%20The%20Kid%20Laroi%20-%20STAY.mp3',
    pois: [
      {
        type: 'selection',
        poiHeading: 110.21238500195498,
        poiPitch: 5.6234331603983305,
        locations: [
          {
            floor: '1',
            url: '?id=kid',
            title: 'COTTON ON KIDS',
            imageUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/cottonon-kids-logo.png',
          },
          {
            floor: '2',
            url: '?id=secondfloor-elevator',
            title: 'Supre',
            imageUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/supre-logo.png',
          },
          {
            floor: '3',
            url: '?id=men-4',
            title: 'Factorie',
            imageUrl: 'https://fidelitystorage.blob.core.windows.net/demo-vr-store/factorie-logo.png',
          },
        ],
      },
    ],
  },
];

export default VirtualLocationMockDatas;
