import { Box, IconButton, makeStyles, Paper, Badge } from '@material-ui/core';
import React, { forwardRef, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import CouponIcon from '@material-ui/icons/ConfirmationNumber';
import CouponService from '../services/coupon.service';
import { CouponContext } from '../contexts/CouponContext';
import dateFormat from 'dateformat';
import CouponDetails from './CouponDetails';

const useCouponItemStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'row',
    width: 325,
    justifyContent: 'space-between',
  },
  couponPart: {
    padding: theme.spacing(1),
    backgroundColor: '#FFF',
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  infoContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    borderLeftColor: '#666',
    borderLeftWidth: 2,
    borderLeftStyle: 'dotted',
    minWidth: 100,
    width: 100,
  },
  progress: {
    fontSize: 10,
  },
  infoText: {
    fontSize: 20,
    textAlign: 'center',
  },
  expiredDate: {
    fontSize: 11,
  },
}));

// CouponsListItems
// id: coupon.id,
// title: coupon.title,
// infoText: 'Collected',
// expiredDate: coupon.expiredDate,
const CouponItem = (props) => {
  const { coupon, onCouponListClick, notification } = props;
  let classes = useCouponItemStyles();
  return (
    <div className={classes.root} onClick={() => onCouponListClick(coupon.id, notification)}>
      <div className={clsx(classes.couponPart, classes.titleContainer)}>
        <span>{coupon.title}</span>
      </div>
      <div className={clsx(classes.couponPart, classes.infoContainer)}>
        <span className={classes.infoText}>{coupon.infoText}</span>
        <span className={classes.expiredDate}>expired: {dateFormat(coupon.expiredDate, 'shortDate')}</span>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: 40,
    left: 0,
    zIndex: 100,
    position: 'absolute',
    backgroundColor: '#CACACA',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    color: '#EE1F3B',
    fontWeight: 'bold',
    fontSize: '1.85rem',
    marginBottom: 0,
    marginTop: theme.spacing(0.5),
    textAlign: 'center',
  },
  subHeader: {
    color: '#333',
    fontWeight: 'bold',
    fontSize: '1rem',
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    textAlign: 'center',
  },
  annouceBox: {
    padding: theme.spacing(1),
    maxHeight: 500,
    scrollbarWidth: 'none',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    color: '#707070',
  },
  anchorOriginTopRightRectangle: {
    transform: 'scale(1) translate(0%, 0%)',
  }
}));

const CouponsMenuContent = (props, ref) => {
  const { onCloseButtonClick } = props;
  const classes = useStyles();
  const history = useHistory();
  const [openCouponDetails, setOpenCouponDetails] = useState(false)
  const [coupons, setCoupons] = useState([]);
  const [couponContext, setCouponContext] = useContext(CouponContext);
  const [couponId, setCouponId] = useState()
  const [couponNotifications, setCouponNotifications] = useState([])

  const handleCloseButtonClick = () => {
    if (onCloseButtonClick) {
      onCloseButtonClick();
    }
  };

  const onCouponListClick = (couponId, notification) => {
    if (!notification) {
      CouponService.clearCouponNotificationByCouponId(couponId)
      console.log("IDDDDDDD", couponId, notification);
      setCouponContext(new Date());
    }
    setCouponId(couponId)
    setOpenCouponDetails(true)
  }

  const onCloseCouponDetails = () => {
    setOpenCouponDetails(false)
  }

  useEffect(() => {
    let coupons = CouponService.getCouponsListItems();
    setCoupons(coupons);
    console.log('getCouponsListItems', coupons);

    let couponNotifications = CouponService.getCouponNotifications();
    setCouponNotifications(couponNotifications)
  }, [couponContext]);

  const invisbleDot = (id) => {
    if (couponNotifications && couponNotifications.some(couponNotification => couponNotification.couponId === id)) {
      return false
    } else {
      return true
    }
  }

  return (
    <>
      <Paper className={classes.root} ref={ref}>
        <p className={classes.header}>Coupons</p>
        <Box className={classes.annouceBox}>
          {coupons.length &&
            coupons.map((coupon, index) => {
              return (
                <Badge color="secondary" variant="dot" invisible={invisbleDot(coupon.id)} key={`couponItem${index}`} classes={{ anchorOriginTopRightRectangle: classes.anchorOriginTopRightRectangle }}>
                  <CouponItem coupon={coupon} onCouponListClick={onCouponListClick} notification={invisbleDot(coupon.id)} />
                </Badge>
              );
            })}
        </Box>

        <IconButton color="secondary" className={classes.iconButton} onClick={handleCloseButtonClick}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      </Paper>
      {couponId && <CouponDetails open={openCouponDetails} couponId={couponId} onCloseCouponDetails={onCloseCouponDetails} />}
    </>
  );
};

export default forwardRef(CouponsMenuContent);
