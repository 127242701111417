import React, { forwardRef } from 'react';
import { Button, makeStyles, withStyles, Tooltip } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CheckOutModal from './CheckOutModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister } from '@fortawesome/free-solid-svg-icons/faCashRegister';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    borderRadius: 25,
  },
  icon: {
  },
}));

const CounterButton = withStyles((theme) => ({
  root: {
    zIndex: 100,
    marginTop: '-50%',
    marginLeft: '-50%',
    padding: theme.spacing(1),
    border: '2px solid #FFF',
    color: '#FFF',
    background: 'radial-gradient(circle, rgba(0,0,0,0.5) 0%, rgba(238,31,59,1) 100%);',
    minWidth: 0,
    '&:hover': {
      backgroundColor: 'rgba(238,31,59,1)',
    },
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 30%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 30%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.3);',
  },
  label: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    '& svg': {
      fontSize: '1.75rem',
      width: '1em',
      height: '1em',
    },
  },
}))(Button);

const CheckOutTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#333',
  },
  arrow: {
    color: '#333',
  },
}))((props) => <Tooltip placement="top" {...props} arrow />);

const PoiCounter = forwardRef((props, ref) => {
  const classes = useStyles();
  const { index, poi, handleOpenModal, handleCloseModal } = props;

  const body = <CheckOutModal handleCloseModal={handleCloseModal} />;

  const handleClick = () => {
    if (handleOpenModal) {
      handleOpenModal(body);
    }
  };

  return (
    <div ref={ref} className="poiPoint" data-poi-index={index}>
      <CheckOutTooltip title="Check Out" placement="top" arrow>
        <CounterButton color="primary" onClick={handleClick} className={classes.button} size="small">
          {/* <ShoppingCartIcon className={classes.icon} /> */}
          <FontAwesomeIcon className={classes.icon} icon={faCashRegister} />
        </CounterButton>
      </CheckOutTooltip>
    </div>
  );
});

export default PoiCounter;
