import ProductsMockData from "../mockdatas/products";

// const user = { chest: 39.4, waist: 32.3, hip: 39 }

const SizeGuideService = {
    calSize: function (data) {
        return calSize(data);
    },
    getSizeGuideKey: function (data) {
        return getSizeGuideKey(data);
    },
    checkSize: function (data) {
        return checkSize(data);
    },
}

function calSize(data) {
    if (data && Object.keys(data).length > 0) {
        return true
    } else {
        return false
    }
}

function getSizeGuideKey(data) {
    const sizeGuideKey = Object.keys(data).filter(key => key !== 'size')
    return sizeGuideKey;
}

function getNumberSize(size) {
    if (size === 'XS') { return 1; }
    else if (size === 'S') { return 2; }
    else if (size === 'M') { return 3; }
    else if (size === 'L') { return 4; }
    else if (size === 'XL') { return 5; }
}

function getFitSize(data) {
    const allSize = data;
    const numberSize = allSize.map(x => getNumberSize(x));
    const fitSize = Math.max(...numberSize);
    console.log("fitSize", fitSize);
    if (fitSize === 1) { return 'XS' }
    else if (fitSize === 2) { return 'S'; }
    else if (fitSize === 3) { return 'M'; }
    else if (fitSize === 4) { return 'L'; }
    else if (fitSize === 5) { return 'XL'; }
    else { return false; }
}

function checkSize(data) {
    const user = data.user;
    const product = ProductsMockData.filter(x => x.id === data.id);
    const productSizeGuide = product[0].sizeGuide;
    console.log('productSizeGuide', productSizeGuide, user);

    let result = [];
    let sizeList = [];
    for (const key in user) {

        console.log("productSizeGuide >> ", key, user[key]);
        let sizeData = productSizeGuide.find((s) => {
            return Math.ceil(s[key]) >= user[key].toLocaleString(undefined, { maximumFractionDigits: 0 }) && Math.ceil(s[key]) <= (user[key] + 1).toLocaleString(undefined, { maximumFractionDigits: 0 })

        })
        if (sizeData) {
            console.log("productSizeGuide 555", sizeData.size);
            result.push({ [key]: sizeData.size });
            sizeList.push(sizeData.size);
        }
    }
    const fitSize = getFitSize(sizeList);
    // console.log("productSizeGuide Result", result, fitSize);
    return { result: result, fitSize: fitSize };
}

export default SizeGuideService;