import { createContext } from 'react';

export const ChangingRoomContext = createContext({
  dressUp: {
    head: {},
    eyes: {},
    tops: {},
    legs: {},
    feet: {},
  },
  body: {
  },
  gender: ''
});
