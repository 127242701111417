import PoiService from './poi.service';
import Perspective from 'perspectivejs';
import { GifPoster } from './gifPoster';
import { MP4Poster } from './mp4Poster';

const PosterService = {
  updatePostersPosition: function (panoramaViewProps, posterMedias, postersCanvas) {
    if (postersCanvas && posterMedias && panoramaViewProps) {
      setPosterPosition(panoramaViewProps, posterMedias, postersCanvas);
    }
  },
  cleanUp: function (posterMedias) {
    if (posterMedias) {
      posterMedias.forEach((posterMedia) => {
        if (posterMedia.poster.type === 'gif' && posterMedia.gifPoster) {
          posterMedia.gifPoster.stopAnimation();
        }else if (posterMedia.poster.type === 'mp4' && posterMedia.mp4Poster){
          posterMedia.mp4Poster.stopAnimation();
        }
      });
    }
  },
};

function getXYPosition(headingAndPitch, panoramaViewProps) {
  var modelHeading = parseFloat(headingAndPitch.poiHeading);
  var modelPitch = parseFloat(headingAndPitch.poiPitch);
  var t = PoiService.moveFromHeadingAndPitch(
    PoiService.deg2rad(panoramaViewProps.heading),
    PoiService.deg2rad(panoramaViewProps.pitch),
    modelHeading,
    modelPitch,
    panoramaViewProps.width,
    panoramaViewProps.height,
    panoramaViewProps.zoom
  );

  if (t.top === undefined || t.left === undefined) {
    return null;
  } else {
    return { x: t.left, y: t.top };
  }
}

var posterCanvas, posterCanvasCtx;
function getCanvasAndContext(panoramaViewProps, posterElement) {
  let isNewCanvas = false;
  let resizedCanvas = false;
  if (posterCanvas != posterElement) {
    posterCanvas = posterElement;
    isNewCanvas = true;
  }
  if (isNewCanvas || posterCanvas.width != panoramaViewProps.width || posterCanvas.height != panoramaViewProps.height) {
    posterCanvas.width = panoramaViewProps.width;
    posterCanvas.height = panoramaViewProps.height;
    posterCanvasCtx = posterCanvas.getContext('2d');
    resizedCanvas = true;
    console.log('newContext');
  }

  return { canvas: posterCanvas, context: posterCanvasCtx, resizedCanvas: resizedCanvas };
}

function setPosterPosition(panoramaViewProps, posterMedias, posterElement) {
  if (posterElement && panoramaViewProps && posterMedias) {
    // Set CSS Position of POI Icon Element
    let { canvas, context, resizedCanvas } = getCanvasAndContext(panoramaViewProps, posterElement);

    context.clearRect(0, 0, canvas.width, canvas.height);

    posterMedias.forEach((posterMedia) => {
      let poster = posterMedia.poster;
      let topleft = getXYPosition(poster.position.topLeft, panoramaViewProps);
      let topRight = getXYPosition(poster.position.topRight, panoramaViewProps);
      let bottomLeft = getXYPosition(poster.position.bottomLeft, panoramaViewProps);
      let bottomRight = getXYPosition(poster.position.bottomRight, panoramaViewProps);

      let drawArea = null;
      if (topleft && topRight && bottomLeft && bottomRight) {
        drawArea = [
          [topleft.x, topleft.y],
          [topRight.x, topRight.y],
          [bottomRight.x, bottomRight.y],
          [bottomLeft.x, bottomLeft.y],
        ];
      }

      if (posterMedia.mediaData) {
        if (poster.type === 'image') {
          updateImagePosterPosition(context, posterMedia, resizedCanvas, drawArea);
        } else if (poster.type === 'gif') {
          updateGifPosterPosition(context, posterMedia, resizedCanvas, drawArea);
        } else if (poster.type === 'mp4') {
          updateMP4PosterPosition(context, posterMedia, resizedCanvas, drawArea);
        }
      }
    });
  }
}

function updateImagePosterPosition(context, posterMedia, resizedCanvas, drawArea) {
  if (drawArea) {
    if (!posterMedia.perspectiveImage || resizedCanvas) {
      posterMedia.perspectiveImage = new Perspective(context, posterMedia.mediaData);
    }
    posterMedia.perspectiveImage.draw(drawArea);
  }
}

function updateGifPosterPosition(context, posterMedia, resizedCanvas, drawArea) {
  if (drawArea) {
    if (!posterMedia.gifPoster || resizedCanvas) {
      if (posterMedia.gifPoster) {
        posterMedia.gifPoster.stopAnimation();
      }
      posterMedia.gifPoster = new GifPoster(posterMedia.mediaData.frames);
    }
    posterMedia.gifPoster.startAnimation(context, drawArea);
  } else {
    if (posterMedia.gifPoster) {
      posterMedia.gifPoster.stopAnimation();
    }
  }
}

function updateMP4PosterPosition(context, posterMedia, resizedCanvas, drawArea) {
  if (drawArea) {
    if (!posterMedia.mp4Poster || resizedCanvas) {
      if (posterMedia.mp4Poster) {
        posterMedia.mp4Poster.stopAnimation();
      }
      posterMedia.mp4Poster = new MP4Poster(posterMedia.mediaData);
    }
    posterMedia.mp4Poster.startAnimation(context, drawArea);
  } else {
    if (posterMedia.mp4Poster) {
      posterMedia.mp4Poster.stopAnimation();
    }
  }
}

export default PosterService;
